import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 70,
    height: 70,
    borderRadius: "50%",
    borderColor: green[500],
    borderWidth: 3,
    borderStyle: "solid",
    margin: theme.spacing(6),
  },
  icon: {
    width: 40,
    height: 40,
    color: green[500],
  },
  text: {
    padding: theme.spacing(3, 10),
  },
}));

const ExposeSubscriptionSuccess = ({ email }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <CheckIcon className={classes.icon} />
      </div>
      <Typography variant="h4">Vielen Dank für Ihr Interesse!</Typography>
      <Typography
        align="center"
        variant="body1"
        color="textPrimary"
        className={classes.text}
      >
        Wir haben soeben eine E-Mail mit einem Link zu dem gewünschten Exposé an{" "}
        <b>{email}</b> gesendet. Sollten Sie die E-Mail nicht finden, prüfen Sie
        bitte auch den Spam-Ordner!
      </Typography>
    </div>
  );
};

export default ExposeSubscriptionSuccess;
