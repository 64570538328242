import React from "react";

/** external imports */
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  outerDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: ({ align }) => align ?? "center",
    color: ({ isDarkMode }) => isDarkMode ? "white" : undefined,
    gap: theme.spacing(2)
  },
  text: {
    color: ({ isDarkMode }) => isDarkMode ? "white" : undefined,
  },
  icon: { marginRight: 5, color: theme.palette.primary.dark },
}));

const CustomListItem = ({ text, align, children, isDarkMode = false, variant = "body1" }) => {
  const classes = useStyles({ align, isDarkMode });


  return (
    <div className={classes.outerDiv}>
      {children ? <Box mr={1}>{children}</Box> : null}
      <Typography variant={variant} className={classes.text}>{text}</Typography>
    </div>
  );
};

export default CustomListItem;
