import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  successMessage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 70,
    height: 70,
    borderRadius: "50%",
    borderColor: green[500],
    borderWidth: 3,
    borderStyle: "solid",
    margin: theme.spacing(6),
  },
  icon: {
    width: 40,
    height: 40,
    color: green[500],
  },
  text: {
    padding: theme.spacing(3, 10),
  },
}));

const SearchAgentStepSuccess = () => {
  const classes = useStyles();

  const successMessage = (
    <div className={classes.successMessage}>
      <div className={classes.iconWrapper}>
        <CheckIcon className={classes.icon} />
      </div>
      <Typography variant="h4">Vielen Dank für Ihre Anfrage!</Typography>
      <Typography
        align="center"
        variant="body1"
        color="textPrimary"
        className={classes.text}
      >
        Wir informieren Sie umgehend, sobald eine Immobilie, die Ihren Wünschen
        entspricht, verfügbar ist.
      </Typography>
    </div>
  );

  return successMessage;
};

export default SearchAgentStepSuccess;
