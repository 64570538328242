const initState = {
  enabled: false,
};

const externalContentReducer = (state = initState, action) => {
  switch (action.type) {
    case "ENABLE_EXTERNAL_CONTENT":
      return { enabled: true };
    case "DISABLE_EXTERNAL_CONTENT":
      return { enabled: false };
    default:
      return state;
  }
};

export default externalContentReducer;
