import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import TitleWithSeparator from '../global/TitleWithSeparator';
import CustomContainer from '../global/CustomContainer';
import CustomSubtitle from '../global/CustomSubtitle';
import HoverImageCard from '../global/CustomHoverImageCard';
import decluttingBeforeImage from '../../images/raeumung-vorher.jpeg';
import decluttingAfterImage from '../../images/raeumung-nachher.jpeg';
import CustomListItem from '../global/CustomListItem';
import ContactForm from '../forms/ContactForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  textSection: {
    flex: 1,
    padding: theme.spacing(2),
  },
  imageSection: {
    flex: 1,
    padding: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  stepsHeaderTitle: {
    textDecoration: 'underline',
  },
  flexGap: {
    gap: theme.spacing(2),
  },
  necessaryInfoBox: {
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
}));

const Decluttering = () => {
  const classes = useStyles();

  return (
    <>
      <CustomContainer>
        <TitleWithSeparator title="Zuverlässig, schnell und fachgerecht" />
        <CustomSubtitle text="Der einfache Weg zu einer sauberen und aufgeräumten Immobilie." />
      </CustomContainer>

      <CustomContainer backgroundWhite p={2}>
        <Box className={classes.content}>
          <Box className={classes.textSection}>
            <Typography variant="h3" className={classes.subtitle}>
              Entrümpelungs- und Räumungsservice
            </Typography>
            <Typography variant="body1">
              Sie brauchen eine fachgerechte Entrümpelung Ihrer Immobilie? Ihr Garten muss aufbereitet werden? Kontaktieren Sie uns gerne! Unser Angebot gilt im Umkreis von 50km um Bad Kissingen.
              Unser Service umfasst sämtliche Aufgaben einer <b>Wohnungsauflösung</b> (auch Messie-Wohnungen), <b>Räumung</b>, <b>Endreinigung</b> und <b>Aufbereitung</b>. Ob einzelne Räume, den Keller oder Dachboden, die Außenanlage oder das gesamte Objekt; wir kümmern uns um die anfallenden Tätigkeiten.
            </Typography>
          </Box>
          <Box className={classes.imageSection}>
            <HoverImageCard 
              beforeImage={decluttingBeforeImage}
              afterImage={decluttingAfterImage}
            />
          </Box>
        </Box>

        <Box mt={6}>
          <Typography variant="h4" className={classes.stepsHeaderTitle}>
            In nur 5 Schritten zu einer sauberen Immobilie:
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            my={2}
            className={classes.flexGap}
          >
            {[
              "Sie suchen sich die Gegenstände und Möbel aus, die Sie behalten möchten.",
              "Wir transportieren diese zu Ihnen und sortieren den Rest aus.",
              "Gegenstände (Kleidung, Bücher etc.), die noch brauchbar sein könnten, versuchen wir zu erhalten, um unnötigen Müll zu vermeiden. Hierfür arbeiten wir mit gemeinnützigen Organisationen zusammen. Somit sparen Sie Geld und unterstützen gleichzeitig hilfsbedürftige Menschen.",
              "Haushaltsmüll, Elektrogeräte und Möbel entsorgen wir fachgerecht. Den Rechnungsbeleg der Deponie stellen wir Ihnen mit der Gesamtrechnung zur Verfügung.",
              "Das Objekt hinterlassen wir besenrein. Eine vollständige Endreinigung ist in manchen Fällen sinnvoll und auf Wunsch natürlich möglich.",
            ].map((text, index) => (
              <CustomListItem key={index} align="left" text={`${index + 1}. ${text}`} />
            ))}
          </Box>
        </Box>

        <Box mt={6}>
          <Typography variant="h4" className={classes.stepsHeaderTitle}>
            Angaben, die wir für die Erstellung eines Angebots benötigen:
          </Typography>

          <Box
            display="flex"
            my={2}
            className={classes.necessaryInfoBox}
          >
            {[
              "Die Objektadresse",
              "Ist ein Parkplatz vorhanden?",
              "Wohnfläche oder Zimmeranzahl",
              "Welche Etage?",
              "Ist ein Fahrstuhl vorhanden?",
              "Fotos vom Zustand",
              "Gewünschte Terminplanung",
            ].map((text, index) => (
              <Box key={index}>
                <CustomListItem align="left" text={text}>
                  <CheckIcon fontSize="small" />
                </CustomListItem>
              </Box>
            ))}
          </Box>
          <Typography variant="body1">
            Wir freuen uns auf Ihre Anfrage und stehen Ihnen gerne für weitere Fragen zur Verfügung.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: 'center', md: 'flex-end'},
          }}
        >
          <ContactForm
            buttonText="Jetzt unverbindlich anfragen"
            buttonVariant="outlined"
            isMobile={false}
            leavePageOnClose={false}
            showButtonIcon={false}
          />
        </Box>
      </CustomContainer>
    </>
  );
};

export default Decluttering;
