const initState = {
  title: "",
  description: "",
  contact_privacy_info: "",
  contact_privacy_checkbox: "",
  contact_newsletter_checkbox: "",
  expose_headline_agb: "",
  expose_agb: "",
  expose_agb_checkbox: "",
  expose_headline_cancellation: "",
  expose_cancellation: "",
  expose_cancellation_checkbox: "",
  archived_title: "",
  archived_description: "",
  objects_title: "",
  objects_description: "",
  show_expose_title: "",
  show_expose_description: "",
  show_expose_button: "",
};

const settingsReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_SETTINGS":
      return action.data;
    default:
      return state;
  }
};

export default settingsReducer;
