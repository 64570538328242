import React from "react";
import {
  DialogContentText,
  TextField,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  InputLabel,
  Typography,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Grid,
} from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useScreensize from "../../utils/useScreensize";

const useStyles = makeStyles((theme) => ({
  phone: {
    display: "flex",
  },
  phoneCountry: {
    minWidth: 80,
    marginRight: theme.spacing(1),
  },
  phonePrefix: {
    marginRight: theme.spacing(1),
  },
  phoneNumber: {
    minWidth: 100,
    flexGrow: 1,
  },
  phoneWrapper: {
    padding: theme.spacing(2, 0),
  },
  privacy: {
    display: "flex",
    padding: theme.spacing(0),
    margin: theme.spacing(-1, 0),
  },
  newsletter: {
    padding: theme.spacing(0),
    margin: theme.spacing(-1, 0),
  },
  privacyLabel: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  links: {
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  smsHint: {
    lineHeight: 1.2,
    marginTop: -2,
  },
  gridItem: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
}));

const ExposeSubscriptionStepOne = ({
  handleChangeInput,
  handleChangeSelect,
  handleChangeCheckbox,
  handleChangeNewsletter,
  salutation,
  email,
  firstname,
  lastname,
  street,
  zip,
  city,
  phone,
  accept_privacy,
  newsletter,
  result,
  invalidPhone,
  sellProperty,
}) => {
  const classes = useStyles();

  const websiteSettings = useSelector((state) => state.settings);

  const { xs } = useScreensize();

  const getContactPrivacyInfo = () => {
    if (websiteSettings?.contact_privacy_info) {
      const parsedPrivacyInfo = websiteSettings?.contact_privacy_info?.split(
        "Info[at]marquart-immobilien.de"
      );
      return parsedPrivacyInfo[1] || websiteSettings?.contact_privacy_info;
    } else {
      return websiteSettings?.contact_privacy_info;
    }
  };

  return (
    <>
      <DialogContentText color="textPrimary">
        Gerne gewähren wir Ihnen Zugriff auf ein ausführliches Exposé inkl. des
        genauen Standortes über diese Immobilie. Bitte füllen Sie hierzu das
        Formular vollständig aus.
      </DialogContentText>
      {result === "error" && (
        <Typography color="error">
          Wir konnten leider keinen Verifizierungs-Code an Ihre Mobilfunk-Nummer
          senden. Bitte überprüfen Sie Ihre Eingabe.
        </Typography>
      )}
      <Grid container spacing={xs ? 0 : 2}>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel htmlFor="salutation">Anrede</InputLabel>
            <Select
              value={salutation}
              onChange={handleChangeSelect}
              input={
                <OutlinedInput
                  labelWidth={50}
                  name="salutation"
                  id="salutation"
                />
              }
            >
              <MenuItem value={"Herr"}>Herr</MenuItem>
              <MenuItem value={"Frau"}>Frau</MenuItem>
              <MenuItem value={"Divers"}>Divers</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="firstname"
            label="Vorname"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput}
            value={firstname}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="lastname"
            label="Nachname"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput}
            value={lastname}
          />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="street"
            label="Straße, Haus-Nr."
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput}
            value={street}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="zip"
            label="PLZ"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput}
            value={zip}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="city"
            label="Ort"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput}
            value={city}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            error={invalidPhone}
            margin="dense"
            id="phone"
            label="Mobilfunk-Nummer"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput}
            value={phone}
            helperText={invalidPhone ? "Ungültige Mobilfunk-Nr." : undefined}
          />
          <FormHelperText className={classes.smsHint}>
            Sie erhalten nach dem Absenden dieses Formulars einen
            Verifizierungs-Code per SMS
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="email"
            label="E-Mail-Adresse"
            type="email"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput}
            value={email}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography variant="body2">{getContactPrivacyInfo()}</Typography>
          </div>
          <div className={classes.links}>
            <MuiLink
              component={Link}
              target="_blank"
              to="/datenschutzerklaerung"
              color="textPrimary"
            >
              <strong>Datenschutzerklärung</strong>
            </MuiLink>
            &nbsp;&nbsp;
            <MuiLink
              component={Link}
              to="/impressum"
              target="_blank"
              color="textPrimary"
            >
              <strong>Impressum</strong>
            </MuiLink>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.privacy}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={accept_privacy}
                  onChange={(e) => handleChangeCheckbox(e, "accept_privacy")}
                  value="accept_privacy"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  {websiteSettings.contact_privacy_checkbox}
                </Typography>
              }
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.privacy}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sellProperty}
                  onChange={(e) => handleChangeCheckbox(e, "sellProperty")}
                  value="sellProperty"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  Ich würde gegebenenfalls meine Immobilie verkaufen.
                </Typography>
              }
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.newsletter}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsletter}
                  onChange={handleChangeNewsletter}
                  value="newsletter"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  {websiteSettings.contact_newsletter_checkbox}
                </Typography>
              }
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ExposeSubscriptionStepOne;
