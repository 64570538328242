/** external imports */
import {
  List,
  Box,
  ListItem,
  makeStyles,
  FormControl,
  ListItemText,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import DescriptionIcon from "@material-ui/icons/Description";

/** internal imports */
import CustomMobileStepper from "../../global/CustomMobileStepper";
import PropertyQualityPage from "./reusablePages/PropertyQualityPage";
import PropertyLocationPage from "./reusablePages/PropertyLocationPage";
import CustomAvatarWithQuestion from "../../global/CustomAvatarWithQuestion";
import PersonalInformationPage from "./reusablePages/PersonalInformationPage";
import PropertyValuationReasonPage from "./reusablePages/PropertyValuationReasonPage";
import CustomBackdrop from "../../global/CustomBackdrop";
import * as constants from "../../../constants";

const useStyles = makeStyles((theme) => ({
  formSection: {
    flex: 1,
    marginLeft: 152,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginLeft: "unset",
    },
  },
  listItem: {
    border: "1px solid lightgray",
    marginBottom: theme.spacing(1),
    height: theme.spacing(8),
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        color: "#fff",
        background: theme.palette.primary.main,
      },
    },
  },
  optionClicked: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "& span": {
      color: "white",
    },
  },
  outerBox: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(10),
    },
  },
  textField: { borderRadius: 0, height: 64 },
}));

const __FOOTER_HEIGHT = 53;

const PropertyValuationApartment = ({ resetStepper, objectType, steps }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();

  /** internal states */
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [options, setOptions] = useState({
    typeOfApartment: null,
    livingSpace: null,
    rooms: null,
    yearOfConstruction: null,
    currentState: null,
    quality: null,
    reason: null,
    postalCode: null,
    city: null,
    street: null,
    personalInformation: {
      salutation: "Herr",
      givenName: null,
      firstName: null,
      email: null,
      telephone: null,
      postalCode: null,
      city: null,
      street: null,
      useObjectAddress: null,
      agreePrivacy: false,
    },
  });
  const [typeOfApartmentValue, setTypeOfApartmentValue] = useState(null);

  const handleSubmit = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    setIsLoading(true);
    fetch(constants.API_URL + "rating-request", {
      method: "POST",
      headers,
      body: JSON.stringify({
        ...options,
        ...(typeOfApartmentValue && { typeOfApartment: typeOfApartmentValue }),
        typeOfObject: objectType,
      }),
    })
      .then((result) => {
        return result.json();
      })
      .then(({ result }) => {
        if (result === "success") {
          setIsLoading(false);
          enqueueSnackbar(
            "Vielen Dank für Ihre Anfrage, wir werden Sie telefonisch oder per E-Mail kontaktieren.",
            {
              variant: "success",
              autoHideDuration: 6000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          );

          history.push("/");
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        enqueueSnackbar(
          "Ein Fehler ist aufgetreten, bitte versuchen Sie es noch einmal.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }
        );
      });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = ({ e, item }) => {
    setOptions({ ...options, [item]: e.target.value });
  };

  const getQuestion = () => {
    if (activeStep === 0) return "Welchen Wohnungstyp besitzen Sie?";

    if (activeStep === 1) return "Wieviel Wohnfläche hat Ihre Wohnung?";

    if (activeStep === 2)
      return "Wie viele Zimmer hat Ihre Wohnung, ohne Küche, Diele und Badezimmer?";

    if (activeStep === 3) return "Wann wurde die Wohnung erbaut?";

    if (activeStep === 4) return "Wie nutzen Sie Ihre Wohnung?";

    if (activeStep === 5)
      return "Welchen Qualitätsstandard würden Sie Ihrer Wohnung geben?";

    if (activeStep === 6) return "Wofür benötigen Sie die Immobilienbewertung?";

    if (activeStep === 7)
      return "Wie lautet die Adresse der zu bewertenden Wohnung?";

    if (activeStep === 8)
      return "Geben Sie uns bitte Ihre Kontaktdaten zur weiteren Kommunikation.";
  };

  const onClickOptionHandler = ({ attribute, value }) => {
    if (attribute === "typeOfApartment" && typeOfApartmentValue) {
      setTypeOfApartmentValue(null);
    }

    setOptions({ ...options, [attribute]: value });
    handleNext();
  };

  const handleChangePersonalInfomations = ({ e, item, type }) => {
    let value = "";

    if (type === "checkbox") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    setOptions({
      ...options,
      personalInformation: {
        ...options?.personalInformation,
        ...(item === "useObjectAddress" && value === true
          ? {
              city: options?.city,
              postalCode: options?.postalCode,
              street: options?.street,
            }
          : null),
        [item]: value,
      },
    });
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNext();
    }
  };

  const onClickGender = ({ gender }) => {
    setOptions({
      ...options,
      personalInformation: {
        ...options?.personalInformation,
        salutation: gender,
      },
    });
  };

  const onFocusTextInput = ({ e }) => {
    if (
      typeOfApartmentValue === "" ||
      !typeOfApartmentValue ||
      e.target.value === ""
    )
      return;

    setOptions({ ...options, typeOfApartment: null });
  };

  const renderFirstPage = () => {
    return (
      <Box display="flex" flexDirection="column">
        <List
          aria-label="housePageList"
          className={classes.formSection}
          style={{ paddingBottom: 0 }}
        >
          <ListItem
            button
            className={clsx(
              classes.listItem,
              options?.typeOfApartment === "Erdgeschosswohnung" &&
                classes.optionClicked
            )}
            onClick={() =>
              onClickOptionHandler({
                attribute: "typeOfApartment",
                value: "Erdgeschosswohnung",
              })
            }
          >
            <ListItemText primary="Erdgeschosswohnung" />
          </ListItem>

          <ListItem
            button
            className={clsx(
              classes.listItem,
              options?.typeOfApartment === "Etagenwohnung" &&
                classes.optionClicked
            )}
            onClick={() =>
              onClickOptionHandler({
                attribute: "typeOfApartment",
                value: "Etagenwohnung",
              })
            }
          >
            <ListItemText primary="Etagenwohnung" />
          </ListItem>

          <ListItem
            button
            className={clsx(
              classes.listItem,
              options?.typeOfApartment === "Penthouse" && classes.optionClicked
            )}
            onClick={() =>
              onClickOptionHandler({
                attribute: "typeOfApartment",
                value: "Penthouse",
              })
            }
          >
            <ListItemText primary="Penthouse" />
          </ListItem>
        </List>

        <FormControl
          className={classes.formSection}
          variant="outlined"
          style={{ marginTop: 0 }}
        >
          <OutlinedInput
            id="typeOfApartment-id"
            value={typeOfApartmentValue || ""}
            onChange={(e) => {
              if (e.target.value < 0) return;

              if (options?.typeOfApartment !== "") {
                setOptions({ ...options, typeOfApartment: null });
              }
              return setTypeOfApartmentValue(e.target.value);
            }}
            aria-describedby="outlined-typeOfApartment-helper-text"
            inputProps={{
              "aria-label": "typeOfApartment",
              maxLength: 50,
            }}
            endAdornment={
              <DescriptionIcon
                fontSize="small"
                style={{ color: "gray" }}
                position="end"
              >
                m&#178;
              </DescriptionIcon>
            }
            placeholder="Andere"
            className={clsx(
              classes.textField,
              typeOfApartmentValue && classes.optionClicked
            )}
            onKeyPress={(e) => {
              if (!options?.typeOfApartment && !typeOfApartmentValue) return;
              onKeyPress(e);
            }}
            onFocus={(e) => onFocusTextInput({ e })}
          />
        </FormControl>
      </Box>
    );
  };

  const renderSndPage = () => {
    return (
      <Box display="flex">
        <FormControl
          className={classes.formSection}
          variant="outlined"
          style={{ marginTop: 16 }}
        >
          <OutlinedInput
            id="livingSpace-id"
            value={options?.livingSpace || ""}
            onChange={(e) => {
              if (e.target.value < 0) return;
              return handleChange({ e, item: "livingSpace" });
            }}
            endAdornment={
              <InputAdornment position="end">m&#178;</InputAdornment>
            }
            aria-describedby="outlined-livingSpace-helper-text"
            inputProps={{
              "aria-label": "livingSpace",
            }}
            placeholder="Wohnfläche"
            style={{ borderRadius: 0 }}
            onKeyPress={(e) => {
              if (!options?.livingSpace) return;
              onKeyPress(e);
            }}
          />
        </FormControl>
      </Box>
    );
  };

  const renderThirdPage = () => {
    return (
      <Box display="flex">
        <FormControl
          className={classes.formSection}
          variant="outlined"
          style={{ marginTop: 16 }}
        >
          <OutlinedInput
            id="rooms-id"
            value={options?.rooms || ""}
            onChange={(e) => {
              if (e.target.value < 0) return;
              return handleChange({ e, item: "rooms" });
            }}
            aria-describedby="outlined-rooms-helper-text"
            inputProps={{
              "aria-label": "rooms",
            }}
            type="number"
            labelWidth={0}
            placeholder="Zimmer"
            style={{ borderRadius: 0 }}
            onKeyPress={(e) => {
              if (!options?.rooms) return;
              onKeyPress(e);
            }}
          />
        </FormControl>
      </Box>
    );
  };

  const renderFourthPage = () => {
    return (
      <Box display="flex">
        <FormControl
          className={classes.formSection}
          variant="outlined"
          style={{ marginTop: 16 }}
        >
          <OutlinedInput
            id="yearOfConstruction-id"
            value={options?.yearOfConstruction || ""}
            onChange={(e) => {
              if (e.target.value < 0) return;
              return handleChange({ e, item: "yearOfConstruction" });
            }}
            aria-describedby="outlined-yearOfConstruction-helper-text"
            inputProps={{
              "aria-label": "yearOfConstruction",
            }}
            type="number"
            labelWidth={0}
            placeholder="Baujahr"
            style={{ borderRadius: 0 }}
            onKeyPress={(e) => {
              if (
                !options?.yearOfConstruction ||
                options?.yearOfConstruction.toString().length !== 4
              )
                return;
              onKeyPress(e);
            }}
          />
        </FormControl>
      </Box>
    );
  };

  const renderFifthPage = () => {
    return (
      <Box display="flex">
        <List aria-label="housePageList" className={classes.formSection}>
          <ListItem
            button
            className={clsx(
              classes.listItem,
              options?.currentState === "Eigennutzung" && classes.optionClicked
            )}
            onClick={() =>
              onClickOptionHandler({
                attribute: "currentState",
                value: "Eigennutzung",
              })
            }
          >
            <ListItemText primary="Eigennutzung" />
          </ListItem>

          <ListItem
            button
            className={clsx(
              classes.listItem,
              options?.currentState === "Vermietet" && classes.optionClicked
            )}
            onClick={() =>
              onClickOptionHandler({
                attribute: "currentState",
                value: "Vermietet",
              })
            }
          >
            <ListItemText primary="Vermietet" />
          </ListItem>

          <ListItem
            button
            className={clsx(
              classes.listItem,
              options?.currentState === "Leerstehend" && classes.optionClicked
            )}
            onClick={() =>
              onClickOptionHandler({
                attribute: "currentState",
                value: "Leerstehend",
              })
            }
          >
            <ListItemText primary="Leerstehend" />
          </ListItem>
        </List>
      </Box>
    );
  };

  const renderPage = () => {
    if (activeStep === 0) return renderFirstPage();
    if (activeStep === 1) return renderSndPage();
    if (activeStep === 2) return renderThirdPage();
    if (activeStep === 3) return renderFourthPage();
    if (activeStep === 4) return renderFifthPage();
    if (activeStep === 5)
      return (
        <PropertyQualityPage
          quality={options?.quality}
          onClickOptionHandler={onClickOptionHandler}
        />
      );
    if (activeStep === 6)
      return (
        <PropertyValuationReasonPage
          reason={options?.reason}
          onClickOptionHandler={onClickOptionHandler}
        />
      );
    if (activeStep === 7)
      return (
        <PropertyLocationPage
          postalCode={options?.postalCode}
          city={options?.city}
          street={options?.street}
          handleChange={handleChange}
          onKeyPress={onKeyPress}
          handleNext={handleNext}
        />
      );
    if (activeStep === 8)
      return (
        <PersonalInformationPage
          firstName={options?.personalInformation?.firstName}
          givenName={options?.personalInformation?.givenName}
          telephone={options?.personalInformation?.telephone}
          salutation={options?.personalInformation?.salutation}
          email={options?.personalInformation?.email}
          city={options?.personalInformation?.city}
          street={options?.personalInformation?.street}
          postalCode={options?.personalInformation?.postalCode}
          useObjectAddress={options?.personalInformation?.useObjectAddress}
          agreePrivacy={options?.personalInformation?.agreePrivacy}
          objectAddress={{
            city: options?.city,
            street: options?.street,
            postalCode: options?.postalCode,
          }}
          handleChangePersonalInfomations={handleChangePersonalInfomations}
          onClickGender={onClickGender}
          handleSubmit={handleSubmit}
        />
      );
  };

  const isMobileStepperDisabled = () => {
    if (activeStep === 0 && !options?.typeOfApartment && !typeOfApartmentValue)
      return true;
    if (activeStep === 1 && !options?.livingSpace) return true;
    if (activeStep === 2 && !options?.rooms) return true;
    if (
      activeStep === 3 &&
      (!options?.yearOfConstruction ||
        options?.yearOfConstruction.toString().length !== 4)
    )
      return true;
    if (activeStep === 4 && !options?.currentState) return true;
    if (activeStep === 5 && !options?.quality) return true;
    if (activeStep === 6 && !options?.reason) return true;
    if (
      activeStep === 7 &&
      (!options?.postalCode || !options?.city || !options?.street)
    )
      return true;
    if (
      activeStep === 8 &&
      (!options?.personalInformation?.email ||
        !options?.personalInformation?.salutation ||
        !options?.personalInformation?.firstName ||
        !options?.personalInformation?.givenName ||
        !options?.personalInformation?.telephone ||
        !options?.personalInformation?.agreePrivacy ||
        !options?.personalInformation?.postalCode ||
        !options?.personalInformation?.city ||
        !options?.personalInformation?.street)
    )
      return true;

    if (activeStep === steps) return true;
  };

  const handleCloseLoader = () => setIsLoading(false);

  return (
    <Box className={classes.outerBox}>
      {isLoading && (
        <CustomBackdrop isLoading={isLoading} handleClose={handleCloseLoader} />
      )}
      <CustomAvatarWithQuestion
        question={getQuestion()}
        resetStepper={resetStepper}
      />
      {renderPage()}

      <Box display="flex" justifyContent="flex-end" mt={`${__FOOTER_HEIGHT}px`}>
        <CustomMobileStepper
          steps={steps}
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={activeStep}
          handleSubmit={handleSubmit}
          disabled={isMobileStepperDisabled()}
        />
      </Box>
    </Box>
  );
};

export default PropertyValuationApartment;
