import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Hidden, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Separator from '../layout/Separator';
import ReviewIcons from '../review-icons/ReviewIcons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  slider: {
    marginTop: 0,
    padding: theme.spacing(3, 1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  sliderArchived: {
    marginTop: 0,
  },
  sliderTitle: {
    marginBottom: 0,
  },
  otherReviews: {
    maxWidth: '900px',
    margin: '0 auto',
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  },
  immoweltPartner: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
  },
  reviewBox: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    textAlign: 'center',
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      width: 220,
    },
  },
}));

const Reviews = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      try {
        setIsLoading(true);
        const response = await fetch(
          'https://service-reviews-ultimate.elfsight.com/data/reviews?uris%5B%5D=ChIJHZ4Wo1vjokcRGXqY0oG0EfE&with_text_only=1&min_rating=4&page_length=100&order=date',
          {
            method: 'GET',
            headers: headers,
          }
        );
        await response.json();
      } catch (error) {
        console.error('Error fetching reviews:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const renderLoader = (isMobile) => {
    if (!isLoading) return null;

    return (
      <div style={{ margin: 'auto', maxWidth: isMobile ? 'auto' : 900 }}>
        <Skeleton height={isMobile ? 80 : 120} />
        {[...Array(isMobile ? 4 : 5)].map((_, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            {[...Array(4)].map((_, idx) => (
              <Skeleton key={idx} variant="rect" width={210} height={isMobile ? 240 : 118} />
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={clsx(classes.slider, classes.sliderArchived)}>
      <Typography variant="h3" align="center" className={classes.sliderTitle}>
        Rezensionen
      </Typography>

      <Separator dense />
      <ReviewIcons />

      <Separator dense />

      <Hidden lgUp>{renderLoader(false)}</Hidden>
      <Hidden mdDown>{renderLoader(true)}</Hidden>
      <div className="elfsight-app-f16d6e5d-7c4f-4a03-aaed-bb072ea1c934"></div>
    </div>
  );
};

export default Reviews;
