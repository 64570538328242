import { Container, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: ({ mt }) => (mt ? theme.spacing(mt) : undefined),
    marginBottom: ({ mb }) => (mb ? theme.spacing(mb) : undefined),
    marginLeft: ({ ml }) => (ml ? theme.spacing(ml) : undefined),
    marginRight: ({ mr }) => (mr ? theme.spacing(mr) : undefined),
    padding: ({ p }) => (p ? theme.spacing(p) : undefined),
    paddingTop: ({ pt }) => (pt ? theme.spacing(pt) : undefined),
    paddingBottom: ({ pb }) => (pb ? theme.spacing(pb) : undefined),
    paddingLeft: ({ pl }) => (pl ? theme.spacing(pl) : undefined),
    paddingRight: ({ pr }) => (pr ? theme.spacing(pr) : undefined),
    background: ({ backgroundWhite }) =>
      backgroundWhite
        ? theme.palette.background.paper
        : theme.palette.background.default,
  },
}));

const CustomContainer = ({
  children,
  maxWidth = "lg",
  id,
  backgroundWhite = false,
  mt = 6,
  mb = 6,
  ml,
  mr,
  pt,
  pb,
  pl,
  pr,
  p,
}) => {
  const classes = useStyles({
    mt,
    mb,
    ml,
    mr,
    pt,
    pb,
    pl,
    pr,
    p,
    backgroundWhite,
  });

  const containerProps = {};

  if (id) {
    containerProps.id = id;
  }

  return (
    <Container
      maxWidth={maxWidth}
      className={classes.container}
      {...containerProps}
    >
      {children}
    </Container>
  );
};

export default CustomContainer;
