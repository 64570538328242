import React, { useEffect, useState } from "react";
import { Pannellum } from "pannellum-react";
import { Box, Button, Hidden, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    boxSizing: "border-box",
    width: "100%",
    height: 0,
    paddingBottom: "50%",
    position: "relative",
  },
  imgWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
  previewWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(2, 0),
  },
  preview: {
    width: 100,
    height: "auto",
    border: "8px solid transparent",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  activePreview: {
    borderColor: theme.palette.primary.main,
  },
}));

const Img360 = ({ images }) => {
  const classes = useStyles();
  const [activeImg, setActiveImg] = useState(null);

  useEffect(() => {
    if (!images || !images.length) {
      return;
    }

    setActiveImg(images[0].pfad);
  }, [images]);

  if (!activeImg || !activeImg.length) {
    return null;
  }

  return (
    <>
      <Hidden lgUp>
        <Box pt={4} pb={2}>
          <Typography variant="h6" align="center">
            360°-Ansicht
          </Typography>
        </Box>
      </Hidden>
      <div className={classes.wrapper}>
        <div className={classes.imgWrapper}>
          <Pannellum
            width="100%"
            height="100%"
            image={activeImg.replace("/1000", "/3000")}
            pitch={0}
            yaw={0}
            hfov={120}
            mouseZoom={false}
            autoLoad
            onLoad={() => {
              console.log("panorama loaded");
            }}
          ></Pannellum>
        </div>
      </div>
      {images.length > 1 && (
        <div className={classes.previewWrapper}>
          {images.map((img, key) => (
            <React.Fragment key={key}>
              <Box px={1}>
                <Button
                  onClick={() => {
                    setActiveImg(img.pfad);
                  }}
                  variant={img.pfad === activeImg ? "contained" : "outlined"}
                  color="secondary"
                >
                  {img.titel}
                </Button>
              </Box>
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default Img360;
