import React from "react";
import { Grid } from "@material-ui/core";

// internal imports
import "./BlogsOverview.css";
import CustomContainer from "../../../global/CustomContainer";
import TitleWithSeparator from "../../../global/TitleWithSeparator";
import CustomSubtitle from "../../../global/CustomSubtitle";
import BlogCard from "../BlogCard";
import useCollectionFetch from "../../../../hooks/useCollectionFetch";
import { Skeleton } from "@material-ui/lab";

function parseDate(dateString) {
  const [day, month, year] = dateString.split('.').map(Number);
  return new Date(year, month - 1, day);
}

function sortBlogsByDate(a, b) {
  if(!b.createdAt || !a.createdAt) return;
  return parseDate(b.createdAt) - parseDate(a.createdAt);
}

const BlogsOverview = ({ showInactiveBlogs = false }) => {
  const { data: blogs, loading } = useCollectionFetch("blogs");

  const sortedBlogsByDate = [...blogs].sort(sortBlogsByDate);
  const visibleBlogs = sortedBlogsByDate.filter((blog) => {
    if (showInactiveBlogs) {
      return !blog.active;
    }

    return blog.active;
  });

  return (
    <CustomContainer>
      <TitleWithSeparator title="Aktuelle Trends und Tipps vom Experten" />
      <CustomSubtitle text="Entdecken Sie informative Beiträge zu Immobilienmarktanalysen, Kauf- und Verkaufstipps sowie wichtige Einblicke und Neuigkeiten rund um die Immobilienbranche." />

      <Grid container spacing={2}>
        {loading && <Skeleton variant="rect" width={608} height={300} />}
        {visibleBlogs?.map((blog) => (
          <Grid item xs={12} sm={6} key={blog.slug}>
            <BlogCard {...blog} />
          </Grid>
        ))}
      </Grid>
    </CustomContainer>
  );
};

export default BlogsOverview;
