import React from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  Box,
} from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import useScreensize from "../../utils/useScreensize";

const useStyles = makeStyles((theme) => ({
  phone: {
    display: "flex",
  },
  phoneCountry: {
    minWidth: 80,
    marginRight: theme.spacing(1),
  },
  phonePrefix: {
    marginRight: theme.spacing(1),
  },
  phoneNumber: {
    minWidth: 100,
    flexGrow: 1,
  },
  phoneWrapper: {
    padding: theme.spacing(2, 0),
  },
  privacy: {
    display: "flex",
    padding: theme.spacing(0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(-1, 0),
    },
  },
  newsletter: {
    padding: theme.spacing(0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(-1, 0),
    },
  },
  privacyLabel: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  privacyInfo: {
    fontSize: 12,
  },
  links: {
    marginTop: theme.spacing(1),
  },
  gridItem: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
}));

const SearchAgentStep5 = ({
  websiteSettings,
  values,
  handleChangeSelect,
  handleChangeInput,
  handleChangePrivacy,
  handleChangeNewsletter,
}) => {
  const classes = useStyles();
  const { xs } = useScreensize();

  const form = (
    <>
      <Box pt={{ xs: 1, sm: 2 }} pb={{ xs: 1, sm: 2 }} mb={3} bgcolor="#f9f9f9">
        <Typography variant="h2" align="center">
          Kontaktdaten
        </Typography>
      </Box>
      <Grid container justify="center">
        <Grid item xs={12} lg={8}>
          <Box p={2} bgcolor="#fff">
            <Grid container spacing={xs ? 0 : 2}>
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <FormControl fullWidth variant="outlined" margin="dense">
                  <InputLabel htmlFor="salutation">Anrede</InputLabel>
                  <Select
                    value={values.salutation}
                    onChange={handleChangeSelect}
                    input={
                      <OutlinedInput
                        labelWidth={50}
                        name="salutation"
                        id="salutation"
                      />
                    }
                  >
                    <MenuItem value={"Herr"}>Herr</MenuItem>
                    <MenuItem value={"Frau"}>Frau</MenuItem>
                    <MenuItem value={"Divers"}>Divers</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <TextField
                  margin="dense"
                  id="firstname"
                  label="Vorname"
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={handleChangeInput("firstname")}
                  value={values.firstname}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <TextField
                  margin="dense"
                  id="lastname"
                  label="Nachname"
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={handleChangeInput("lastname")}
                  value={values.lastname}
                />
              </Grid>
              <Grid item xs={12} sm={5} className={classes.gridItem}>
                <TextField
                  margin="dense"
                  id="street"
                  label="Straße, Haus-Nr."
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={handleChangeInput("street")}
                  value={values.street}
                />
              </Grid>
              <Grid item xs={12} sm={3} className={classes.gridItem}>
                <TextField
                  margin="dense"
                  id="zip"
                  label="PLZ"
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={handleChangeInput("zip")}
                  value={values.zip}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <TextField
                  margin="dense"
                  id="city"
                  label="Ort"
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={handleChangeInput("city")}
                  value={values.city}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <TextField
                  margin="dense"
                  id="email"
                  label="E-Mail-Adresse"
                  type="email"
                  variant="outlined"
                  fullWidth
                  onChange={handleChangeInput("email")}
                  value={values.email}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridItem}>
                <TextField
                  margin="dense"
                  id="phone"
                  label="Telefon"
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={handleChangeInput("phone")}
                  value={values.phone}
                />
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <TextField
                  margin="dense"
                  id="message"
                  label="Anmerkungen"
                  type="text"
                  variant="outlined"
                  fullWidth
                  multiline
                  onChange={handleChangeInput("message")}
                  value={values.message}
                />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <Typography variant="body2" className={classes.privacyInfo}>
                    {websiteSettings.contact_privacy_info}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.links}>
                  <MuiLink
                    component={Link}
                    target="_blank"
                    to="/datenschutzerklaerung"
                    color="textPrimary"
                  >
                    <strong>Datenschutzerklärung</strong>
                  </MuiLink>
                  &nbsp;&nbsp;
                  <MuiLink
                    component={Link}
                    to="/impressum"
                    target="_blank"
                    color="textPrimary"
                  >
                    <strong>Impressum</strong>
                  </MuiLink>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.privacy}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.accept_privacy}
                        onChange={handleChangePrivacy}
                        value="accept_privacy"
                        color="primary"
                      />
                    }
                    label={
                      <span style={{ fontSize: 13 }}>
                        {websiteSettings.contact_privacy_checkbox}
                      </span>
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.newsletter}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.newsletter}
                        onChange={handleChangeNewsletter}
                        value="newsletter"
                        color="primary"
                      />
                    }
                    label={
                      <span style={{ fontSize: 13 }}>
                        {websiteSettings.contact_newsletter_checkbox}
                      </span>
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );

  return form;
};

const mapStateToProps = ({ settings }) => {
  return {
    websiteSettings: settings,
  };
};

export default connect(mapStateToProps)(SearchAgentStep5);
