import React from "react";
import {
  Container,
  Grid,
  Typography,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import { grey } from "@material-ui/core/colors";
import XingIcon from "mdi-react/XingIcon";
import TwitterIcon from "mdi-react/TwitterIcon";
import FacebookIcon from "mdi-react/FacebookIcon";
import InstagramIcon from "mdi-react/InstagramIcon";
import YoutubeIcon from "mdi-react/YoutubeIcon";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2),
    marginTop: "auto",
    backgroundColor: theme.palette.primary.main,
  },
  footerLinks: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.between("sm", "md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  footerLink: {
    margin: theme.spacing(1),
    color: grey[900],
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(1, 2),
    },
  },
  socialLinks: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  socialIcon: {
    margin: theme.spacing(0, 1),
  },
  footerContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const location = useLocation();

  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer
      className={classes.footer}
      hidden={location.pathname === "/immobilienbewertung"}
    >
      <Container maxWidth={false} className={classes.footerContainer}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={3}>
            <Typography variant="body1">
              © {year} Marquart Immobilien
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={classes.socialLinks}>
            <IconButton
              href="http://www.facebook.com/MARQUART-IMMOBILIEN-1632382623543341/"
              className={classes.socialIcon}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/marquartimmobilien/?hl=de"
              className={classes.socialIcon}
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              href="https://twitter.com/intent/follow?source=followbutton&variant=1.0&screen_name=MarquartImmo"
              className={classes.socialIcon}
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              href="https://www.xing.com/companies/marquartimmobilien"
              className={classes.socialIcon}
            >
              <XingIcon />
            </IconButton>
            <IconButton href="/youtube" className={classes.socialIcon}>
              <YoutubeIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={6} className={classes.footerLinks}>
            <MuiLink
              component={Link}
              to="/newsletter"
              variant="body2"
              className={classes.footerLink}
            >
              Newsletter
            </MuiLink>
            <MuiLink
              component={Link}
              to="/impressum"
              variant="body2"
              className={classes.footerLink}
            >
              Impressum
            </MuiLink>
            <MuiLink
              component={Link}
              to="/datenschutzerklaerung"
              variant="body2"
              className={classes.footerLink}
            >
              Datenschutzerklärung
            </MuiLink>
            <MuiLink
              component={Link}
              to="/disclaimer"
              variant="body2"
              className={classes.footerLink}
            >
              Disclaimer
            </MuiLink>
            <MuiLink
              component={Link}
              to="/agb"
              variant="body2"
              className={classes.footerLink}
            >
              AGB
            </MuiLink>
            <MuiLink
              component={Link}
              to="/widerrufsrecht"
              variant="body2"
              className={classes.footerLink}
            >
              Widerrufsrecht
            </MuiLink>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
