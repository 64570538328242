export const organizeDataByAlphabet = (data) => {
  const organizedData = {};

  data.forEach(({ title, description }) => {
    const firstLetter = title.charAt(0).toUpperCase();

    if (!organizedData[firstLetter]) {
      organizedData[firstLetter] = [];
    }

    organizedData[firstLetter].push({ title, description });
  });

  return organizedData;
};
