import React from "react";
import convert from "htmr";
import Image from "../Image";
import { Typography, Link } from "@material-ui/core";

const transform = {
  img: node => <Image {...node} />,
  a: node => {
    return (
      <Link color="secondary" href={node.href}>
        {node.children[0]}
      </Link>
    );
  }
};

export default ({ name, text }) => (
  <Typography variant="body1" component="div">
    {text && convert(text, { transform })}
  </Typography>
);
