import React from "react";

/** external imports */
import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  formSection: {
    flex: 1,
    marginLeft: 152,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginLeft: "unset",
    },
  },
  optionClicked: {
    backgroundColor: theme.palette.primary.main,
    "& span": {
      color: "white",
    },
  },
  listItem: {
    border: "1px solid lightgray",
    marginBottom: theme.spacing(1),
    height: theme.spacing(8),
    "&:hover": {
      color: "#fff",
      background: theme.palette.primary.main,
    },
  },
  outerBox: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(10),
    },
  },
}));

const PropertyValuationReasonPage = ({ onClickOptionHandler, reason }) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.outerBox}>
      <List aria-label="housePageList" className={classes.formSection}>
        <ListItem
          button
          className={clsx(
            classes.listItem,
            reason === "Verkauf" && classes.optionClicked
          )}
          onClick={() =>
            onClickOptionHandler({
              attribute: "reason",
              value: "Verkauf",
            })
          }
        >
          <ListItemText primary="Verkauf" />
        </ListItem>

        <ListItem
          button
          className={clsx(
            classes.listItem,
            reason === "Kauf" && classes.optionClicked
          )}
          onClick={() =>
            onClickOptionHandler({
              attribute: "reason",
              value: "Kauf",
            })
          }
        >
          <ListItemText primary="Kauf" />
        </ListItem>

        <ListItem
          button
          className={clsx(
            classes.listItem,
            reason === "Ich bin neugierig." && classes.optionClicked
          )}
          onClick={() =>
            onClickOptionHandler({
              attribute: "reason",
              value: "Ich bin neugierig.",
            })
          }
        >
          <ListItemText primary="Ich bin neugierig." />
        </ListItem>
      </List>
    </Box>
  );
};

export default PropertyValuationReasonPage;
