import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import ReviewWrapper from "../layout/ReviewWrapper";

const ReviewIcons = () => {
  const classes = useStyles();

  return (
    <Box className={classes.outerBox}>
      <ReviewWrapper>
        <iframe
          title="immobilienscout24 title badge"
          src="//widget.immobilienscout24.de/anbieter/bewertung/528f8f5d1a056f463ea1723f9bd461afdb2535e1a6cac44be951458dd9693658cc0dc77a1b879b85dbdd22b3e9321dc1"
          frameBorder="0"
          style={{
            overflow: "hidden",
            width: "190px",
            height: "160px",
          }}
          marginHeight="0"
          marginWidth="0"
          scroll="no"
        />
      </ReviewWrapper>

      <ReviewWrapper>
        <a
          href="https://www.immowelt.de/profil/65db94df73e5420db8d698d20306f225#objects"
          title="Partnerschaft mit Immowelt AG"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.immowelt.de/app_themes/global_rwd/image/logo/partner-awards/partneraward_business.svg"
            alt="Immowelt-Partner MARQUART Immobilien"
            width="175"
            height="175"
          />
        </a>
      </ReviewWrapper>

      <ReviewWrapper>
        <iframe
          title="immowelt rating badge"
          src="https://homepagemodul.immowelt.de/ratingbadge/65DB94DF73E5420DB8D698D20306F225?whg=wid_mbw"
          style={{
            width: "200px",
            height: "210px",
            border: "none",
          }}
          scroll="no"
        ></iframe>
      </ReviewWrapper>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  outerBox: {
    display: "flex",
    maxWidth: "900px",
    margin: "0px auto",
    gap: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  },
}));

export default ReviewIcons;
