import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import BlockIcon from "@material-ui/icons/Block";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(6),
  },
  icon: {
    width: 80,
    height: 80,
    color: red[500],
  },
  text: {
    padding: theme.spacing(3, 10),
  },
}));

const ExposeSubscriptionFailed = ({ phoneNumber }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <BlockIcon className={classes.icon} />
      </div>
      <Typography variant="h4" color="error">
        Zugriff verweigert!
      </Typography>
      <Typography
        align="center"
        variant="body1"
        color="textPrimary"
        className={classes.text}
      >
        Leider können wir Ihnen den Zugriff auf das Exposé nicht gewähren, da 
        die Telefonnummer <b>{phoneNumber}</b> gesperrt ist.
      </Typography>
    </div>
  );
};

export default ExposeSubscriptionFailed;
