import * as constants from "../constants";
import COCKPIT_API from "../utils/COCKPIT_API";

export const fetchPages = async () => {
  let pages = undefined;
  try {
    pages = await COCKPIT_API.post(
      "collections/get/pages?token=" + constants.COCKPIT_TOKEN
    );
  } catch (error) {
    throw new Error(
      `An error occurred while fetching all pages from cockpit cms. Error message: ${error.message}`
    );
  }

  return pages;
};

export const fetchCollectionByName = async (name) => {
  let collection = undefined;
  try {
    collection = await COCKPIT_API.get(
      `collections/get/${name}?token=${
        constants.COCKPIT_TOKEN
      }&t=${Math.random()}`
    );
  } catch (error) {
    throw new Error(
      `An error occurred while fetching the collection "${name}" from cockpit cms. Error message: ${error.message}`
    );
  }

  return collection;
};

export const fetchCollections = async () => {
  let collection = undefined;
  try {
    collection = await COCKPIT_API.get(
      `collections/listCollections?token=${constants.COCKPIT_TOKEN}`
    );
  } catch (error) {
    throw new Error(
      `An error occurred while fetching all collections from cockpit cms. Error message: ${error.message}`
    );
  }

  return collection;
};

export const fetchCollectionBySlug = async (slug, collection = "pages") => {
  let page = undefined;

  try {
    page = await COCKPIT_API.post(
      `collections/get/${collection}?token=${constants.COCKPIT_TOKEN}`,
      { filter: { slug }, limit: 1 }
    );
  } catch (error) {
    throw new Error(
      `An error occurred while fetching page with slug "${slug}" from cockpit cms. Error message: ${error.message}`
    );
  }

  return page;
};
