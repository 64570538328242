import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Slider, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  disabled: {
    opacity: 0.3,
    pointerEvents: "none",
  },
  label: {
    textAlign: "center",
    paddingBottom: 30,
    [theme.breakpoints.smUp]: {
      textAlign: "left",
      paddingBottom: 0,
    },
  },
}));

const marksWohnflaeche = [
  {
    value: 20,
    label: (
      <>
        unter 40 m<sup>2</sup>
      </>
    ),
  },
  {
    value: 320,
    label: (
      <>
        über 300 m<sup>2</sup>
      </>
    ),
  },
];

const marksGrundstueck = [
  {
    value: 0,
    label: (
      <>
        unter 200 m<sup>2</sup>
      </>
    ),
  },
  {
    value: 2200,
    label: (
      <>
        über 2.000 m<sup>2</sup>
      </>
    ),
  },
];

const marksZimmer = [
  { value: 1, label: "1" },
  { value: 11, label: "über 10" },
];

const marksKaufpreis = [
  { value: 75000, label: "unter 100.000" },
  { value: 750000, label: "über 700.000" },
];

const SearchAgentStep2 = ({
  valueBaujahr,
  handleChangeBaujahr,
  valueTextBaujahr,
  valueWohnflaeche,
  handleChangeWohnflaeche,
  valueTextWohnflaeche,
  valueGrundstueck,
  handleChangeGrundstueck,
  valueTextGrundstueck,
  valueZimmer,
  handleChangeZimmer,
  valueTextZimmer,
  valueKaufpreis,
  handleChangeKaufpreis,
  valueTextKaufpreis,
  isGrundstueck,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box pt={{ xs: 1, sm: 2 }} pb={{ xs: 1, sm: 2 }} mb={3} bgcolor="#f9f9f9">
        <Typography variant="h2" align="center">
          Eckdaten
        </Typography>
      </Box>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Box
            pt={6}
            pb={2}
            pl={{ xs: 0, sm: 2 }}
            bgcolor="#fff"
            className={clsx(isGrundstueck && classes.disabled)}
          >
            <Grid container>
              <Grid item xs={12} sm={2} className={classes.label}>
                <strong>Wohnfläche</strong>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Box px={8}>
                  <Slider
                    value={valueWohnflaeche}
                    onChange={handleChangeWohnflaeche}
                    valueLabelFormat={valueTextWohnflaeche}
                    valueLabelDisplay="on"
                    step={20}
                    marks={marksWohnflaeche}
                    min={20}
                    max={320}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box pt={6} pb={2} pl={{ xs: 0, sm: 2 }} bgcolor="#fff">
            <Grid container>
              <Grid item xs={12} sm={2} className={classes.label}>
                <strong>Grundstück</strong>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Box px={8}>
                  <Slider
                    value={valueGrundstueck}
                    onChange={handleChangeGrundstueck}
                    valueLabelFormat={valueTextGrundstueck}
                    valueLabelDisplay="on"
                    step={200}
                    marks={marksGrundstueck}
                    min={0}
                    max={2200}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            pt={6}
            pb={2}
            pl={{ xs: 0, sm: 2 }}
            bgcolor="#fff"
            className={clsx(isGrundstueck && classes.disabled)}
          >
            <Grid container>
              <Grid item xs={12} sm={2} className={classes.label}>
                <strong>Zimmer</strong>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Box px={8}>
                  <Slider
                    value={valueZimmer}
                    onChange={handleChangeZimmer}
                    valueLabelFormat={valueTextZimmer}
                    valueLabelDisplay="on"
                    step={1}
                    marks={marksZimmer}
                    min={1}
                    max={11}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box pt={6} pb={2} pl={{ xs: 0, sm: 2 }} bgcolor="#fff">
            <Grid container>
              <Grid item xs={12} sm={2} className={classes.label}>
                <strong>Kaufpreis</strong>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Box px={8}>
                  <Slider
                    value={valueKaufpreis}
                    onChange={handleChangeKaufpreis}
                    valueLabelFormat={valueTextKaufpreis}
                    valueLabelDisplay="on"
                    step={25000}
                    marks={marksKaufpreis}
                    min={75000}
                    max={750000}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchAgentStep2;
