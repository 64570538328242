import React from "react";
import {
  Typography,
  makeStyles,
  Hidden,
  Button,
  Chip,
  Tooltip,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

// internal imports
import "./Overview/BlogsOverview.css";

const useStyles = makeStyles((theme) => ({
  blogTitle: {
    marginBottom: theme.spacing(2),
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  blogText: {
    marginBottom: theme.spacing(2),
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 4,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  readMoreBtn: {
    position: "absolute",
    bottom: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  createdAt: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(5),
  },
  previewHint: {
    position: "absolute",
    zIndex: 9999,
    top: theme.spacing(1),
    right: theme.spacing(2),
    backgroundColor: theme.palette.error.light,
    borderRadius: theme.spacing(5),
  },
}));

const BlogCard = ({ title, cardDescription, createdAt, slug, active }) => {
  const classes = useStyles();
  const history = useHistory();

  const detailUrl = active
    ? `/fachwissen/${slug}`
    : `/fachwissen-vorschau/${slug}`;
  const navigateToDetailPage = () => history.push(detailUrl);

  return (
    <div className="item">
      {!active ? (
        <Chip label="Vorschau" className={classes.previewHint} />
      ) : null}
      <div className="item-in">
        <Tooltip title={title}>
          <Typography variant="h4" className={classes.blogTitle}>
            {title}
          </Typography>
        </Tooltip>

        <div className="seperator"></div>
        <Typography variant="body2" className={classes.blogText}>
          {cardDescription}
        </Typography>
        <Button
          onClick={navigateToDetailPage}
          className={classes.readMoreBtn}
          size="small"
        >
          Mehr lesen
        </Button>
        <Hidden smDown>
          {createdAt ? (
            <Typography variant="body2" className={classes.createdAt}>
              Erstellt am {createdAt}
            </Typography>) : null}
        </Hidden>
        <Hidden mdUp>
          <Typography variant="body2" className={classes.createdAt}>
            {createdAt}
          </Typography>
        </Hidden>
      </div>
    </div>
  );
};

export default BlogCard;
