import React from "react";

/** external imports */
import {
  Box,
  Button,
  ButtonGroup,
  makeStyles,
  OutlinedInput,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  genderBtnGroup: {
    width: "200px",
    height: theme.spacing(8),
    border: "1px solid lightgray",
    borderRadius: 0,
    padding: "5px",
  },
  genderButtonClicked: {
    flex: 1,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    border: "none",
    color: theme.palette.text.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  genderButton: {
    flex: 1,
    borderRadius: 0,
    border: "none",
    color: theme.palette.text.main,
  },
  inputField: { flex: 1, borderRadius: 0 },
  box: {
    flex: 1,
    marginLeft: 152,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginLeft: "unset",
    },
  },
  outerBox: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(10),
    },
  },
}));

const PersonalInformationPage = ({
  city,
  street,
  postalCode,
  firstName,
  givenName,
  useObjectAddress,
  agreePrivacy,
  email,
  telephone,
  salutation,
  onClickGender,
  handleChangePersonalInfomations,
  objectAddress,
  handleSubmit,
}) => {
  const classes = useStyles();

  const onKeyPress = ({ key }) => {
    if (key !== "Enter") return;
    if (
      firstName &&
      givenName &&
      telephone &&
      email &&
      city &&
      street &&
      postalCode &&
      salutation &&
      agreePrivacy
    ) {
      handleSubmit();
    }
  };

  return (
    <Box className={classes.outerBox}>
      <Box display="flex" className={classes.box}>
        <ButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          className={classes.genderBtnGroup}
        >
          <Button
            color="primary"
            onClick={() => onClickGender({ gender: "Herr" })}
            className={
              salutation === "Herr"
                ? classes.genderButtonClicked
                : classes.genderButton
            }
          >
            Herr
          </Button>
          <Button
            onClick={() => onClickGender({ gender: "Frau" })}
            className={
              salutation === "Frau"
                ? classes.genderButtonClicked
                : classes.genderButton
            }
          >
            Frau
          </Button>
        </ButtonGroup>
      </Box>

      <Box display="flex" className={classes.box}>
        <Box display="flex" flex={3} pr={"12px"}>
          <OutlinedInput
            id="firstName-id"
            value={firstName || ""}
            onChange={(e) =>
              handleChangePersonalInfomations({ e, item: "firstName" })
            }
            aria-describedby="outlined-firstName-helper-text"
            inputProps={{
              "aria-label": "firstName",
            }}
            labelWidth={0}
            placeholder="Vorname"
            className={classes.inputField}
            onKeyPress={onKeyPress}
          />
        </Box>

        <Box display="flex" flex={3} pl={"12px"}>
          <OutlinedInput
            id="givenName-id"
            value={givenName || ""}
            onChange={(e) =>
              handleChangePersonalInfomations({ e, item: "givenName" })
            }
            aria-describedby="outlined-givenName-helper-text"
            inputProps={{
              "aria-label": "givenName",
            }}
            labelWidth={0}
            placeholder="Nachname"
            className={classes.inputField}
            onKeyPress={onKeyPress}
          />
        </Box>
      </Box>

      <Box display="flex" flex={1} className={classes.box}>
        <Box display="flex" flex={1} pr={"12px"}>
          <OutlinedInput
            id="email-id"
            value={email || ""}
            onChange={(e) =>
              handleChangePersonalInfomations({ e, item: "email" })
            }
            aria-describedby="outlined-email-helper-text"
            inputProps={{
              "aria-label": "email",
            }}
            labelWidth={0}
            placeholder="E-Mail Adresse"
            className={classes.inputField}
            onKeyPress={onKeyPress}
          />
        </Box>

        <Box display="flex" flex={1} pl={"12px"}>
          <OutlinedInput
            id="telephone-id"
            value={telephone || ""}
            onChange={(e) =>
              handleChangePersonalInfomations({ e, item: "telephone" })
            }
            aria-describedby="outlined-telephone-helper-text"
            inputProps={{
              "aria-label": "telephone",
            }}
            labelWidth={0}
            placeholder="Telefonnummer"
            className={classes.inputField}
            onKeyPress={onKeyPress}
          />
        </Box>
      </Box>

      <Box display="flex" className={classes.box}>
        <FormControlLabel
          control={
            <Checkbox
              checked={useObjectAddress}
              onChange={(e) =>
                handleChangePersonalInfomations({
                  e,
                  item: "useObjectAddress",
                  type: "checkbox",
                })
              }
              name="useObjectAddress"
              color="primary"
            />
          }
          label="Die Adresse stimmt mit der Objektadresse überein."
        />
      </Box>

      <Box display="flex" className={classes.box}>
        <Box display="flex" flex={1} pr={"12px"}>
          <OutlinedInput
            id="postalCode-id"
            value={
              useObjectAddress && objectAddress
                ? objectAddress?.postalCode || ""
                : postalCode || ""
            }
            onChange={(e) => {
              if (e.target.value < 0) return;
              return handleChangePersonalInfomations({ e, item: "postalCode" });
            }}
            aria-describedby="outlined-postalCode-helper-text"
            inputProps={{
              "aria-label": "postalCode",
            }}
            type="number"
            labelWidth={0}
            placeholder="PLZ"
            className={classes.inputField}
            disabled={useObjectAddress}
            onKeyPress={onKeyPress}
          />
        </Box>

        <Box display="flex" flex={3} pl={"12px"}>
          <OutlinedInput
            id="city-id"
            value={
              useObjectAddress && objectAddress
                ? objectAddress?.city || ""
                : city || ""
            }
            onChange={(e) =>
              handleChangePersonalInfomations({ e, item: "city" })
            }
            aria-describedby="outlined-city-helper-text"
            inputProps={{
              "aria-label": "city",
            }}
            labelWidth={0}
            placeholder="Stadt"
            className={classes.inputField}
            disabled={useObjectAddress}
            onKeyPress={onKeyPress}
          />
        </Box>
      </Box>

      <Box display="flex" flex={1} className={classes.box}>
        <OutlinedInput
          id="street-id"
          value={
            useObjectAddress && objectAddress
              ? objectAddress?.street || ""
              : street || ""
          }
          onChange={(e) =>
            handleChangePersonalInfomations({ e, item: "street" })
          }
          aria-describedby="outlined-street-helper-text"
          inputProps={{
            "aria-label": "street",
          }}
          labelWidth={0}
          placeholder="Strasse"
          className={classes.inputField}
          disabled={useObjectAddress}
          onKeyPress={onKeyPress}
        />
      </Box>

      <Box display="flex" className={classes.box}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreePrivacy}
              onChange={(e) =>
                handleChangePersonalInfomations({
                  e,
                  item: "agreePrivacy",
                  type: "checkbox",
                })
              }
              name="checkedB"
              color="primary"
            />
          }
          label="Ich willige in diese Verarbeitung meiner Daten ein und erlaube den Auftragnehmer mich über die angegeben Kontaktdaten zu kontaktieren."
        />
      </Box>
    </Box>
  );
};

export default PersonalInformationPage;
