import { Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import COCKPIT_API from "../../utils/COCKPIT_API";
import * as constants from "../../constants";
import VideoTeaser from "./VideoTeaser";

export default () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const body = {
        limit: 1000,
        sort: { _o: 1 },
      };
      const result = await COCKPIT_API.post(
        "collections/get/videos?token=" + constants.COCKPIT_TOKEN,
        body
      );

      if (result && result.data && result.data.entries) {
        setVideos(result.data.entries);
      }
    } catch (e) {
      console.log("FEHLER: ", e);
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {videos.map((video, i) => (
            <Grid item md={6} key={i}>
              <VideoTeaser video={video} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
