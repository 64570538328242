import React from "react";
import {
  DialogContentText,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center"
  },
  fieldWrapper: {
    display: "flex",
    justifyContent: "center",
    maxWidth: 240
  },
  field: {
    margin: 2,
    textAlign: "center"
  }
}));

const ExposeSubscriptionStepTwo = ({
  handleChangeInput,
  verifySms_1,
  verifySms_2,
  verifySms_3,
  verifySms_4,
  verifySmsFocus,
  result
}) => {
  const classes = useStyles();

  const fieldStyle = {
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    padding: "10px 0"
  };

  const focusVerifySms_1 = input => {
    if (input && verifySmsFocus === 1) {
      setTimeout(() => input.focus(), 0);
    } else if (input) {
      setTimeout(() => input.blur(), 0);
    }
  };

  const focusVerifySms_2 = input => {
    if (input && verifySmsFocus === 2) {
      setTimeout(() => input.focus(), 0);
    } else if (input) {
      setTimeout(() => input.blur(), 0);
    }
  };

  const focusVerifySms_3 = input => {
    if (input && verifySmsFocus === 3) {
      setTimeout(() => input.focus(), 0);
    } else if (input) {
      setTimeout(() => input.blur(), 0);
    }
  };

  const focusVerifySms_4 = input => {
    if (input && verifySmsFocus === 4) {
      setTimeout(() => input.focus(), 0);
    } else if (input) {
      setTimeout(() => input.blur(), 0);
    }
  };

  return (
    <>
      <DialogContentText color="textPrimary">
        Bitte geben Sie den Code ein, den Sie soeben per SMS erhalten haben
      </DialogContentText>
      {result === "error" && (
        <Typography color="error">
          Der eingegebene Code ist falsch. Bitte versuchen Sie es erneut.
        </Typography>
      )}
      <div className={classes.root}>
        <div className={classes.fieldWrapper}>
          <TextField
            inputRef={focusVerifySms_1}
            className={classes.field}
            inputProps={{
              style: fieldStyle
            }}
            id="verifySms_1"
            type="text"
            variant="outlined"
            onChange={handleChangeInput}
            value={verifySms_1}
          />
          <TextField
            inputRef={focusVerifySms_2}
            className={classes.field}
            inputProps={{
              style: fieldStyle
            }}
            id="verifySms_2"
            type="text"
            variant="outlined"
            onChange={handleChangeInput}
            value={verifySms_2}
          />
          <TextField
            inputRef={focusVerifySms_3}
            className={classes.field}
            inputProps={{
              style: fieldStyle
            }}
            id="verifySms_3"
            type="text"
            variant="outlined"
            onChange={handleChangeInput}
            value={verifySms_3}
          />
          <TextField
            inputRef={focusVerifySms_4}
            className={classes.field}
            inputProps={{
              style: fieldStyle
            }}
            id="verifySms_4"
            type="text"
            variant="outlined"
            onChange={handleChangeInput}
            value={verifySms_4}
          />
        </div>
      </div>
    </>
  );
};

export default ExposeSubscriptionStepTwo;
