import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  separatorWrapper: {
    height: ({ noHeight }) => (noHeight ? 0 : 60),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      height: ({ noHeight }) => (noHeight ? 0 : 100),
    },
  },
  separatorWrapperDense: {
    height: 80,
  },
  separator: {
    width: 90,
    height: 3,
    background: theme.palette.primary.main,
  },
}));

const Separator = ({ dense, noHeight }) => {
  const classes = useStyles({ noHeight });

  return (
    <div
      className={clsx(
        classes.separatorWrapper,
        dense && classes.separatorWrapperDense
      )}
    >
      <div className={classes.separator} />
    </div>
  );
};

export default Separator;
