import React from "react";

/** external imports */
import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  formSection: {
    flex: 1,
    marginLeft: 152,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginLeft: "unset",
    },
  },
  listItem: {
    border: "1px solid lightgray",
    marginBottom: theme.spacing(1),
    height: theme.spacing(8),
    "&:hover": {
      color: "#fff",
      background: theme.palette.primary.main,
    },
  },
  optionClicked: {
    backgroundColor: theme.palette.primary.main,
    "& span": {
      color: "white",
    },
  },
  outerBox: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(10),
    },
  },
}));

const PropertyQualityPage = ({ quality, onClickOptionHandler }) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.outerBox}>
      <List aria-label="housePageList" className={classes.formSection}>
        <ListItem
          button
          className={clsx(
            classes.listItem,
            quality === "Einfach" && classes.optionClicked
          )}
          onClick={() =>
            onClickOptionHandler({ attribute: "quality", value: "Einfach" })
          }
        >
          <ListItemText primary="Einfach" />
        </ListItem>

        <ListItem
          button
          className={clsx(
            classes.listItem,
            quality === "Normal" && classes.optionClicked
          )}
          onClick={() =>
            onClickOptionHandler({ attribute: "quality", value: "Normal" })
          }
        >
          <ListItemText primary="Normal" />
        </ListItem>

        <ListItem
          button
          className={clsx(
            classes.listItem,
            quality === "Gehoben" && classes.optionClicked
          )}
          onClick={() =>
            onClickOptionHandler({ attribute: "quality", value: "Gehoben" })
          }
        >
          <ListItemText primary="Gehoben" />
        </ListItem>

        <ListItem
          button
          className={clsx(
            classes.listItem,
            quality === "Luxus" && classes.optionClicked
          )}
          onClick={() =>
            onClickOptionHandler({ attribute: "quality", value: "Luxus" })
          }
        >
          <ListItemText primary="Luxus" />
        </ListItem>
      </List>
    </Box>
  );
};

export default PropertyQualityPage;
