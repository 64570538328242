import { Button, makeStyles, MobileStepper, useTheme } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React from "react";

const CustomMobileStepper = ({
  steps,
  activeStep,
  handleNext,
  handleBack,
  disabled,
  handleSubmit,
}) => {
  const theme = useTheme();

  const useStyles = makeStyles((theme) => ({
    mobileStepper: {
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
      borderTop: "1px solid lightgray",
      backgroundColor: "rgba(255, 255, 255, 0.6)",
      [theme.breakpoints.down("xs")]: {
        position: "fixed",
        minHeight: "5rem",
        width: "100%",
        bottom: "0px",
        left: "0px",
        display: "flex",
        justifyContent: "spaceBetween",
        marginTop: "auto",
        borderTop: "1px solid lightgray",
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        padding: "0.5rem",
        backdropFilter: "blur(10px)",
      },
    },
    button: {
      background: theme.palette.primary.main,
      color: "#fff",
      "&:hover": {
        background: theme.palette.primary.dark,
      },
    },
  }));

  const classes = useStyles();

  const renderNextButton = () => {
    let isComplete = activeStep === steps - 1;

    return (
      <Button
        onClick={isComplete ? handleSubmit : handleNext}
        disabled={disabled}
        className={!disabled ? classes.button : null}
        variant="outlined"
      >
        {isComplete ? "Abfrage senden" : "Weiter"}
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </Button>
    );
  };

  const renderBackButton = () => (
    <Button
      onClick={handleBack}
      variant="outlined"
      color="primary"
      disabled={activeStep === 0}
      className={activeStep !== 0 ? classes.button : null}
    >
      {theme.direction === "rtl" ? (
        <KeyboardArrowRight />
      ) : (
        <KeyboardArrowLeft />
      )}
      Zurück
    </Button>
  );

  return (
    <MobileStepper
      variant="text"
      steps={steps}
      position="bottom"
      activeStep={activeStep}
      className={classes.mobileStepper}
      nextButton={renderNextButton()}
      backButton={renderBackButton()}
    />
  );
};

export default CustomMobileStepper;
