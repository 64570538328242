import React, { useMemo, useState } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

// Internal imports
import CustomContainer from "../../global/CustomContainer";
import TitleWithSeparator from "../../global/TitleWithSeparator";
import headerImg from "../../../images/ein-homeoffice-mit-blick-auf-die-stadt.jpg";
import { sortDataByAlphabet } from "../../../utils/sortDataByAlphabet";
import CustomAccordion from "../../global/CustomAccordian";
import CustomSubtitle from "../../global/CustomSubtitle";
import { organizeDataByAlphabet } from "../../../utils/storeDataByAlphabet";
import { data } from "./lexiconData.json";

const Lexicon = () => {
  const classes = useStyles();

  const sortedByAlphabet = sortDataByAlphabet(data);
  const organizedData = organizeDataByAlphabet(sortedByAlphabet);
  const lexiconKeys = useMemo(
    () => data.map((lexicon) => lexicon.title.toLowerCase()),
    []
  );

  const [isClickedWord, setIsClickedWord] = useState(null);

  const renderAccordianByAlphabet = () => {
    let accordians = [];
    for (const [key, value] of Object.entries(organizedData)) {
      accordians.push(
        <Box display="flex" mt={1} className={classes.outerBoxAccordian}>
          <Box p={2} fontWeight="bolder" fontSize={15} minWidth={45}>
            {key}
          </Box>
          <div>
            {value?.map(({ title, description }) => (
              <CustomAccordion
                title={title}
                description={description}
                lexiconKeys={lexiconKeys.filter(
                  (key) => key.toLowerCase() !== title.toLowerCase()
                )}
                setIsClickedWord={(word) => setIsClickedWord(word)}
                scrollToTitle={isClickedWord}
              />
            ))}
          </div>
        </Box>
      );
    }

    return accordians;
  };

  return (
    <CustomContainer>
      <TitleWithSeparator title="Immobilienlexikon" />
      <CustomSubtitle text="Das Vokabular der Immobilienbranche ist breitgefächert. Sie haben Fragen zu Fachbegriffen oder sind sich bei ihrer Bedeutung unsicher? In unserem Lexikon finden Sie die wichtigsten Begriffe kurz und verständlich erläutert." />

      <div className={classes.outerDivHeaderImg}>
        <img src={headerImg} alt="header" className={classes.headerImg} />
      </div>

      <Box mt={5}>{renderAccordianByAlphabet()}</Box>

      <Typography variant="h6" className={classes.hint}>
        <b>Hinweis:</b> Unser Immobilienlexikon sowie sämtliche Artikel dienen
        lediglich zu Informationszwecken und ersetzen keine Rechts- oder
        Finanzberatung. Unsere Beiträge werden mit größter Sorgfalt recherchiert
        und stets aktualisiert, vereinzelte Fehler sind dennoch nicht
        auszuschließen. Ihnen ist ein Begriff unklar oder Sie vermissen ein
        Fachwort in unserer Liste? Kontaktieren Sie uns gerne!{" "}
      </Typography>
    </CustomContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  outerDivHeaderImg: {
    width: "100%",
    textAlign: "center",
  },
  headerImg: {
    objectFit: "cover",
    marginTop: theme.spacing(6),
    width: "100%",
    maxWidth: "800px",
    maxHeight: "400px",
    textAlign: "center",
  },
  accordions: {
    marginTop: theme.spacing(6),
  },
  hint: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  outerBoxAccordian: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
}));

export default Lexicon;
