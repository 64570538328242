import { Dialog, DialogTitle, makeStyles } from "@material-ui/core";
import React from "react";

const CustomDialog = ({ title, isOpen, handleClose, children, id }) => {
  const classes = useStyles();

  return (
    <Dialog
      id={id}
      onClose={handleClose}
      open={isOpen}
      classes={{ paper: classes.paper }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {children}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1.5),
  },
}));

export default CustomDialog;
