import React, { useState } from "react";

/** external imports */
import ApartmentOutlinedIcon from "@material-ui/icons/ApartmentOutlined";
import { Box, Container, Hidden, Typography } from "@material-ui/core";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

/** internal imports */
import PropertyValuationApartment from "./PropertyValuationApartment";
import PropertyValuationHouse from "./PropertyValuationHouse";
import CustomListItem from "../../global/CustomListItem";
import AnalysingIcon from "../../../images/immobilienbewertung/analysing.svg";
import ErgebnisIcon from "../../../images/immobilienbewertung/ergebnis.svg";
import ApartmentsIcon from "../../../images/immobilienbewertung/apartments.svg";
import Separator from "../../layout/Separator";
import ThorstenImage from "../../../images/flowfact/5_Thorsten_small.png";
import ChristinaImage from "../../../images/flowfact/2_Christina_small.png";
import LogoImage from "../../../images/flowfact/logo-marquart_small.png";
import FacebookIcon from "../../../images/flowfact/facebook.png";
import GoogleMapsIcon from "../../../images/flowfact/google-maps.png";
import InstagramIcon from "../../../images/flowfact/instagram.png";
import TwitterIcon from "../../../images/flowfact/twitter.png";
import WhatsAppIcon from "../../../images/flowfact/whatsapp.png";
import XingIcon from "../../../images/flowfact/xing.png";
import YoutubeIcon from "../../../images/flowfact/youtube.png";

const useStyles = makeStyles((theme) => ({
  benefitBox: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(2),
    },
  },
  subtitle: {
    textAlign: "left",
    marginTop: theme.spacing(2),
  },
  objectBox: {
    border: "1px solid rgb(217, 217, 217)",
    display: "flex",
    alignItems: "center",
    minWidth: 220,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& p": {
        color: "white",
        fontWeight: "bold",
      },
      "& svg": {
        color: "white",
      },
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center",
      padding: 20,
      flexDirection: "column",
      marginRight: 20,
    },
  },
  outerDivObjectBox: {
    display: "flex",
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  },
  closeBtnText: {
    paddingRight: theme.spacing(1),
  },
  outerDivCloseBtn: {
    cursor: "pointer",
    display: "contents",
  },
  outerDivPageHeader: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 5px 10px 0",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "unset",
      display: "none",
    },
  },
  listItemIcon: { marginRight: 5, color: theme.palette.primary.dark },
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  selectionPageOptions: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  selectionPageOuterBox: {
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  selectionPageInnerLeftBox: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "unset",
    },
  },
  selectionPageInnerRightBox: {
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontFamily: theme.page.fontFamily,
    lineHeight: theme.page.lineHeight,
  },
  instructionsTitle: {
    marginTop: theme.spacing(5),
  },
  objectIcon: {
    fontSize: theme.spacing(5),
    color: theme.palette.primary.dark,
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.spacing(10),
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  arrowIconBox: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  instructions: {
    display: "flex",
    gap: theme.spacing(4),
    margin: `${theme.spacing(8)}`,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  instructionsBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 300,
    "& img": {
      marginBottom: theme.spacing(2),
    },
    "& p": {
      marginTop: theme.spacing(2),
    },
  },
  flowFactImage: {
    maxHeight: 150,
    display: "none",
  },
  hint: {
    textAlign: "left",
    marginLeft: theme.spacing(1),
  },
}));

const __APARTMENT_PAGE_NAME = "Wohnung";
const __HOUSE_PAGE_NAME = "Haus";

const PropertyValuation = () => {
  const classes = useStyles();

  const [selectedObject, setSelectedObject] = useState({
    object: null,
    steps: 0,
  });

  const renderPageHeader = () => (
    <div className={classes.outerDivPageHeader}>
      <div className={classes.outerDivCloseBtn} onClick={resetStepper}>
        <p className={classes.closeBtnText}>SCHLIESSEN</p>
        <CloseIcon fontSize="small" />
      </div>
    </div>
  );

  const renderStepper = () => {
    if (selectedObject.object === __HOUSE_PAGE_NAME) {
      return (
        <PropertyValuationHouse
          resetStepper={resetStepper}
          objectType={selectedObject?.object}
          steps={9}
        />
      );
    }

    if (selectedObject.object === __APARTMENT_PAGE_NAME) {
      return (
        <PropertyValuationApartment
          resetStepper={resetStepper}
          objectType={selectedObject?.object}
          steps={9}
        />
      );
    }
  };

  const resetStepper = () => {
    setSelectedObject({ object: null, steps: 0 });
  };

  const renderSelectionPage = () => {
    let benefits = [
      "Aktuelle Marktpreise",
      "Beratung durch Experten",
      "Kostenlose Immobilienbewertung",
    ];

    return (
      <>
        <div className={classes.benefitBox}>
          {benefits?.map((benefit, index) => (
            <React.Fragment key={index}>
              <CustomListItem text={benefit}>
                <CheckCircleIcon
                  className={classes.listItemIcon}
                  fontSize="small"
                />
              </CustomListItem>
            </React.Fragment>
          ))}
        </div>

        <Typography variant="body1" className={classes.subtitle}>
          Jetzt Ihre Immobilie kostenlos und unverbindlich bewerten lassen!
        </Typography>

        {/* <div className={classes.subtitle}>
          <p>
            Jetzt Ihre Immobilie kostenlos und unverbindlich bewerten lassen!
          </p>
        </div> */}
      </>
    );
  };

  const renderSelectionPageOptions = () => (
    <Box display="flex" className={classes.selectionPageOptions}>
      <div className={classes.outerDivObjectBox}>
        <div
          className={classes.objectBox}
          onClick={() =>
            setSelectedObject({ object: __HOUSE_PAGE_NAME, steps: 3 })
          }
        >
          <HomeOutlinedIcon className={classes.objectIcon} />
          <Typography variant="body1">Haus</Typography>
        </div>

        <div
          className={classes.objectBox}
          onClick={() =>
            setSelectedObject({ object: __APARTMENT_PAGE_NAME, steps: 5 })
          }
        >
          <ApartmentOutlinedIcon className={classes.objectIcon} />
          <Typography variant="body1">Wohnung</Typography>
        </div>
      </div>
    </Box>
  );

  const renderMainPage = () => (
    <Box display="flex" className={classes.selectionPageOuterBox}>
      <Box
        display="flex"
        flexDirection="column"
        flex={2}
        className={classes.selectionPageInnerLeftBox}
      >
        {!selectedObject?.object && renderSelectionPage()}
      </Box>
    </Box>
  );

  const renderHint = () => (
    <Box display="flex" alignItems="center" mt={6}>
      <ErrorOutlineIcon color="primary" />
      <Typography variant="body1" className={classes.hint}>
        Der exakte Wert einer Immobilie unter Berücksichtigung aller
        Besonderheiten kann nur vor Ort ermittelt werden.
      </Typography>
    </Box>
  );

  const RightArrow = () => (
    <Hidden smDown>
      <Box className={classes.arrowIconBox}>
        <ArrowForwardIosIcon fontSize="large" />
      </Box>
    </Hidden>
  );

  const renderInstructions = () => (
    <Box className={classes.instructions}>
      <Box className={classes.instructionsBox}>
        <img src={ApartmentsIcon} width="80" height="80" alt="apartmentsIcon" />

        <Typography variant="h5" align="center">
          Die wesentlichen Angaben
        </Typography>
        <Typography variant="h5" align="center">
          zu Ihrer Immobilie
        </Typography>

        <Typography variant="body1" align="left">
          Sie übermitteln uns über das Formular erste, zentrale Angaben zu Ihrer
          Immobilie, die wir im Anschluss in unserer Software anlegen.
        </Typography>
      </Box>
      <RightArrow />

      <Box className={classes.instructionsBox}>
        <img src={AnalysingIcon} width="80" height="80" alt="analysingIcon" />
        <Typography variant="h5" align="center">
          Erste Marktpreiseinschätzung nach dem Telefonat
        </Typography>

        <Typography variant="body1" align="left">
          Wir kontaktieren Sie telefonisch, um Ihre persönliche Situation, sowie
          weitere Eigenschaften der Immobilie zu besprechen. Im Anschluss
          erhalten Sie von uns eine grobe Marktpreiseinschätzung.
        </Typography>
      </Box>
      <RightArrow />

      <Box className={classes.instructionsBox}>
        <img src={ErgebnisIcon} width="80" height="80" alt="ergebnisIcon" />
        <Typography variant="h5" align="center">
          Präzisierung durch den Termin vor Ort
        </Typography>

        <Typography variant="body1" align="left">
          Für eine präzise Bewertung ist immer ein Termin vor Ort notwendig. Die
          Besonderheiten Ihrer Immobilie, der bauliche Zustand, sowie die
          Einzigartigkeit von Ausstattung und Lage, beeinflussen den Wert
          maßgeblich.
        </Typography>
      </Box>
    </Box>
  );

  const renderTitle = () => (
    <Typography variant="h2" align="center" className={classes.title}>
      Kostenlose Immobilienbewertung
    </Typography>
  );

  const renderSocialMediaIcons = () => (
    <>
      <img
        src={ThorstenImage}
        alt="Thorsten"
        className={classes.flowFactImage}
      />
      <img
        src={ChristinaImage}
        alt="Christina"
        className={classes.flowFactImage}
      />
      <img src={LogoImage} alt="Logo" className={classes.flowFactImage} />
      <img
        src={FacebookIcon}
        alt="FacebookIcon"
        className={classes.flowFactImage}
      />
      <img
        src={GoogleMapsIcon}
        alt="GoogleMapsIcon"
        className={classes.flowFactImage}
      />
      <img
        src={InstagramIcon}
        alt="InstagramIcon"
        className={classes.flowFactImage}
      />
      <img
        src={TwitterIcon}
        alt="TwitterIcon"
        className={classes.flowFactImage}
      />
      <img
        src={WhatsAppIcon}
        alt="WhatsAppIcon"
        className={classes.flowFactImage}
      />
      <img src={XingIcon} alt="XingIcon" className={classes.flowFactImage} />
      <img
        src={YoutubeIcon}
        alt="YoutubeIcon"
        className={classes.flowFactImage}
      />
    </>
  );

  return (
    <Container
      maxWidth={!selectedObject?.object ? "lg" : "md"}
      id="propertyValuation"
      className={classes.container}
    >
      {!selectedObject?.object && (
        <>
          {renderTitle()}
          {renderMainPage()}
          {renderSelectionPageOptions()}
          <Typography
            variant="h3"
            align="center"
            className={classes.instructionsTitle}
          >
            So einfach funktioniert die Immobilienbewertung
          </Typography>
          <Separator dense />
          {renderInstructions()}
          {renderHint()}
          {renderSocialMediaIcons()}
        </>
      )}

      {selectedObject?.object && renderPageHeader()}
      {selectedObject?.object && renderStepper()}
    </Container>
  );
};

export default PropertyValuation;
