import { makeStyles } from "@material-ui/core";
import React from "react";

const ReviewWrapper = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.outerDiv}>{children}</div>;
};

const useStyles = makeStyles((theme) => ({
  outerDiv: {
    width: "300px",
    height: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
  },
}));

export default ReviewWrapper;
