import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  InputLabel,
  Typography,
  Checkbox,
  FormControlLabel,
  DialogActions,
  Button,
  useMediaQuery,
  Grid,
  Box,
  Tabs,
  Tab,
  IconButton,
  Hidden,
} from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import PhoneIcon from "mdi-react/PhoneIcon";
import * as constants from "../../constants";
import { Link, Redirect } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import { green, grey } from "@material-ui/core/colors";
import { connect } from "react-redux";
import useScreensize from "../../utils/useScreensize";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    right: 10,
    bottom: 10,
    zIndex: 999,
    color: "white",
    [constants.THEME.breakpoints.up("sm")]: {
      right: 30,
      bottom: 30,
    },
  },
  phone: {
    display: "flex",
  },
  phoneCountry: {
    minWidth: 80,
    marginRight: constants.THEME.spacing(1),
  },
  phonePrefix: {
    marginRight: constants.THEME.spacing(1),
  },
  phoneNumber: {
    minWidth: 100,
    flexGrow: 1,
  },
  phoneWrapper: {
    padding: constants.THEME.spacing(2, 0),
  },
  privacy: {
    display: "flex",
    padding: theme.spacing(0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(-1, 0),
    },
  },
  newsletter: {
    padding: theme.spacing(0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(-1, 0),
    },
  },
  privacyLabel: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  privacyInfo: {
    fontSize: 12,
  },
  links: {
    marginTop: constants.THEME.spacing(1),
  },
  successMessage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 70,
    height: 70,
    borderRadius: "50%",
    borderColor: green[500],
    borderWidth: 3,
    borderStyle: "solid",
    margin: constants.THEME.spacing(6),
  },
  icon: {
    width: 40,
    height: 40,
    color: green[500],
  },
  text: {
    padding: constants.THEME.spacing(3, 10),
  },
  dialogTitle: {
    [constants.THEME.breakpoints.up("xs")]: {
      background: grey[100],
      boxShadow:
        "0px 1px 6px 0px rgba(0,0,0,0.2), 0px 1px 2px 0px rgba(0,0,0,0.14), 0px 2px 2px -1px rgba(0,0,0,0.12)",
    },
  },
  dialogActions: {
    [constants.THEME.breakpoints.up("xs")]: {
      background: grey[100],
      boxShadow:
        "0px 1px 6px 2px rgba(0,0,0,0.2), 0px 1px 2px 2px rgba(0,0,0,0.14), 0px 2px 2px 1px rgba(0,0,0,0.12)",
    },
  },
  gridItem: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  btnIcon: {
    marginRight: theme.spacing(1),
  },
  phoneLink: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
  },
  btnText: {
    paddingRight: theme.spacing(2),
  },
}));

const initialContactFormValues = {
  salutation: "",
  firstname: "",
  lastname: "",
  street: "",
  zip: "",
  city: "",
  email: "",
  phone: "",
  message: "",
  accept_privacy: false,
  newsletter: false,
};

const initialCallBackValues = {
  name: "",
  phone: "",
  message: "",
  accept_privacy: false,
};

const ContactForm = ({
  websiteSettings,
  buttonText,
  buttonColor,
  buttonVariant,
  showButtonIcon = true,
  leavePageOnClose = true,
  isMobile = true,
}) => {
  const classes = useStyles();

  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(initialContactFormValues);
  const [valuesCallBack, setValuesCallBack] = useState(initialCallBackValues);
  const [showSearchAgent, setShowSearchAgent] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const fullScreen = useMediaQuery(constants.THEME.breakpoints.down("sm"));
  const { xs } = useScreensize();

  const handleChangeTab = (event, val) => {
    setTabValue(val);
  };

  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setValues(initialContactFormValues);
    setValuesCallBack(initialCallBackValues);
    setOpen(false);
    setSuccess(false);
  
    if (leavePageOnClose) {
      setShowSearchAgent(true);
      setTimeout(() => {
        setShowSearchAgent(false);
      }, 0);
    }
  };

  const handleChangeSelect = (event) => {
    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeInput = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangePrivacy = () => {
    const newPrivacy = !values.accept_privacy;
    setValues({ ...values, accept_privacy: newPrivacy });
  };

  const handleChangeNewsletter = () => {
    const newNewsletter = !values.newsletter;
    setValues({ ...values, newsletter: newNewsletter });
  };

  const handleChangeCallBackInput = (name) => (event) => {
    setValuesCallBack({ ...valuesCallBack, [name]: event.target.value });
  };

  const handleChangeCallBackPrivacy = () => {
    const newPrivacy = !valuesCallBack.accept_privacy;
    setValuesCallBack({ ...valuesCallBack, accept_privacy: newPrivacy });
  };

  const handleSend = async (event) => {
    event.preventDefault();

    const {
      salutation,
      email,
      firstname,
      lastname,
      street,
      zip,
      city,
      phone,
      newsletter,
      message,
    } = values;

    const messageWithHostInfo = message + "\n\n" + window.location.href;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(constants.API_URL + "contact", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        salutation,
        email,
        firstname,
        lastname,
        street,
        zip,
        city,
        phone_number: phone,
        newsletter,
        message: messageWithHostInfo,
      }),
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        setSuccess(true);
      });
  };

  const handleSendCallBack = async (event) => {
    event.preventDefault();

    const { name, phone, message } = valuesCallBack;

    const messageWithHostInfo = message + "\n\n" + window.location.href;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(constants.API_URL + "callback", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name,
        phone_number: phone,
        message: messageWithHostInfo,
      }),
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        setSuccess(true);
      });
  };

  const valid =
    values.salutation &&
    values.email &&
    values.firstname &&
    values.street &&
    values.zip &&
    values.city &&
    values.lastname &&
    values.phone &&
    values.accept_privacy &&
    values.message;

  const validCallback =
    valuesCallBack.name &&
    valuesCallBack.phone &&
    valuesCallBack.accept_privacy;

  const successMessage = (
    <div className={classes.successMessage}>
      <div className={classes.iconWrapper}>
        <CheckIcon className={classes.icon} />
      </div>
      <Typography variant="h4">Vielen Dank für Ihre Anfrage!</Typography>
      <Typography
        align="center"
        variant="body1"
        color="textPrimary"
        className={classes.text}
      >
        Wir setzen uns so bald wie möglich mit Ihnen in Verbindung
      </Typography>
    </div>
  );

  const getContactPrivacyInfo = () => {
    if (websiteSettings?.contact_privacy_info) {
      const parsedPrivacyInfo = websiteSettings?.contact_privacy_info?.split(
        "Info[at]marquart-immobilien.de"
      );
      return parsedPrivacyInfo[1] || websiteSettings?.contact_privacy_info;
    } else {
      return websiteSettings?.contact_privacy_info;
    }
  };

  const form = (
    <>
      <DialogContentText color="textPrimary">
        Sie haben Fragen zu einer Immobilie oder möchten gerne Ihre Immobilie
        verkaufen? Gerne helfen wir Ihnen weiter.
      </DialogContentText>
      <Grid container spacing={xs ? 0 : 2}>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel htmlFor="salutation">Anrede</InputLabel>
            <Select
              value={values.salutation}
              onChange={handleChangeSelect}
              input={
                <OutlinedInput
                  labelWidth={50}
                  name="salutation"
                  id="salutation"
                />
              }
            >
              <MenuItem value={"Herr"}>Herr</MenuItem>
              <MenuItem value={"Frau"}>Frau</MenuItem>
              <MenuItem value={"Divers"}>Divers</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="firstname"
            label="Vorname"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("firstname")}
            value={values.firstname}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="lastname"
            label="Nachname"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("lastname")}
            value={values.lastname}
          />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="street"
            label="Straße, Haus-Nr."
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("street")}
            value={values.street}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="zip"
            label="PLZ"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("zip")}
            value={values.zip}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="city"
            label="Ort"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("city")}
            value={values.city}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="email"
            label="E-Mail-Adresse"
            type="email"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("email")}
            value={values.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="phone"
            label="Telefon"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("phone")}
            value={values.phone}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="message"
            label="Ihre Nachricht"
            type="text"
            variant="outlined"
            fullWidth
            multiline
            onChange={handleChangeInput("message")}
            value={values.message}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography variant="body2" className={classes.privacyInfo}>
              {getContactPrivacyInfo()}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.links}>
            <MuiLink
              component={Link}
              target="_blank"
              to="/datenschutzerklaerung"
              color="textPrimary"
            >
              <strong>Datenschutzerklärung</strong>
            </MuiLink>
            &nbsp;&nbsp;
            <MuiLink
              component={Link}
              to="/impressum"
              target="_blank"
              color="textPrimary"
            >
              <strong>Impressum</strong>
            </MuiLink>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.privacy}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.accept_privacy}
                  onChange={handleChangePrivacy}
                  value="accept_privacy"
                  color="primary"
                />
              }
              label={
                <span style={{ fontSize: 13 }}>
                  {websiteSettings.contact_privacy_checkbox}
                </span>
              }
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.newsletter}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.newsletter}
                  onChange={handleChangeNewsletter}
                  value="newsletter"
                  color="primary"
                />
              }
              label={
                <span style={{ fontSize: 13 }}>
                  {websiteSettings.contact_newsletter_checkbox}
                </span>
              }
            />
          </div>
        </Grid>
      </Grid>
    </>
  );

  const callbackForm = (
    <>
      <Box color="textPrimary" mb={2}>
        <Typography variant="h3" component="div">
          Wir rufen Sie gerne zurück
        </Typography>
        <Box mt={1}>
          Sie erreichen uns von Montag bis Freitag jeweils von 9:00 bis 18:00
          Uhr
          <br />
          unter der Telefonnummer{" "}
          <a className={classes.phoneLink} href="tel:+49-971-12382980">
            0971 - 123 82 980
          </a>
          .
        </Box>
        <Box mt={1}>
          <strong>
            Gerne bieten wir Ihnen auch unseren Rückrufservice an.
          </strong>
        </Box>
      </Box>
      <Grid container spacing={xs ? 0 : 2}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeCallBackInput("name")}
            value={valuesCallBack.name}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="phone"
            label="Telefon"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeCallBackInput("phone")}
            value={valuesCallBack.phone}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="message"
            label="Ihre Nachricht (optional)"
            type="text"
            variant="outlined"
            fullWidth
            multiline
            onChange={handleChangeCallBackInput("message")}
            value={valuesCallBack.message}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography variant="body2" className={classes.privacyInfo}>
              {getContactPrivacyInfo()}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.links}>
            <MuiLink
              component={Link}
              target="_blank"
              to="/datenschutzerklaerung"
              color="textPrimary"
            >
              <strong>Datenschutzerklärung</strong>
            </MuiLink>
            &nbsp;&nbsp;
            <MuiLink
              component={Link}
              to="/impressum"
              target="_blank"
              color="textPrimary"
            >
              <strong>Impressum</strong>
            </MuiLink>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.privacy}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={valuesCallBack.accept_privacy}
                  onChange={handleChangeCallBackPrivacy}
                  value="accept_privacy"
                  color="primary"
                />
              }
              label={
                <span style={{ fontSize: 13 }}>
                  {websiteSettings.contact_privacy_checkbox}
                </span>
              }
            />
          </div>
        </Grid>
      </Grid>
    </>
  );

  if (showSearchAgent) {
    return <Redirect to="/suchagent" />;
  }

  return (
    <>
      {isMobile ? (
        <Hidden lgUp>
          <IconButton onClick={handleOpen} color="secondary">
            <PhoneIcon />
          </IconButton>
        </Hidden>
      ) : null}

      <Hidden mdDown={isMobile ? true : false}>
        <Button
          onClick={handleOpen}
          color={buttonColor ? buttonColor : "secondary"}
          variant={buttonVariant ?? "text"}
        >
          {showButtonIcon ? <PhoneIcon className={classes.btnIcon} /> : null}
          <span className={classes.btnText}>
            {buttonText ? buttonText : "Kontakt"}
          </span>
        </Button>
      </Hidden>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown={!success}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          Kontakt
        </DialogTitle>
        <DialogContent>
          {success && (
            <Box pt={3} pb={2}>
              {successMessage}
            </Box>
          )}

          {!success && (
            <>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                indicatorColor="secondary"
                textColor="secondary"
              >
                <Tab label="Rückrufservice" />
                <Tab label="Kontaktformular" />
              </Tabs>
              {tabValue === 0 && (
                <Box pt={3} pb={2}>
                  {callbackForm}
                </Box>
              )}

              {tabValue === 1 && (
                <Box pt={3} pb={2}>
                  {form}
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleClose}
            color="primary"
            disableFocusRipple={true}
          >
            {success ? "Schließen" : "Abbrechen"}
          </Button>
          {!success && (
            <Button
              variant="contained"
              onClick={
                (tabValue === 0 && handleSendCallBack) ||
                (tabValue === 1 && handleSend)
              }
              color="primary"
              disabled={
                (tabValue === 0 && !validCallback) || (tabValue === 1 && !valid)
              }
            >
              Senden
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ settings }) => {
  return {
    websiteSettings: settings,
  };
};

export default connect(mapStateToProps)(ContactForm);
