import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useScreensize from "../../utils/useScreensize";
import green from "@material-ui/core/colors/green";
import CheckIcon from "@material-ui/icons/Check";
import * as constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  privacy: {
    display: "flex",
    padding: theme.spacing(0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(-1, 0),
    },
  },
  privacyInfo: {
    fontSize: 12,
  },
  links: {
    marginTop: theme.spacing(1),
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 70,
    height: 70,
    borderRadius: "50%",
    borderColor: green[500],
    borderWidth: 3,
    borderStyle: "solid",
    margin: theme.spacing(2, 6),
  },
  icon: {
    width: 40,
    height: 40,
    color: green[500],
  },
}));

const NewsletterForm = ({ websiteSettings }) => {
  const classes = useStyles();
  const { xs } = useScreensize();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [values, setValues] = useState({
    salutation: "",
    firstname: "",
    lastname: "",
    street: "",
    zip: "",
    city: "",
    email: "",
    phone_number: "",
    accept_privacy: false,
  });

  const handleSubmit = () => {
    setSubmitted(true);
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(constants.API_URL + "newsletter", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(values),
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        setSuccess(true);
        setSubmitted(false);
      })
      .catch((error) => {
        setSubmitted(false);
      });
  };

  const handleChangeSelect = (event) => {
    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeInput = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangePrivacy = () => {
    const newPrivacy = !values.accept_privacy;
    setValues({ ...values, accept_privacy: newPrivacy });
  };

  const isValid = Object.keys(values).every((key) => Boolean(values[key]));

  const form = (
    <>
      <Grid container spacing={xs ? 0 : 2}>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel htmlFor="salutation">Anrede</InputLabel>
            <Select
              value={values.salutation}
              onChange={handleChangeSelect}
              input={
                <OutlinedInput
                  labelWidth={50}
                  name="salutation"
                  id="salutation"
                />
              }
            >
              <MenuItem value={"Herr"}>Herr</MenuItem>
              <MenuItem value={"Frau"}>Frau</MenuItem>
              <MenuItem value={"Divers"}>Divers</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="firstname"
            label="Vorname"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("firstname")}
            value={values.firstname}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="lastname"
            label="Nachname"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("lastname")}
            value={values.lastname}
          />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="street"
            label="Straße, Haus-Nr."
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("street")}
            value={values.street}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="zip"
            label="PLZ"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("zip")}
            value={values.zip}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="city"
            label="Ort"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("city")}
            value={values.city}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="email"
            label="E-Mail-Adresse"
            type="email"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("email")}
            value={values.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <TextField
            margin="dense"
            id="phone_number"
            label="Telefon"
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChangeInput("phone_number")}
            value={values.phone_number}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography variant="body2" className={classes.privacyInfo}>
              {websiteSettings.contact_privacy_info}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.links}>
            <MuiLink
              component={Link}
              target="_blank"
              to="/datenschutzerklaerung"
              color="textPrimary"
            >
              <strong>Datenschutzerklärung</strong>
            </MuiLink>
            &nbsp;&nbsp;
            <MuiLink
              component={Link}
              to="/impressum"
              target="_blank"
              color="textPrimary"
            >
              <strong>Impressum</strong>
            </MuiLink>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.privacy}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.accept_privacy}
                  onChange={handleChangePrivacy}
                  value="accept_privacy"
                  color="primary"
                />
              }
              label={
                <span style={{ fontSize: 13 }}>
                  {websiteSettings.contact_privacy_checkbox}
                </span>
              }
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!isValid || submitted}
          >
            Newsletter jetzt abonnieren
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const successMessage = (
    <>
      <Box display="flex" justifyContent="center">
        <div className={classes.iconWrapper}>
          <CheckIcon className={classes.icon} />
        </div>
      </Box>
      <Typography variant="h3" align="center" gutterBottom>
        Vielen Dank!
      </Typography>
      <Typography variant="body1" align="center">
        Bitte rufen Sie Ihre E-Mails ab und bestätigen Sie Ihre Anmeldung.
      </Typography>
    </>
  );

  return <>{success ? successMessage : form}</>;
};

export default NewsletterForm;
