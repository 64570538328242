import {
  Box,
  Button,
  Chip,
  Container,
  // List,
  // ListItem,
  // ListItemIcon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// Internal imports
import "./SalesPageStyle.css";
import imageStepTwo from "../../../images/verkaufsseite-schritt-2.jpg";
import ImmobilienBewertungTeaser from "../../global/ImmobilienBewertungTeaser";
import Separator from "../../layout/Separator";
import ArchivedSlider from "../../offers/ArchivedSlider";
import TitleWithSeparator from "../../global/TitleWithSeparator";
import CustomContainer from "../../global/CustomContainer";
import Video from "../../video/Video";

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: 0,
    padding: theme.spacing(3, 1),
    background: "transparent",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6, 0),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "calc(70vh - 64px - 250px)",
      padding: theme.spacing(8),
    },
  },
  sliderArchived: {
    marginTop: 0,
  },
  slide: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  sliderTitle: {
    marginBottom: 0,
  },
  cta: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
  stepIcon: {
    background: theme.palette.primary.main,
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  mobileStep: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      zIndex: 999,
      display: "flex",
      color: theme.palette.background.paper,
      fontWeight: "bold",
    },
  },
  mobileStepLeft: {
    [theme.breakpoints.down("xs")]: {
      borderBottomRightRadius: "100px",
    },
  },
  mobileStepRight: {
    [theme.breakpoints.down("xs")]: {
      borderBottomLeftRadius: "100px",
    },
  },
  listItem: {
    paddingLeft: 0,
    "& span": {
      fontSize: theme.page.card.subHeader.fontSize,
    },
  },
  cardTitle: {
    textAlign: "left",
  },
  ytVideoText: {
    marginTop: 0,
    marginBottom: theme.spacing(4),
    textAlign: "left",
    marginLeft: theme.spacing(4),
    [theme.breakpoints.up("xl")]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    "&>p": {
      marginTop: 0,
    },
  },
}));

const SalesPage = () => {
  const classes = useStyles();

  const steps = [
    {
      img: null,
      title: "Kostenfreie, professionelle Bewertung Ihrer Immobilie",
      content:
        "Was ist meine Immobilie wert und wann lohnt sich der Verkauf? Fragen wie diese stehen oft am Beginn der Überlegungen, sich mit dem Vermarktung der eigenen Immobilie zu befassen. Eine erste, schnelle Orientierung bieten heute viele kostenlose Bewertungsportale, jedoch erfassen diese kaum die individuellen Besonderheiten Ihres Objekts. Um den optimalen Verkaufspreis zu ermitteln, beraten wir Sie gerne persönlich vor Ort oder per Video-Call, dann können Sie uns gleich zu Beginn mit Ihrer Immobilie vertraut machen. Wir gehen mit Ihnen zusammen eine Bewertungs-Checkliste durch, um sicher zu gehen, dass wir Ihrer Immobilie gerecht werden. Ihre Angaben nutzen wir, um Anhand von Vergleichsdaten und komplexeren Bewertungsverfahren den optimalen Verkaufspreis für Sie kostenlos zu ermitteln.",
    },
    {
      img: imageStepTwo,
      title: "Ihre Immobilie wird für den Verkauf vorbereitet",
      content:
        "Wenn wir Sie bis hierhin für unsere Arbeit gewinnen konnten und Sie uns Ihr Vertrauen geschenkt haben, planen wir gerne die weiteren Schritte mit Ihnen. Wichtig sind hierbei zum einen Fragen, die etwas mit Ihrer Zeitplanung zu tun haben: Soll die Immobilie sofort verkauft werden oder erst zu einem definierten, späteren Zeitpunkt? Gibt es zwingende Gründe, die einen schnellen Verkauf notwendig machen? Den Zeitpunkt wählen zu können, kann ein Vorteil bei der Vermarktung sowie bei den Preisverhandlungen mit Interessenten sein. Zweitens sichten wir gerne mit Ihnen zusammen die bereits vorhandenen Dokumente wie Grundrisse, den Energieausweis oder die Wohnflächenberechnung. Sie haben diese Dokumente nicht oder nur teilweise? Dann unterstützen wir Sie bei der Beschaffung: Wir sichten die Bauakte, beauftragen einen Energieberater mit der Erstellung des Energieausweises und dank unserer guten Kontakte zu Architekten können wir ebenfalls fehlende Wohnflächenberechnungen zeitnah erstellen lassen. Somit verlieren wir keine Zeit!",
    },
    {
      img: null,
      title:
        "Lehnen Sie sich zurück – wir starten für Sie mit der Vermarktung durch!",
      content:
        "Wir haben mit Ihnen die nötigen Informationen und Unterlagen besorgt, jetzt sind wir an der Reihe mit der Erstellung von Fotos, 360° Videos, Texten, Anzeigen und dem Exposé. Wir setzen modernste digitale Technik ein, um Ihre Immobilie optimal zu präsentieren. Ferner haben wir Mitarbeiter, die darauf spezialisiert sind, die Bilder und Videos so aufzubereiten, dass ein rundherum professioneller Eindruck bei der Präsentation entsteht. Unsere Webseite, auf der wir sämtliche Angebote präsentieren, ermöglicht es Interessenten, sich das ausführliche digitale Exposé selbständig freizuschalten, inklusive dem Besichtigungsvideo und ersten Unterlagen. Selbstverständlich sind wir auf allen großen Immobilienportalen vertreten. Von unseren Kunden haben wir sehr positive Rückmeldungen für das bereitgestellte Gesamtpaket erhalten. Oftmals ersetzt dieses die Erstbesichtigung und schont damit auch Ihre Nerven, falls Sie noch selbst in der Immobilie wohnen. Ernsthaften Interessenten zeigen wir im Anschluss Ihre Immobilie in jedem Fall persönlich.",
    },
    {
      img: null,
      title: "Vermitteln, Verhandeln und Kaufentscheidung herbeiführen",
      content:
        "Wie schnell wir Ihnen erste Kaufkandidaten präsentieren können, hängt zwar etwas von dem konkreten Objekt sowie der gewählten Vermarktungsform ab (auch die diskrete Vermarktung ohne Bilder bieten wir selbstverständlich an). Andererseits liegen uns meistens bereits nach wenigen Wochen und manchmal schon nach wenigen Tagen erste ernsthafte Gebote vor. Wir leiten alle Gebote grundsätzlich an Sie weiter und selbstverständlich entscheiden Sie am Ende, wem Sie Ihre Immobilie verkaufen. Dennoch machen wir regelmäßig die Erfahrung, dass Verkäufer sich von uns eine beratende Einschätzung wünschen bei der Wahl des Interessenten. Die Entscheidung hängt mitunter nicht nur von den Preisvorstellungen der Interessenten ab, sondern auch von der Frage, wie schnell der Verkauf abgewickelt werden kann. Gibt es schon eine Finanzierung? Wie sicher ist diese? Hier kommt unsere Erfahrung im Umgang mit Kaufinteressenten zum tragen. Selbst schwierige Verhandlungen gestalten wir erfolgreich für Sie.",
    },
    {
      img: null,
      title: "Der formale Verkaufsprozess: Mit uns behalten Sie die Übersicht!",
      content:
        "Ein Interessent wurde gefunden, der Ihre Immobilie zu einem guten Preis kaufen will. Für uns bedeutet diese positive Nachricht, dass wir den formalen Kaufprozess rechtssicher einleiten und zum Abschluss bringen. Hierbei gilt es zunächst das Notariat mit der Erstellung eines Kaufvertrags zu beauftragen, wofür parallel einige Fragen geklärt werden müssen: Wird Inventar mitverkauft? Wird eine Grundschuld bestellt, die anschließend im Grundbuch eingetragen werden soll? Wann soll der Kaufpreis fließen sowie der Besitzübergang stattfinden? Unserer Erfahrung folgend können wir gut abschätzen, wann welche Fragen zu klären sind und wieviel Aufwand dahinter steht – das ist etwas, womit gerade viele Erstverkäufer und -käufer mitunter überfordert sind. Mit uns behalten Sie in jedem Fall die Übersicht und wissen immer, welcher Schritt gerade ansteht.",
    },
    {
      img: null,
      title: "Die Übergabe: Wir machen dort weiter, wo andere schon aufhören",
      content:
        "Nach der Beurkundung des Kaufvertrags wird dieser weiter gleitet an das Grundbuchamt, die eine Auflassungsvormerkung für den Käufer eintragen. Das Grundbuch meldet den Vollzug an das Notariat und dieses wiederrum schickt die Fälligkeitsmitteilung an den Käufer und zur Kenntnisnahme an Sie. Viele Makler beenden hier bereits Ihre Tätigkeit, wir jedoch wissen genau, dass auch der anschließende Prozess der Kaufpreiszahlung und des Besitzübergangs vor allem zeitlich gut geplant werden sollten. Wenn Mietverhältnisse bestehen, kommt es mitunter sogar auf eine tagesgenau Planung an, damit der Besitzübergang reibungslos stattfinden kann. Wir möchten Ihnen hier die Garantie geben, an Ihrer Seite zu stehen, bis alle Fragen geklärt und die Schlüssel übergeben wurden.",
    },
  ];

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <CustomContainer>
      <TitleWithSeparator title={"Immobilienverkauf ist Vertrauenssache"} />

      <Box
        my={4}
        display="flex"
        flexDirection={{ xs: "column-reverse", md: "row" }}
      >
        <Box width={{ xs: "100%", md: "66%" }} pr={{ xs: 0, md: 1 }}>
          <Video yt="gIhXzbavw00" />
        </Box>
        <Box width={{ xs: "100%", md: "34%" }} pl={{ xs: 0, md: 0 }}>
          <Typography variant="body1" className={classes.ytVideoText}>
            Ihre Immobilie ist mehr als nur eine Wohnung oder ein Haus. Es ist
            vermutlich das größte, vorhandene Vermögen was Sie besitzen.
          </Typography>
        </Box>
      </Box>

      <Separator />

      <Typography
        variant="h3"
        align="center"
        component="div"
        className={classes.title}
      >
        Der Immobilienverkauf leicht verständlich: Eine Schritt-für-Schritt
        Anleitung
      </Typography>

      <Box
        display="flex"
        sx={{
          mt: 5,
        }}
      >
        <div className="wrapper">
          <div className="center-line">
            <Button onClick={() => handleScrollToTop()} className="scroll-icon">
              <ExpandLessIcon />
            </Button>
          </div>

          {steps?.map(({ title, content }, index) => {
            let isEven = index % 2 === 0;
            return (
              <div
                className={isEven ? "row row-1" : "row row-2"}
                key={title.split(" ").join("_")}
              >
                <Chip
                  color="primary"
                  label={index + 1}
                  className={clsx(
                    classes.mobileStep,
                    isEven ? classes.mobileStepLeft : classes.mobileStepRight
                  )}
                />

                <section>
                  <i className="icon">{index + 1}</i>
                  <div className="details">
                    <Typography variant="h4" className={classes.cardTitle}>
                      {title}
                    </Typography>
                  </div>
                  <Typography variant="body1">{content}</Typography>
                </section>
              </div>
            );
          })}
        </div>
      </Box>

      <Box textAlign="center" className="verkaufen-slider">
        <ImmobilienBewertungTeaser />
      </Box>

      <Box textAlign="center" className="verkaufen-slider">
        <div className={clsx(classes.slider, classes.sliderArchived)}>
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              align="center"
              className={classes.sliderTitle}
            >
              Unsere neuesten Referenzen
            </Typography>
            <Separator dense />
            <ArchivedSlider />
            <div className={classes.cta}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                component={Link}
                to="/immobilien/verkauft"
              >
                Alle Referenzen anzeigen
              </Button>
            </div>
          </Container>
        </div>
      </Box>
    </CustomContainer>
  );
};

export default SalesPage;
