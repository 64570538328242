import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Chip,
  makeStyles,
} from "@material-ui/core";
import * as constants from "../../constants";
import Currency from "react-currency-formatter";
import { Link } from "react-router-dom";
import Facts from "./Facts";
import noImage from "../../images/team2022.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  contentWrapper: {
    height: 150,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: 180,
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  // title: {
  //   fontSize: 16,
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: 18,
  //   },
  // },
  footer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  // footerText: {
  //   fontSize: 18,
  // },
  actions: {
    borderTop: "1px solid #eee",
  },
  contentOne: {
    flexGrow: 1,
  },
  chip: {
    position: "absolute",
    right: 10,
    top: 10,
    textTransform: "uppercase",
  },
}));

const OfferTeaser = ({ item, archived = false }) => {
  const classes = useStyles();

  if (!item || !item.freitexte) {
    return null;
  }

  const img =
    item.anhaenge && item.anhaenge.length && item.anhaenge.length > 1
      ? item.anhaenge[1].daten.pfad_preview
      : null;

  const kaltmiete = item.preise.hasOwnProperty("kaltmiete")
    ? parseFloat(item.preise.kaltmiete)
    : null;
  const kaufpreis = item.preise.hasOwnProperty("kaufpreis")
    ? parseFloat(item.preise.kaufpreis)
    : null;

  const preisLabel = kaltmiete ? "Kaltmiete" : "Kaufpreis";
  const preisValue = kaltmiete ? kaltmiete : kaufpreis;

  let chip = null;
  if (item.freitexte.dreizeiler) {
    chip = (
      <Chip
        color="secondary"
        className={classes.chip}
        label={item.freitexte.dreizeiler}
      />
    );
  }

  return (
    <Card className={classes.card}>
      <CardActionArea
        component={Link}
        to={
          "/immobilien/" +
          (archived ? "verkauft" : "angebot") +
          "/" +
          (item.id || "").replace("#", "---")
        }
      >
        <CardMedia
          className={classes.media}
          alt="Contemplative Reptile"
          image={img ? constants.API_URL + img : noImage}
          title={item.freitexte.objekttitel}
          style={!img ? { backgroundPosition: "center top" } : undefined}
        />
        {chip}
        <CardContent className={classes.contentWrapper}>
          <div className={classes.contentOne}>
            <Typography
              gutterBottom
              variant="body1"
            >
              {item.freitexte.objekttitel}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {item.plz} {item.ort}
            </Typography>
          </div>
          <div className={classes.contentTwo}>
            <Facts
              size="small"
              data={item}
            />
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <div className={classes.footer}>
            <Typography variant="body1" component="div">
              {preisLabel}
            </Typography>
            <Typography variant="body1" component="div">
              {preisValue > 0 ? (
                <Currency
                  pattern="##,### !"
                  decimal=","
                  group="."
                  quantity={preisValue}
                  currency="EUR"
                />
              ) : (
                "k.A."
              )}
            </Typography>
          </div>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default OfferTeaser;
