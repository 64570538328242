import React, { useEffect, useState } from "react";
import * as constants from "../../constants";
import Slider from "./Slider";

const OffersSlider = () => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    fetch(constants.API_URL + "objects")
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        const visibleEntries = !data.entries
          ? []
          : data.entries
              .filter(
                (item) =>
                  item?.freitexte?.dreizeiler?.toLowerCase() !== "freigabe"
              )
              .filter((f) => {
                const txt =
                  f.freitexte && f.freitexte.dreizeiler
                    ? f.freitexte.dreizeiler
                    : "";
                return txt.toUpperCase().indexOf("GEWERBE") === -1;
              })
              .sort((a, b) => {
                let pA = a.preise.hasOwnProperty("kaufpreis")
                  ? parseFloat(a.preise.kaufpreis)
                  : parseFloat(a.preise.kaltmiete);
                let pB = b.preise.hasOwnProperty("kaufpreis")
                  ? parseFloat(b.preise.kaufpreis)
                  : parseFloat(b.preise.kaltmiete);

                if (isNaN(pA)) {
                  pA = 0;
                }
                if (isNaN(pB)) {
                  pB = 0;
                }
                return pA > pB ? -1 : 1;
              })
              .slice(0, Math.min(9, data.entries.length));
        setItems(visibleEntries);
      });
  }, []);

  return <Slider items={items} />;
};

export default OffersSlider;
