import React, { useEffect, useState } from "react";
import COCKPIT_API from "../../utils/COCKPIT_API";
import { Container, Paper, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as constants from "../../constants";
import convert from "htmr";
import { Image, Text } from "../cockpit";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 0, 8, 0),
  },
  paper: {
    padding: theme.spacing(4),
  },
  title: {
    padding: theme.spacing(0, 20),
    marginTop: theme.spacing(8),
  },
  text: {
    fontSize: 20,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  teaser: {
    fontWeight: 600,
    fontSize: 16,
  },
}));

const Article = ({ match }) => {
  const classes = useStyles();
  const slug = match.params.slug;
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [teaser, setTeaser] = useState(null);
  const [content, setContent] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const body = {
          filter: { slug },
          limit: 1,
        };
        const pageData = await COCKPIT_API.post(
          "collections/get/articles?token=" + constants.COCKPIT_TOKEN,
          body
        );

        // Title
        const titleData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].title
            : null;
        setTitle(titleData);

        // Description
        const descriptionData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].subtitle
            : null;
        setSubtitle(descriptionData);

        // Teaser
        const teaserData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].teaser
            : null;
        setTeaser(teaserData);

        // Content
        const contentData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].content
            : null;
        setContent(contentData);

        // Image
        const imageData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].image
            : null;
        setImage(imageData);
      } catch (e) {
        console.log("Error: ", e);
      }
    }
    fetchData();
  }, [slug]);

  const renderedTeaser = (
    <Typography
      variant="body2"
      align="left"
      color="textPrimary"
      component="div"
      className={classes.teaser}
    >
      {teaser && convert(teaser)}
    </Typography>
  );

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" className={classes.title}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            align="center"
            color="textPrimary"
            component="div"
            className={classes.text}
          >
            {subtitle && convert(subtitle)}
          </Typography>
          <Paper className={classes.paper}>
            <Grid container spacing={4}>
              {Boolean(image) && Boolean(image.path) ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Image image={image} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {renderedTeaser}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  {renderedTeaser}
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <Text text={content} />
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
      <Helmet>
        <title>Marquart Immobilien - {Boolean(title) ? title : ""}</title>
        <meta
          name="description"
          content="Ihr Immobilienmakler in der Region Bad Kissingen, Schweinfurt und Würzburg. Kundenorientierung, Professionalität und Expertise stehen im Fokus unserer täglichen Arbeit. Gerne beraten wir Sie persönlich. Ihr Team von MARQUART IMMOBILIEN"
        />
      </Helmet>
    </>
  );
};

export default Article;
