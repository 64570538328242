import { createTheme } from "@material-ui/core/styles";

export const THEME = createTheme({
  palette: {
    primary: {
      main: "#cebb8a",
      dark: "#b89c56",
    },
    secondary: {
      main: "#ee0033",
    },
    background: {
      paper: "#fff",
      default: "#f5f5f5",
    },
    text: {
      main: "#67686c",
      title: "#67686c",
    },
  },
  page: {
    lineHeight: "1.5em",
    fontFamily: "Montserrat",
    header: {
      fontSize: 38,
      fontWeight: 400,
    },
    subheader: {
      fontSize: 22,
      fontWeight: 400,
    },
    content: {
      fontSize: 18,
    },
    card: {
      header: {
        fontSize: 20,
      },
      content: {
        fontSize: 18,
      },
      subHeader: {
        fontSize: 16,
      },
    },
  },
  card: {
    fontFamily: "Montserrat",
    lineHeight: "1.5em",
    content: {
      fontSize: 18,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          fontSize: "15px",
        },
      },
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h1: {
      color: "#67686c",
      fontSize: "4rem",
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "-0.01562em",
      "@media (max-width:960px)": {
        fontSize: "3rem",
      },
      "@media (max-width:600px)": {
        fontSize: "2.5rem",
      },
    },
    h2: {
      color: "#67686c",
      fontSize: "3rem",
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
      "@media (max-width:960px)": {
        fontSize: "2.5rem",
      },
      "@media (max-width:600px)": {
        fontSize: "2rem",
      },
    },
    h3: {
      color: "#67686c",
      fontSize: "2.25rem",
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "0em",
      "@media (max-width:960px)": {
        fontSize: "1.75rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
      },
    },
    h4: {
      color: "#67686c",
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "0.00735em",
      "@media (max-width:960px)": {
        fontSize: "1.25rem",
      },
      "@media (max-width:600px)": {
        fontSize: "1.125rem",
      },
    },
    h5: {
      color: "#67686c",
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "0em",
      "@media (max-width:960px)": {
        fontSize: "1.125rem",
      },
    },
    h6: {
      color: "#67686c",
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "0.0075em",
      "@media (max-width:960px)": {
        fontSize: "1rem",
      },
    },
    subtitle1: {
      color: "#67686c",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
      "@media (max-width:960px)": {
        fontSize: "0.875rem",
      },
      "@media (max-width:600px)": {
        fontSize: "0.75rem",
      },
    },
    subtitle2: {
      color: "#67686c",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: "0.00714em",
      "@media (max-width:960px)": {
        fontSize: "0.75rem",
      },
      "@media (max-width:600px)": {
        fontSize: "0.625rem",
      },
    },
    body1: {
      color: "#67686c",
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      color: "#67686c",
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
    },
    button: {
      color: "#fff",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
      "@media (max-width:960px)": {
        fontSize: "0.875rem",
      },
      // "@media (max-width:600px)": {
      //   fontSize: "0.75rem",
      // },
    },
    caption: {
      color: "#67686c",
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
    },
    overline: {
      color: "#67686c",
      fontSize: "0.625rem",
      fontWeight: 500,
      lineHeight: 2.66,
      letterSpacing: "0.16667em",
      textTransform: "uppercase",
    },
  },
});
