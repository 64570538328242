import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

// MUI components
import { Box, Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';

// Internal components
import Separator from "../layout/Separator";
import decluttingAfterImage from '../../images/raeumung-nachher.jpeg'
import decluttingBeforeImage from '../../images/raeumung-vorher.jpeg'
import HoverImageCard from "./CustomHoverImageCard";
import CustomListItem from "./CustomListItem";

const DeclutteringTeaser = ({ isDarkMode, isHome }) => {
  const classes = useStyles({ isDarkMode, isHome });
  const history = useHistory();

  const navigateToDetails = () =>
    history.push("/entrümpelung-und-räumung");

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h3" align="center" className={classes.title}>
        Entrümpelungs- und Räumungsservice
      </Typography>
      <Separator dense />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.teaserBox}
      >
        <Box
          className={classes.outerBoxImg}
        >
          <HoverImageCard 
              beforeImage={decluttingBeforeImage}
              afterImage={decluttingAfterImage}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width={450}
        >
             <Box>
                <CustomListItem
                  align="left"
                  variant="h5"
                  text="Aufbereitung von Gärten"
                  isDarkMode
                >
                  <CheckIcon fontSize="small"/>
                </CustomListItem>
                <CustomListItem
                  align="left"
                  variant="h5"
                  text="fachgerechte Entrümpelung"
                  isDarkMode
                >
                  <CheckIcon fontSize="small"/>
                </CustomListItem>
                <CustomListItem
                  align="left"
                  variant="h5"
                  text="Wohnungsauflösung"
                  isDarkMode
                >
                  <CheckIcon fontSize="small"/>
                </CustomListItem>
                <CustomListItem
                  align="left"
                  variant="h5"
                  text="Räumung"
                  isDarkMode
                >
                  <CheckIcon fontSize="small"/>
                </CustomListItem>
                <CustomListItem
                  align="left"
                  variant="h5"
                  text="Endreinigung"
                  isDarkMode
                >
                  <CheckIcon fontSize="small"/>
                </CustomListItem>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={navigateToDetails}
                  size="small"
                  className={classes.navigateBtn}
                >
                  Mehr erfahren
                </Button>
              </Box>
        </Box>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: ({ isHome }) => (isHome ? theme.spacing(2) : theme.spacing(7)),
  },
  title: {
    wordBreak: "break-word",
    marginBottom: 0,
    color: ({ isDarkMode }) =>
      isDarkMode ? theme.palette.background.paper : theme.palette.text.title,
  },
  teaserBox: {
    gap: theme.spacing(2),
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  outerBoxImg: {
    cursor: "pointer",
    width: 400,
    height: 300,
  },
  navigateBtn: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
}));

export default DeclutteringTeaser;
