import React, { useEffect, useState, useMemo } from "react";
import PageComponents from "../page/PageComponents";
import { Paper, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import convert from "htmr";
import { Helmet } from "react-helmet";
import NewsletterForm from "../forms/NewsletterForm";
import { useSelector } from "react-redux";
import VideosOverview from "../video/VideosOverview";
import CustomContainer from "../global/CustomContainer";
import TitleWithSeparator from "../global/TitleWithSeparator";
import CustomSubtitle from "../global/CustomSubtitle";
import { fetchCollectionBySlug } from "../../api/cockpitCmsApi";
import ContactForm from "../forms/ContactForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
}));

const Page = ({ match }) => {
  const classes = useStyles();

  const collectionName = useMemo(
    () => (match.path.includes("fachwissen") ? "blogs" : "pages"),
    [match.path]
  );
  const slug = match.params.slug;
  const [components, setComponents] = useState([]);
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const websiteSettings = useSelector((state) => state.settings);

  useEffect(() => {
    async function fetchData() {
      try {
        const pageData = await fetchCollectionBySlug(slug, collectionName);

        // Title
        const titleData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].title
            : null;
        setTitle(titleData);

        // Description
        const descriptionData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].subtitle
            : null;
        setSubtitle(descriptionData);

        // Components
        const componentsData =
          pageData.data && pageData.data.entries && pageData.data.entries[0]
            ? pageData.data.entries[0].content
            : [];
        setComponents(componentsData);
      } catch (e) {}
    }
    fetchData();
    // eslint-disable-next-line
  }, [slug]);

  return (
    <>
      <CustomContainer>
        <TitleWithSeparator title={title} />

        <CustomSubtitle text={subtitle && convert(subtitle)} />
        {components && Array.isArray(components) && components?.length ? (
          <Paper className={classes.paper}>
            <PageComponents components={components || []} />
            {slug === "anhaenger" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: 'center', md: 'flex-end'},
                }}
                
              >
                <ContactForm
                  buttonText="Anhänger kostenlos mieten"
                  buttonVariant="outlined"
                  isMobile={false}
                  leavePageOnClose={false}
                  showButtonIcon={false}
                />
              </Box>
            )}
            {slug === "newsletter" && (
              <NewsletterForm websiteSettings={websiteSettings} />
            )}
          </Paper>
        ) : null}
    
      </CustomContainer>
      {slug === "youtube" && (
        <Box mt={4}>
          <VideosOverview />
        </Box>
      )}

      <Helmet>
        <title>Marquart Immobilien - {Boolean(title) ? title : ""}</title>
        <meta
          name="description"
          content="Ihr Immobilienmakler in der Region Bad Kissingen, Schweinfurt und Würzburg. Kundenorientierung, Professionalität und Expertise stehen im Fokus unserer täglichen Arbeit. Gerne beraten wir Sie persönlich. Ihr Team von MARQUART IMMOBILIEN"
        />
      </Helmet>
    </>
  );
};

export default Page;
