import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import API from "../../utils/API";
import convert from "htmr";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 0, 8, 0),
  },
  paper: {
    padding: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  checkbox: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    background: "#f5f1e8",
  },
  cta: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iframe: {
    width: "100vw",
    height: "100vh",
  },
  overflow: {
    padding: theme.spacing(0, 1),
    maxHeight: 180,
    overflow: "auto",
    border: "1px solid #aaa",
    borderRadius: 4,
  },
}));

const Expose = ({ match }) => {
  const token = match.params.token;
  const classes = useStyles();
  const [acceptanceMissing, setAcceptanceMissing] = useState(false);
  const [agb, setAgb] = useState(false);
  const [widerruf, setWiderruf] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [success, setSuccess] = useState(false);

  const websiteSettings = useSelector((state) => state.settings);

  useEffect(() => {
    async function fetchData() {
      try {
        const body = {
          token,
        };
        const res = await API.post("expose", body);
        if (res.data && res.data.result === "missing_acceptance") {
          setAcceptanceMissing(true);
        }
        if (res.data && res.data.result === "Invalid Token") {
          setInvalidToken(true);
        }
        if (res.data && res.data.result === "success") {
          setSuccess(true);
        }
      } catch (e) {
        console.log("Error: ", e);
      }
    }
    fetchData();
  }, [token]);

  if (success) {
    return <Redirect push to={"/expose/" + token} />;
  }

  if (invalidToken) {
    return (
      <>
        <br />
        <br />
        <Typography variant="h5" align="center">
          Ungültiger Link
        </Typography>
        <Typography variant="body1" align="center">
          Bitte überprüfen Sie, ob Sie den Link vollständig kopiert und
          eingefügt haben
        </Typography>
      </>
    );
  }

  const handleChangeAgb = (e) => {
    setAgb(!agb);
  };

  const handleChangeWiderruf = (e) => {
    setWiderruf(!widerruf);
  };

  const handleClick = async (e) => {
    try {
      const body = {
        token,
        agb,
        widerruf,
      };
      const res = await API.post("expose", body);
      if (res.data && res.data.result === "missing_acceptance") {
        setAcceptanceMissing(true);
      }
      if (res.data && res.data.result === "success") {
        setSuccess(true);
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const valid = agb && widerruf;

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="md">
          {acceptanceMissing && (
            <Paper className={classes.paper}>
              <Typography variant="h3" gutterBottom>
                {websiteSettings.expose_headline_agb}
              </Typography>
              <div className={classes.overflow}>
                <Typography variant="body1">
                  {websiteSettings.expose_agb &&
                    convert(websiteSettings.expose_agb)}
                </Typography>
              </div>
              <div className={classes.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agb}
                      onChange={handleChangeAgb}
                      color="primary"
                    />
                  }
                  label={websiteSettings.expose_agb_checkbox}
                />
              </div>
              <Divider className={classes.divider} />
              <Typography variant="h3" gutterBottom>
                {websiteSettings.expose_headline_cancellation}
              </Typography>
              <div className={classes.overflow}>
                <Typography variant="body1">
                  {websiteSettings.expose_cancellation &&
                    convert(websiteSettings.expose_cancellation)}
                </Typography>
              </div>
              <div className={classes.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={widerruf}
                      onChange={handleChangeWiderruf}
                      color="primary"
                    />
                  }
                  label={websiteSettings.expose_cancellation_checkbox}
                />
              </div>
              <div className={classes.cta}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={!valid}
                  onClick={handleClick}
                >
                  Ausführliches Exposé jetzt anzeigen
                </Button>
              </div>
            </Paper>
          )}
        </Container>
      </div>
    </>
  );
};

export default Expose;
