import React from "react";
import grey from "@material-ui/core/colors/grey";
import { Typography, makeStyles } from "@material-ui/core";
import IconPreis from "./../../images/objekt_icons_preis.png";
import IconFlaeche from "./../../images/objekt_icons_flaeche.png";
import IconGrundstueck from "./../../images/objekt_icons_grundstueck.png";
import IconGarage from '@material-ui/icons/DirectionsCar';
import IconBaujahr from '@material-ui/icons/CalendarTodayOutlined';
import IconZimmer from "./../../images/objekt_icons_zimmer.png";
import Currency from "react-currency-formatter";
import NumberFormat from "react-number-format";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 0.5, 1, 0.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
  rootSmall: {
    padding: theme.spacing(1, 2, 0, 0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1, 2, 0, 0),
    },
  },
  label: {
    fontSize: 10,
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
  },
  value: {
    fontSize: 13,
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 30,
    },
  },
  valueSmall: {
    fontSize: 13,
    color: grey[700],
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 15,
    },
  },
  icon: {
    width: 35,
    height: 35,
    opacity: 0.5,
    display: "block",
    [theme.breakpoints.up("sm")]: {
      width: 45,
      height: 45,
    },
    [theme.breakpoints.up("md")]: {
      width: 70,
      height: 70,
    },
  },
  iconSmall: {
    width: 30,
    height: 30,
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  iconWrapperSmall: {
    marginBottom: 0,
    marginRight: theme.spacing(1),
  },
}));

const getIcon = (icon) => {
  const iconMapping = {
    preis: IconPreis,
    zimmer: IconZimmer,
    flaeche: IconFlaeche,
    grundstueck: IconGrundstueck,
    garage: <IconGarage />,
    baujahr: <IconBaujahr />,
  };

  return iconMapping[icon] || IconZimmer;
};

const Fact = ({ value, label, type, size, unit }) => {
  const classes = useStyles();
  const icon = getIcon(type);

  let displayValue = value;
  if (type === "preis") {
    displayValue =
      value > 0 ? (
        <Currency
          pattern="##,### !"
          decimal=","
          group="."
          quantity={parseFloat(value)}
          currency="EUR"
        />
      ) : (
        "k.A."
      );
  } else {
    if(type === "baujahr") {
      displayValue = value;
    } else {
      displayValue = (
        <NumberFormat
        value={parseFloat(value)}
        displayType={"text"}
        decimalSeparator=","
        thousandSeparator="."
        />
      );
    }
  }

  return (
    <div className={clsx(classes.root, size === "small" && classes.rootSmall)}>
      <div
        className={clsx(
          classes.iconWrapper,
          size === "small" && classes.iconWrapperSmall
        )}
      >
        {typeof icon === "string" ? (
          <img
            src={icon}
            alt=""
            className={clsx(classes.icon, size === "small" && classes.iconSmall)}
          />
        ) : (
          <icon.type
            className={clsx(classes.icon, size === "small" && classes.iconSmall)}
          />
        )}
      </div>
      <Typography
        align="center"
        component="p"
        className={clsx(classes.value, size === "small" && classes.valueSmall)}
      >
        {displayValue}
        {!!unit && " " + unit}
      </Typography>
      {size !== "small" && (
        <Typography variant="body2" align="center" className={classes.label}>
          {label}
        </Typography>
      )}
    </div>
  );
};

export default Fact;
