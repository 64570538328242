import settingsReducer from "./settingsReducer";
import externalContentReducer from "./externalContentReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  settings: settingsReducer,
  externalContent: externalContentReducer,
});

export default rootReducer;
