import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { useSelector, useDispatch } from "react-redux";
import * as externalContentActions from "../../store/actions/externalContentActions";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  hint: {
    border: `10px solid ${grey[100]}`,
    overflow: "auto",
    background: "#fff",
  },
  videoWrapper: {
    background: "#fff",
    boxSizing: "border-box",
    width: "100%",
    height: 0,
    paddingBottom: "56.25%",
    position: "relative",
  },
  yt: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
}));

const Video = ({ yt = null, title = "Video" }) => {
  const classes = useStyles();
  const externalContent = useSelector((state) => state.externalContent);
  const dispatch = useDispatch();

  const handleChange = () => {
    if (!externalContent || !externalContent.enabled) {
      dispatch(externalContentActions.enable());
    } else {
      dispatch(externalContentActions.disable());
    }
  };

  if (!yt || yt === "-" || yt === "ZXqPkXn2sSI" || yt === "zpkKIfdhU3U") {
    return null;
  }

  const ytUrl =
    yt.indexOf("youtu") !== -1 ? yt : "https://www.youtube.com/embed/" + yt;

  const privacyHint = (
    <Box p={2} height="100%" className={classes.hint}>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2">
        An dieser Stelle finden Sie einen externen Inhalt von{" "}
        <strong>YouTube</strong>. Sie können sich hier alle externen Inhalte mit
        einem Klick anzeigen lassen oder wieder ausblenden
      </Typography>
      <Box py={4}>
        <FormControlLabel
          control={
            <Switch
              checked={externalContent && externalContent.enabled}
              onChange={handleChange}
              name="privacy"
            />
          }
          label={<strong>YouTube Videos anzeigen</strong>}
        />
      </Box>
      <Typography variant="body2" color="textSecondary">
        Ich bin damit einverstanden, dass mir externe Inhalte angezeigt werden.
        Damit können personenbezogene Daten an Drittplattformen übermittelt
        werden. Weitere Informationen in unserer{" "}
        <a
          href="/datenschutzerklaerung"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          Datenschutzerklärung
        </a>
      </Typography>
    </Box>
  );

  const video = (
    <>
      <div className={classes.videoWrapper}>
        <iframe
          className={classes.yt}
          frameBorder="0"
          allowFullScreen="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          src={ytUrl + "?rel=0"}
          title="YouTube video player"
        ></iframe>
      </div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={2}
        bgcolor="rgba(0,0,0,0.4)"
        color="#fff"
      >
        <FormControlLabel
          control={
            <Switch
              checked={externalContent && externalContent.enabled}
              onChange={handleChange}
              name="privacy"
            />
          }
          label="Externe Inhalte"
        />
        <a
          href="/datenschutzerklaerung"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fff" }}
          className={classes.link}
        >
          Datenschutzerklärung
        </a>
      </Box>
    </>
  );

  return (
    <>{externalContent && externalContent.enabled ? video : privacyHint}</>
  );
};

export default Video;
