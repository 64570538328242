import React from "react";

/** external imports */
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  active: {
    background: "#f5f1e8",
    color: theme.palette.primary.dark,
    "&:hover": {
      background: "#f5f1e8",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const CustomListItemWithText = ({
  navigateTo,
  uniqueKey,
  component,
  primary,
  onClickItem,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      component={component}
      to={navigateTo}
      className={classes.nested}
      activeClassName={classes.active}
      key={uniqueKey}
      onClick={onClickItem}
    >
      <ListItemText primary={primary} />
    </ListItem>
  );
};

export default CustomListItemWithText;
