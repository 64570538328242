import React from "react";
import { Helmet } from "react-helmet";
import CustomContainer from "../global/CustomContainer";
import TitleWithSeparator from "../global/TitleWithSeparator";
import { Paper, Typography, makeStyles } from "@material-ui/core";

const Widerrufsrecht = () => {
  const classes = useStyles();
  return (
    <>
      <CustomContainer>
        <TitleWithSeparator title={"Widerrufsrecht"} />

        <Paper className={classes.paper}>
          <Typography variant="body1">
            Widerrufsrecht Sie haben das Recht, binnen vierzehn Tagen ohne
            Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist
            beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses. Um Ihr
            Widerrufsrecht auszuüben, müssen Sie uns (Marquart Immobilien,
            Unterer Steinberg 10, 97688. Tel: 0971-123 82 98 0) über Ihren
            Entschluss informieren, diesen Vertrag zu widerrufen. Zur Wahrung
            der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die
            Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            Folgen des Widerrufs Wenn Sie diesen Vertrag widerrufen, haben wir
            Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
            einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen
            Kosten, die sich daraus ergeben, dass Sie eine andere Art der
            Lieferung als die von uns angebotene, günstigste Standardlieferung
            gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab
            dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf
            dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
            verwenden wir dasselbe Zahlungsmittel, das Sie bei der
            ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
            wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden
            Ihnen wegen dieser Rückzahlung Entgelte berechnet. Haben Sie
            verlangt, dass die Dienstleistungen während der Widerrufsfrist
            beginnen sollen, so haben Sie uns einen angemessenen Betrag zu
            zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von
            der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags
            unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum
            Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen
            entspricht.
          </Typography>
        </Paper>
      </CustomContainer>

      <Helmet>
        <title>Marquart Immobilien - Wiederrufsrecht</title>
        <meta name="description" content="Wiederrufsrecht" />
      </Helmet>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
}));

export default Widerrufsrecht;
