import React from "react";
import Video from "../../video/Video";

export default ({ videoId }) => {
  return (
    <>
      <Video yt={videoId} />
    </>
  );
};
