import { useEffect, useState } from "react";
import { fetchCollectionByName } from "../api/cockpitCmsApi";

const useCollectionFetch = (collectionName) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collection = await fetchCollectionByName(collectionName);
        const entries = collection.data.entries || [];

        const processedData = entries.map(
          ({
            active,
            blogId,
            cardTitle,
            cardDescription,
            publicationDate,
            slug,
            title,
          }) => ({
            active,
            slug,
            title,
            cardTitle,
            cardDescription,
            createdAt: publicationDate,
            id: blogId,
          })
        );

        setData(processedData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [collectionName]);

  return { data, loading, error };
};

export default useCollectionFetch;
