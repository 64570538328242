import React from "react";
import * as constants from "../../../constants";

function parseToJson(string) {
  try {
    return JSON.parse(string);
  } catch (error) {
    return null;
  }
}

export default ({ image, alt, width, height, style }) => {
  let path = image.path;
  path = `${constants.COCKPIT_URL}${path}`;

  return (
    <img
      src={path}
      alt={alt}
      width={width || "100%"}
      height={height || "100%"}
      style={parseToJson(style)}
    />
  );
};
