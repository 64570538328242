import React from "react";

/** external imports */
import { Box, makeStyles, OutlinedInput } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  firstBox: {
    flex: 1,
    marginLeft: theme.spacing(19),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginLeft: "unset",
    },
  },
  lastBox: {
    flex: 1,
    marginLeft: theme.spacing(19),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      marginLeft: "unset",
    },
  },
  inputField: { flex: 1, borderRadius: 0 },
  outerBox: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(10),
    },
  },
}));

const PropertyLocationPage = ({
  postalCode,
  city,
  street,
  handleChange,
  handleNext,
}) => {
  const classes = useStyles();

  const onKeyPress = ({ key }) => {
    if (key !== "Enter") return;
    if (postalCode && city && street) {
      handleNext();
    }
  };

  return (
    <Box className={classes.outerBox}>
      <Box display="flex" className={classes.firstBox}>
        <Box display="flex" flex={1} pr={"12px"}>
          <OutlinedInput
            id="postalCode-id"
            value={postalCode || ""}
            onChange={(e) => {
              if (e.target.value < 0) return;
              return handleChange({ e, item: "postalCode" });
            }}
            aria-describedby="outlined-postalCode-helper-text"
            inputProps={{
              "aria-label": "postalCode",
            }}
            type="number"
            labelWidth={0}
            placeholder="PLZ"
            className={classes.inputField}
            onKeyPress={onKeyPress}
          />
        </Box>

        <Box display="flex" flex={3} pl={"12px"}>
          <OutlinedInput
            id="city-id"
            value={city || ""}
            onChange={(e) => handleChange({ e, item: "city" })}
            aria-describedby="outlined-city-helper-text"
            inputProps={{
              "aria-label": "city",
            }}
            labelWidth={0}
            placeholder="Stadt"
            className={classes.inputField}
            onKeyPress={onKeyPress}
          />
        </Box>
      </Box>

      <Box display="flex" flex={1} className={classes.lastBox}>
        <OutlinedInput
          id="street-id"
          value={street || ""}
          onChange={(e) => handleChange({ e, item: "street" })}
          aria-describedby="outlined-street-helper-text"
          inputProps={{
            "aria-label": "street",
          }}
          labelWidth={0}
          placeholder="Strasse"
          className={classes.inputField}
          onKeyPress={onKeyPress}
        />
      </Box>
    </Box>
  );
};

export default PropertyLocationPage;
