import API from "../../utils/COCKPIT_API";
import * as constants from "../../constants";

export const setSettings = () => async (dispatch, getState) => {
  // make async call to database
  let { settings } = getState();
  let data = {
    title: "",
    description: "",
    contact_privacy_info: "",
    contact_privacy_checkbox: "",
    contact_newsletter_checkbox: "",
    expose_headline_agb: "",
    expose_agb: "",
    expose_agb_checkbox: "",
    expose_headline_cancellation: "",
    expose_cancellation: "",
    expose_cancellation_checkbox: "",
    archived_title: "",
    archived_description: "",
    objects_title: "",
    objects_description: "",
    show_expose_title: "",
    show_expose_description: "",
    show_expose_button: "",
  };
  try {
    const settingsData = await API.get(
      "singletons/get/website_settings?token=" + constants.COCKPIT_TOKEN
    );
    if (settingsData.data) {
      data = {
        ...settingsData.data,
        ...(settings?.alreadyShownHolidayGreeting
          ? { alreadyShownHolidayGreeting: true }
          : { alreadyShownHolidayGreeting: false }),
      };
    }
  } catch (e) {
    console.log("FEHLER: ", e);
  }
  dispatch({ type: "SET_SETTINGS", data });
};

export const setHolidayGreeting = () => async (dispatch, getState) => {
  let data = {
    ...getState().settings,
    alreadyShownHolidayGreeting: true,
  };

  dispatch({ type: "SET_SETTINGS", data });
};
