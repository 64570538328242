import React, { useEffect, useState } from "react";
import * as constants from "../../constants";
import Slider from "./Slider";

const ArchivedSlider = () => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    fetch(constants.API_URL + "archived-objects")
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        const visibleEntries = !data.entries
          ? []
          : data.entries
              .sort((a, b) => {
                let pA = a.id;
                let pB = b.id;
                let dA = a.datum;
                let dB = b.datum;

                if (dA > dB) {
                  return -1;
                }

                if (dB > dA) {
                  return 1;
                }

                if (isNaN(pA)) {
                  pA = 0;
                }
                if (isNaN(pB)) {
                  pB = 0;
                }

                if (String(pA)[0] * 1 <= 3) {
                  pA *= 1000;
                }

                if (String(pB)[0] * 1 <= 3) {
                  pB *= 1000;
                }

                return pA > pB ? -1 : 1;
              })
              .slice(0, Math.min(9, data.entries.length));
        setItems(visibleEntries);
      });
  }, []);

  return <Slider items={items} archived={true} />;
};

export default ArchivedSlider;
