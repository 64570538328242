import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fact from "./Fact";
import clsx from "clsx";
import getAreaValues from "./utils/getAreaValues";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rootSmall: {
    justifyContent: "flex-start",
  },
}));

const Facts = ({ size, data }) => {
  const classes = useStyles();

  const flaechen = getAreaValues(data);

  if (!flaechen || !data.preise) {
    return null;
  }

  const zimmer = flaechen.anzahl_zimmer;
  const wohnflaeche = flaechen.wohnflaeche;
  const gesamtflaeche = flaechen.gesamtflaeche;
  const grundstueck = flaechen.grundstuecksflaeche || null;
  const preis = data.preise.kaufpreis ? data.preise.kaufpreis : data.preise.kaltmiete;
  const preisLabel = data.preise.kaufpreis ? "Kaufpreis" : "Kaltmiete";
  const anzahlStellplaetze = data?.eckdaten?.stellplaetze;
  const gebaeudeart = data?.energie.gebaeudeart;
  const baujahr = data?.eckdaten?.baujahr;

  return (
    <div className={clsx(classes.root, size === "small" && classes.rootSmall)}>
      {zimmer && (
        <Fact value={zimmer} label="Zimmer" type="zimmer" size={size} />
      )}
      {wohnflaeche && (
        <Fact
          value={wohnflaeche}
          label="Wohnfläche ca."
          type="flaeche"
          size={size}
          unit="qm"
        />
      )}
      {gesamtflaeche && (
        <Fact
          value={gesamtflaeche}
          label="Gesamtfläche ca."
          type="flaeche"
          size={size}
          unit="qm"
        />
      )}
      {grundstueck && (
        <Fact
          value={grundstueck}
          label="Grundstück ca."
          type="grundstueck"
          size={size}
          unit="qm"
        />
      )}
      {gebaeudeart !== 'wohn' ? (
        <>
         {anzahlStellplaetze && anzahlStellplaetze > 0 ? (
            <Fact
              value={anzahlStellplaetze}
              label="Stellplätze"
              type="garage"
              size={size}
            />
         ): null}

        {baujahr && baujahr !== '-' ? (
        <Fact
          value={baujahr}
          label="Baujahr"
          type="baujahr"
          size={size}
        />): null}
        </>
      ): null}
      {size !== "small" && (
        <Fact type="preis" value={preis} label={preisLabel} size={size} />
      )}
    </div>
  );
};

export default Facts;
