import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import React from "react";

// Internal imports
import Separator from "../layout/Separator";
import TitleWithSeparator from "../global/TitleWithSeparator";
import CustomContainer from "../global/CustomContainer";
import CustomSubtitle from "../global/CustomSubtitle";
import Video from "../video/Video";
import ContactForm from "../forms/ContactForm";
import CustomListItem from "../global/CustomListItem";

// Icons
import Img1 from "../../images/videoerstellung/group_9190868.svg";
import Img2 from "../../images/videoerstellung/microphone_788395.svg";
import Img3 from "../../images/videoerstellung/video_863761.svg";

const useStyles = makeStyles((theme) => ({
  instructions: {
    display: "flex",
    gap: theme.spacing(6),
    margin: `${theme.spacing(8)}`,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  instructionsBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 300,
    marginTop: theme.spacing(2),
    "& img": {
      marginBottom: theme.spacing(2),
    },
    "& p": {
      marginTop: theme.spacing(2),
    },
  },
  cardTitle: {
    height: theme.spacing(8),
  },
  sectionTwoContainer: {
    background: theme.palette.text.main,
  },
  sectionTwo: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(5),
    "& p": {
      color: "#ffffff",
    },
  },
  flexGap: {
    gap: theme.spacing(2),
  },
}));

const VideoCreation = () => {
  const classes = useStyles();

  return (
    <>
      <CustomContainer>
        <TitleWithSeparator title={"Moderierte 360-Grad-Videoerstellung"} />

        <CustomSubtitle text="Für Ferienimmobilien und Hotels." />
        <CustomSubtitle text="Eine einzigartige Immobilie verdient eine einzigartige Präsentation." />

        <Box>
          {/* Section 1 */}
          <Box display="flex" alignItems="center" flexDirection="column">
            {/* Youtube-Video */}
            <Box width={{ xs: "100%", md: "66%" }} pr={{ xs: 0, md: 1 }} mb={6}>
              <Video yt="lr8XYTg9k3Q" />
            </Box>

            {/* Cards with an icon */}
            <Box className={classes.instructions}>
              <Box className={classes.instructionsBox}>
                <img src={Img2} width="60" height="60" alt="Img1" />

                <Typography
                  variant="h5"
                  align="center"
                  className={classes.cardTitle}
                >
                  Moderierte Besichtigung
                </Typography>
                <Separator noHeight />

                <Typography
                  variant="body1"
                  align="center"
                  className={classes.cardTitle}
                >
                  Durch unsere Moderation <b>betonen</b> wir{" "}
                  <b>wichtige Merkmale</b> und führen Ihre zukünftigen Gäste
                  gezielt durch die visuellen Aufnahmen.
                </Typography>
              </Box>

              <Box className={classes.instructionsBox}>
                <img src={Img3} width="60" height="60" alt="Img2" />
                <Typography
                  variant="h5"
                  align="center"
                  className={classes.cardTitle}
                >
                  360-Grad-Video
                </Typography>
                <Separator noHeight />

                <Typography variant="body1" align="center">
                  Bietet den Zuschauenden die Möglichkeit, sich{" "}
                  <b>interaktiv</b> im Video umzusehen.
                </Typography>
              </Box>

              <Box className={classes.instructionsBox}>
                <img src={Img1} width="60" height="60" alt="Img3" />
                <Typography
                  variant="h5"
                  align="center"
                  className={classes.cardTitle}
                >
                  Zielgruppenorientiert
                </Typography>

                <Separator noHeight />

                <Typography variant="body1" align="center">
                  Wir vermitteln <b>Informationen und Aktivitäten</b>, die für
                  Ihre Zielgruppe <b>relevant</b> sind.
                </Typography>
              </Box>
            </Box>

            <Box mt={6}>
              <ContactForm
                buttonText="Jetzt ein unverbindliches Erstgespräch vereinbaren"
                buttonVariant="contained"
                buttonColor="primary"
                isMobile={false}
                leavePageOnClose={false}
                showButtonIcon={false}
              />
            </Box>
          </Box>
        </Box>
      </CustomContainer>

      <Box className={classes.sectionTwoContainer} pb={4}>
        <Container maxWidth="lg">
          {/* Section 2 */}
          <Box className={classes.sectionTwo}>
            {/* Subtitle */}
            <CustomSubtitle
              text="Warum ein Vorstellungsvideo?"
              textColor="#ffffff"
              textAlign="left"
              withSeparator
              mt={2}
              mb={6}
            />

            <Typography variant="body1">
              Texte und Vorschaubilder sind wichtig, reichen jedoch nicht aus,
              um die relevanten Informationen und Besonderheiten einer
              Ferienimmobilie auf einen Blick greifbar darzustellen. Durch
              unsere moderierten 360-Grad-Videos können wir den gesamten
              Informationsgehalt vermitteln und Ihren zukünftigen Gästen einen
              einmaligen Ersteindruck bieten. Neben den Vorzügen Ihrer Immobilie
              kommunizieren wir ebenfalls die für Ihre Zielgruppe relevanten
              Aktivitäten in der Umgebung, um die Lage des Objektes
              hervorzuheben.
            </Typography>
            <br></br>
            <Typography variant="body1">
              Durch diesen exklusiven Service, über den Sie mit unserem Video
              verfügen, heben Sie sich von der Konkurrenz ab. Gerade bei
              anspruchsvollen Kunden beseitigen Sie direkt zu Beginn aufkommende
              Fragen und Unsicherheiten. Mehr Sicherheit führt wiederum zu mehr
              Buchungen.
            </Typography>

            {/* Subtitle */}
            <CustomSubtitle
              text="Warum wir?"
              textColor="#ffffff"
              textAlign="right"
              withSeparator
              mt={6}
              mb={6}
            />

            <Typography variant="body1">
              Wir sind erfahrene Immobilienmakler, die sich auf den Verkauf von
              Wohnimmobilien spezialisiert haben. Mithilfe unserer moderierten
              360-Grad-Videos konnten wir unsere Verkaufsobjekte noch
              erfolgreicher inszenieren und vermarkten. Dies führte über die
              letzten Jahre zu einer Steigerung unseres Umsatzes. Unser Konzept
              bieten wir nun Ihnen als Eigentümer einer Ferienimmobilie an.
              Durch unsere zentrale Lage (Bad Kissingen, Nordbayern) agieren wir
              deutschlandweit.
            </Typography>

            {/* Subtitle */}
            <CustomSubtitle
              text="Wie geht es jetzt weiter?"
              textColor="#ffffff"
              textAlign="left"
              withSeparator
              mt={6}
              mb={6}
            />

            <Box
              display="flex"
              flexDirection="column"
              className={classes.flexGap}
            >
              <CustomListItem
                align="left"
                text="1. Sie kontaktieren uns für ein unverbindliches Erstgespräch."
              />
              <CustomListItem
                align="left"
                text="2. Wir klären die Details der Zusammenarbeit. Sie erläutern uns dabei Ihre Vorstellungen."
              />
              <CustomListItem
                align="left"
                text="3. Wir fahren zu Ihrer Location und beginnen mit den Dreharbeiten. Wichtig: Wir gehen dabei jederzeit auf Ihre individuellen Wünsche ein."
              />
              <CustomListItem
                align="left"
                text="4. Sie verfügen frei über das fertige Video."
              />
            </Box>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg">
        {/* Section 3 */}
        <Box mt={6}>
          {/* Subtitle */}
          <CustomSubtitle text="Weitere Vorteile" />
          <Box mb={4}>
            <Separator noHeight />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            className={classes.flexGap}
          >
            <CustomListItem
              align="left"
              text={[
                "- Ein ",
                <b>360-Grad-Video</b>,
                " erzeugt deutlich ",
                <b>mehr Vertrauen</b>,
                " als herkömmliche Videos, Texte und Bilder. Der Gast selbst besitzt das Gefühl der Kontrolle, da er sich in Ihrer Ferienimmobilie eigenständig umsehen kann.",
              ]}
            />
            <CustomListItem
              align="left"
              text={[
                "- Sie erhalten von uns ",
                <b>kostenlose Werbung</b>,
                ", da wir das Video ebenfalls auf unserem YouTube-Kanal hochladen (insofern Sie das möchten).",
              ]}
            />
            <CustomListItem
              align="left"
              text={[
                "- ",
                <b>Nachträgliche Änderungswünsche</b>,
                " sind möglich, schließlich wollen wir das bestmögliche Video für Sie produzieren.",
              ]}
            />
            <CustomListItem
              align="left"
              text={[
                "- Durch unsere Maklertätigkeit besitzen wir jahrelange ",
                <b>Erfahrung im Marketingbereich</b>,
                " und wissen, welche spezifischen Merkmale es bei Ihrer Ferienimmobile hervorzuheben gilt.",
              ]}
            />
          </Box>

          <Box mt={5}>
            <Typography variant="body1">
              Sie haben weitere Fragen? Kontaktieren Sie uns gerne.
            </Typography>
          </Box>

          <Box mt={4} mb={6}>
            <ContactForm
              buttonText="Kostenloses Erstgespräch"
              buttonVariant="contained"
              buttonColor="primary"
              isMobile={false}
              leavePageOnClose={false}
              showButtonIcon={false}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default VideoCreation;
