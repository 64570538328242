import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import convert from "htmr";
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Box,
} from "@material-ui/core";

/** internal imports */
import AdSearchAgent from "../search-agent/AdSearchAgent";
import OfferTeaser from "../offers/OfferTeaser";
import * as constants from "../../constants";
import AdTrailer from "../offers/AdTrailer";
import Separator from "../layout/Separator";
import Video from "../video/Video";
import { hiddenOffers } from "../../utils/hiddenOffers";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 0, 8, 0),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4, 0, 8, 0),
    },
  },
  title: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("xl")]: {
      padding: theme.spacing(0, 20),
      marginTop: theme.spacing(8),
    },
  },
  text: {
    marginTop: 0,
    marginBottom: theme.spacing(4),
    textAlign: "left",
    marginLeft: theme.spacing(4),
    [theme.breakpoints.up("xl")]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    "&>p": {
      marginTop: 0,
    },
  },
}));

const saveHeight = () => {
  const height = document.getElementById("refHeight").offsetHeight;

  sessionStorage.setItem("angeboteHeight", height);
};

const readHeight = () => {
  const height = sessionStorage.getItem("angeboteHeight");
  return height ? height + "px" : "100vh";
};

const Offers = ({ archived = false, approval = false, title }) => {
  const classes = useStyles();
  const [entries, setEntries] = useState([]);
  const websiteSettings = useSelector((state) => state.settings);

  useEffect(() => {
    saveHeight();
  }, [entries]);

  useEffect(() => {
    const url = archived ? "archived-objects" : "objects";
    fetch(constants.API_URL + url)
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        let visibleEntries = [];

        if (!archived && approval) {
          visibleEntries = data?.entries?.filter(
            (item) => item?.freitexte?.dreizeiler?.toLowerCase() === "freigabe"
          );
        } else {
          visibleEntries = data?.entries
          .filter(entry => !hiddenOffers.includes(entry.id))
          .filter(
            (item) => item?.freitexte?.dreizeiler?.toLowerCase() !== "freigabe"
          );
        }

        visibleEntries.forEach((entry) => {
          if (
            entry?.id === "23029#uqbt44" &&
            entry?.freitexte &&
            !entry?.freitexte?.dreizeiler
          ) {
            entry.freitexte.dreizeiler = "Großes Baugrundstück";
          }

          if (
            entry?.id === "23025#uqbt44" &&
            entry?.freitexte &&
            !entry?.freitexte?.dreizeiler
          ) {
            entry.freitexte.dreizeiler = "Preis pro Baugrundstück";
          }
        });

        if (data && data.entries) {
          visibleEntries = visibleEntries.sort((a, b) => {
            if (a.id === "2009") {
              a.preise.kaufpreis = parseFloat(224500);
            }

            if (archived) {
              let pA = a.id;
              let pB = b.id;
              let dA = a.datum;
              let dB = b.datum;

              if (dA > dB) {
                return -1;
              }

              if (dB > dA) {
                return 1;
              }

              if (isNaN(pA)) {
                pA = 0;
              }
              if (isNaN(pB)) {
                pB = 0;
              }

              if (String(pA)[0] * 1 <= 3) {
                pA *= 1000;
              }

              if (String(pB)[0] * 1 <= 3) {
                pB *= 1000;
              }

              return pA > pB ? -1 : 1;
            } else {
              let pA = a.preise.hasOwnProperty("kaufpreis")
                ? parseFloat(a.preise.kaufpreis)
                : parseFloat(a.preise.kaltmiete);
              let pB = b.preise.hasOwnProperty("kaufpreis")
                ? parseFloat(b.preise.kaufpreis)
                : parseFloat(b.preise.kaltmiete);

              if (isNaN(pA)) {
                pA = 0;
              }
              if (isNaN(pB)) {
                pB = 0;
              }
              return pA > pB ? -1 : 1;
            }
          });
        }

        setEntries(visibleEntries);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const introtext = websiteSettings.objects_description && (
    <>{convert(websiteSettings.objects_description)}</>
  );

  const introtextArchived = websiteSettings.archived_description && (
    <>{convert(websiteSettings.archived_description)}</>
  );

  const getTitle = () => {
    if (title) return title;

    if (archived) {
      return websiteSettings.archived_title;
    } else {
      return websiteSettings.objects_title;
    }
  };

  return (
    <>
      <div
        id="angebote"
        className={classes.root}
        style={{ minHeight: readHeight() }}
      >
        <Container maxWidth="lg" id="refHeight">
          <Typography variant="h2" align="center" className={classes.title}>
            {getTitle()}
          </Typography>
          <Separator dense />

          {approval && entries?.length === 0 && (
            <Typography variant="body1" align="center">
              Keine Einträge vorhanden.
            </Typography>
          )}

          {!approval && (
            <Box
              my={4}
              display="flex"
              flexDirection={{ xs: "column-reverse", md: "row" }}
            >
              {!archived && (
                <Box width={{ xs: "100%", md: "66%" }} pr={{ xs: 0, md: 1 }}>
                  <Video yt="A76SswmXzZ8" />
                </Box>
              )}
              <Box
                width={{ xs: "100%", md: archived ? "100%" : "34%" }}
                pl={{ xs: 0, md: 0 }}
              >
                <Typography
                  variant="body1"
                  // component="div"
                  className={classes.text}
                >
                  {archived ? introtextArchived : introtext}
                </Typography>
              </Box>
            </Box>
          )}

          <Grid container spacing={5}>
            {entries.map(
              (item, index) =>
                item.freitexte && (
                  <React.Fragment key={index}>
                    {index === 3 && !archived && (
                      <Grid item xs={12}>
                        <AdSearchAgent />
                      </Grid>
                    )}
                    {index === 6 && !archived && (
                      <Grid item xs={12}>
                        <AdTrailer />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={4}>
                      <OfferTeaser item={item} archived={archived} />
                    </Grid>
                  </React.Fragment>
                )
            )}
          </Grid>
        </Container>
      </div>
      <Helmet>
        <title>
          Marquart Immobilien - Unsere Immobilienangebote in der Region Bad
          Kissingen, Schweinfurt und Würzburg
        </title>
        <meta
          name="description"
          content="Sie möchten ein Haus kaufen oder eine Wohnung mieten? Hier finden Sie unsere Immobilienangebote"
        />
      </Helmet>
    </>
  );
};

export default Offers;
