import { Typography, makeStyles } from "@material-ui/core";
import React from "react";

// internal imports
import Separator from "../layout/Separator";

const useStyles = makeStyles((theme) => ({
  outerDiv: {
    marginTop: ({ mt }) => (mt ? theme.spacing(mt) : undefined),
    marginBottom: ({ mb }) => (mb ? theme.spacing(mb) : undefined),
    marginLeft: ({ ml }) => (ml ? theme.spacing(ml) : undefined),
    marginRight: ({ mr }) => (mr ? theme.spacing(mr) : undefined),
    paddingTop: ({ pt }) => (pt ? theme.spacing(pt) : undefined),
    paddingBottom: ({ pb }) => (pb ? theme.spacing(pb) : undefined),
    paddingLeft: ({ pl }) => (pl ? theme.spacing(pl) : undefined),
    paddingRight: ({ pr }) => (pr ? theme.spacing(pr) : undefined),
  },
}));

const TitleWithSeparator = ({ title, mt, mb, ml, mr, pt, pb, pl, pr }) => {
  const classes = useStyles({ mt, mb, ml, mr, pt, pb, pl, pr });

  return (
    <div className={classes.outerDiv}>
      <Typography variant="h2" align="center">
        {title}
      </Typography>
      <Separator dense />
    </div>
  );
};

export default TitleWithSeparator;
