import { Chip, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import MouseIcon from '@material-ui/icons/Mouse';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '75%',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '60%',
    },
    [theme.breakpoints.down('md')]: {
      p: theme.spacing(2),
      overflow: 'hidden',
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'filter 0.3s ease',
  },
  before: {
    filter: 'brightness(70%)',
  },
  after: {
    filter: 'brightness(100%)',
  },
  chip: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    zIndex: 2,
    padding: '10px 20px',
    fontSize: '16px',
    textAlign: 'center',
  },
  label: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '5px 10px',
    borderRadius: 5,
    fontSize: 16,
  },
}));

const HoverImageCard = ({ beforeImage, afterImage, label }) => {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);
  const handleClick = () => setHovered(!hovered);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div 
      className={classes.card}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <img 
        src={hovered ? afterImage : beforeImage} 
        alt={label} 
        className={`${classes.image} ${hovered ? classes.after : classes.before}`} 
      />
      <Chip 
        label={isMobile ? "Tippen" : "Klicken"}
        icon={isMobile ? <TouchAppIcon /> : <MouseIcon />}
        className={classes.chip}
        size="small"
      />
      <div className={classes.label}>
        {label}
      </div>
    </div>
  );
};

export default HoverImageCard;