import React from "react";

/** MUI imports */
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  name: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
    textTransform: "uppercase",
    textAlign: "left",
  },
  profileImage: {
    width: 252,
    height: 315,
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    alignSelf: "flex-start",
    marginBottom: 50,
    objectFit: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "unset",
      height: "unset",
    },
  },
  description: {
    marginBottom: theme.spacing(3),
    textAlign: "left",
    fontWeight: "lighter",
  },
  outerBox: {
    alignItems: "center",
    padding: "0 0 5px 0",
    margin: "5px 0 5px 0",
    minWidth: "50%",
    minHeight: 450,
    height: "100%",
    background: "#fff",
    [theme.breakpoints.down("md")]: {
      height: "unset",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  leftBox: {
    alignSelf: "flex-start",
    backgroundColor: "white",
    height: "100%",
    minHeight: "450px",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "unset",
    },
  },
  rightBox: {
    alignSelf: "flex-end",
    padding: theme.spacing(0, 3, 3, 3),
    backgroundColor: "white",
    height: "calc(100% - 24px)",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      minHeight: "426px",
      height: "calc(100% - 24px)",
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 24px)",
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "400px",
      paddingTop: theme.spacing(1),
    },
  },
  contactBtn: {
    margin: theme.spacing(3),
  },
  specialFieldListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  specialFieldList: { paddingTop: 0, paddingBottom: 0 },
  checkIcon: {
    fontSize: theme.spacing(2.5),
    fontWeight: "bold",
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
  nameOfFirstProfile: {
    wordBreak: "break-word",
    width: 140,
    [theme.breakpoints.down("xs")]: {
      width: "unset",
    },
  },
}));

const CustomProfile = ({
  id,
  name,
  image,
  email,
  description,
  specialFields,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.outerBox}
      flexDirection="row"
      display="flex"
      style={
        id === 1
          ? {
              minMidth: "100%",
              background: "white",
              padding: 0,
            }
          : {}
      }
      key={id}
    >
      <Box display="flex" flexDirection="column" className={classes.leftBox}>
        <img src={image} className={classes.profileImage} alt="Team member" />

        <Button
          variant="outlined"
          className={classes.contactBtn}
          component="a"
          href={`mailto:${email}`}
        >
          Kontaktieren
        </Button>
      </Box>

      <Box display="flex" flexDirection="column" className={classes.rightBox}>
        <Box>
          <Typography
            variant="h5"
            align="center"
            className={clsx(
              classes.name,
              id !== 1 && classes.nameOfFirstProfile
            )}
          >
            {name}
          </Typography>

          <Typography
            variant="body1"
            align="center"
            className={classes.description}
          >
            {description}
          </Typography>
        </Box>

        <Box>
          <Typography
            variant="body1"
            align="left"
            className={classes.specialFieldTitle}
          >
            Aufgabenschwerpunkte:
          </Typography>

          <List
            component="nav"
            className={classes.specialFieldList}
            aria-label="specialFields"
          >
            {specialFields?.map((field, index) => (
              <ListItem className={classes.specialFieldListItem} key={index}>
                <ListItemIcon className={classes.listItemIcon}>
                  <CheckOutlinedIcon className={classes.checkIcon} />
                </ListItemIcon>
                <Typography variant="body1">{field?.title}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomProfile;
