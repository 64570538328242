import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import Video from "./Video";

export default ({ video }) => {
  return (
    <Paper>
      <Video yt={video.videoId} />
      <Box mt={2} p={2} height={180}>
        <Typography variant="h5" gutterBottom component="h2">
          {video.title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {video.description}
        </Typography>
      </Box>
    </Paper>
  );
};
