import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

// MUI components
import { Box, Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Internal components
import Separator from "../layout/Separator";
import immobilienbewertungTeaserImg from "../../images/immobilienbewertung/immobilienbewertung-teaser.jpeg";

const ImmobilienBewertungTeaser = ({ isDarkMode, isHome }) => {
  const classes = useStyles({ isDarkMode, isHome });
  const history = useHistory();

  const navigateToImmobilienbewertung = () =>
    history.push("/immobilienbewertung");

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h3" align="center" className={classes.title}>
        Immobilienbewertung
      </Typography>
      <Separator dense />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.teaserBox}
      >
        <Box
          className={classes.outerBoxImg}
          onClick={navigateToImmobilienbewertung}
        >
          <img
            src={immobilienbewertungTeaserImg}
            alt="immobilienbewertung-teaser"
            className={classes.teaserImg}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width={450}
        >
          <Typography variant="h4" className={classes.teaserText}>
            Welchen Kaufpreis
            <br />
            erzielt meine Immobilie?
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            onClick={navigateToImmobilienbewertung}
            size="small"
            className={classes.navigateBtn}
          >
            Jetzt kostenlos bewerten
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: ({ isHome }) => (isHome ? theme.spacing(2) : theme.spacing(7)),
  },
  title: {
    wordBreak: "break-word",
    marginBottom: 0,
    color: ({ isDarkMode }) =>
      isDarkMode ? theme.palette.background.paper : theme.palette.text.title,
  },
  teaserImg: {
    width: 400,
    height: 300,
    objectFit: "cover",
    objectPosition: "70% 30%",
    [theme.breakpoints.down("xs")]: {
      width: 350,
    },
  },
  teaserBox: {
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  teaserText: {
    color: ({ isDarkMode }) =>
      isDarkMode ? theme.palette.background.paper : theme.palette.text.title,
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  outerBoxImg: {
    cursor: "pointer",
    position: "relative",
    "& div": {
      position: "absolute",
      top: theme.spacing(2),
      left: "20px",
      border: "1px solid #fff",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      background: "#fff",
      zIndex: 1,
      "& p": {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
  },
  navigateBtn: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
}));

export default ImmobilienBewertungTeaser;
