import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  list: {
    background: "#fff",
    paddingTop: 0,
  },
}));

const SearchAgentStep1 = ({
  handleToggleObjektart,
  checkedObjektart,
  objektarten,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box pt={{ xs: 1, sm: 2 }} pb={{ xs: 1, sm: 2 }} mb={3} bgcolor="#f9f9f9">
        <Typography variant="h2" align="center">
          Objektart
        </Typography>
      </Box>
      <Box px={3}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12}>
            <List className={classes.list}>
              <List disablePadding>
                {Object.keys(objektarten).map((artKey) => (
                  <ListItem
                    key={"h" + artKey}
                    className={classes.nested}
                    button
                    onClick={handleToggleObjektart(artKey)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        checked={checkedObjektart.indexOf(artKey) !== -1}
                      />
                    </ListItemIcon>
                    <ListItemText>{objektarten[artKey]}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SearchAgentStep1;
