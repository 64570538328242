import React from "react";
import {
  Typography,
  Box,
  Paper,
  makeStyles,
  Button,
  Grid,
} from "@material-ui/core";
import AnhaengerBild from "../../images/anhaenger.jpg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bild: {
    width: "100%",
    height: "auto",
  },
  verticalCenter: {
    display: "flex",
    alignItems: "center",
  },
}));

const AdTrailer = () => {
  const classes = useStyles();

  return (
    <>
      <Box py={{ xs: 2, md: 4 }} px={{ xs: 0, md: 8 }}>
        <Paper>
          <Grid container>
            <Grid item xs={12} sm={3} className={classes.verticalCenter}>
              <img
                src={AnhaengerBild}
                alt="Anhänger kostenlos mieten"
                className={classes.bild}
              />
            </Grid>
            <Grid item xs={12} sm={9} className={classes.verticalCenter}>
              <Box
                pt={{ xs: 0, sm: 4 }}
                pb={{ xs: 2, sm: 4 }}
                px={{ xs: 2, md: 6 }}
              >
                <Typography variant="h3" align="left">
                  Unser Service für Sie
                </Typography>
                <Box py={2}>
                  <Typography variant="body1" align="left">
                    Nutzen Sie einen unserer kostenlosen Anhänger!
                    <br />
                    Was immer Sie auch vorhaben – ob Fahrten zum Wertstoffhof,
                    Baumarkt oder Einrichtungshaus – wir bieten Ihnen diese
                    Anhänger zur kostenlosen Nutzung an.
                  </Typography>
                </Box>
                <Button
                  disableFocusRipple
                  variant="contained"
                  color="secondary"
                  size="small"
                  component={Link}
                  to="/anhaenger"
                >
                  Weitere Informationen
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default AdTrailer;
