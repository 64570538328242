import React from "react";
import {
  Heading,
  Text,
  Divider,
  Grid,
  Button,
  Image,
  CallToAction,
  YouTubeVideo,
} from "../cockpit";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  component: {
    padding: theme.spacing(1, 0),
  },
}));

export default ({ components }) => {
  const classes = useStyles();

  const defaultComponents = {
    heading: Heading,
    text: Text,
    divider: Divider,
    grid: Grid,
    button: Button,
    image: Image,
    calltoaction: CallToAction,
    youtubevideo: YouTubeVideo,
  };

  return (
    <div className="page--components">
      {components &&
        components.map((component_parsed, idx) => {
          const name = component_parsed.component.toLowerCase();
          const settings = component_parsed.settings;
          const columns = component_parsed.columns || [];

          if (defaultComponents[name] === undefined) {
            return null;
          }

          const Component = defaultComponents[name];

          return (
            <div key={`component-${idx}`} className={classes.component}>
              <Component name={name} {...settings} columns={columns} />
            </div>
          );
        })}
    </div>
  );
};
