import React from "react";
import OfferTeaser from "./OfferTeaser";
import { default as SlickSlider } from "react-slick";
import useScreensize from "../../utils/useScreensize";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  slide: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
}));

const settingsDesktop = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  centerMode: false,
  variableWidth: false,
};

const settingsMobile = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  variableWidth: false,
  arrows: false,
};

const settingsSm = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  centerMode: false,
  variableWidth: false,
  arrows: false,
};

const Slider = ({ items = [], archived = false }) => {
  const classes = useStyles();
  const { xs, sm } = useScreensize();

  let sliderSettings = settingsDesktop;
  if (xs) {
    sliderSettings = settingsMobile;
  }
  if (sm) {
    sliderSettings = settingsSm;
  }

  if (!items || !items.length) {
    return null;
  }

  return (
    <>
      <SlickSlider {...sliderSettings}>
        {items.map(
          (item, index) =>
            item.freitexte && (
              <div className={classes.slide} key={index}>
                <OfferTeaser archived={archived} item={item} />
              </div>
            )
        )}
      </SlickSlider>
    </>
  );
};

export default Slider;
