import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  Container,
  Paper,
  StepButton,
} from "@material-ui/core";
import * as constants from "../../constants";
import { grey } from "@material-ui/core/colors";
import { connect } from "react-redux";
import useScreensize from "../../utils/useScreensize";
import SearchAgentStep1 from "./SearchAgentStep1";
import SearchAgentStepSuccess from "./SearchAgentStepSuccess";
import SearchAgentStep2 from "./SearchAgentStep2";
import SearchAgentStep3 from "./SearchAgentStep3";
import SearchAgentStep4 from "./SearchAgentStep4";
import SearchAgentStep5 from "./SearchAgentStep5";
import Video from "../video/Video";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    [theme.breakpoints.up("xs")]: {
      background: grey[100],
      boxShadow:
        "0px 1px 6px 0px rgba(0,0,0,0.2), 0px 1px 2px 0px rgba(0,0,0,0.14), 0px 2px 2px -1px rgba(0,0,0,0.12)",
    },
  },
  dialogActions: {
    [theme.breakpoints.up("xs")]: {
      background: grey[100],
      boxShadow:
        "0px 1px 6px 2px rgba(0,0,0,0.2), 0px 1px 2px 2px rgba(0,0,0,0.14), 0px 2px 2px 1px rgba(0,0,0,0.12)",
    },
  },
  dialogContent: {
    background: "#f9f9f9",
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 210px)",
    },
  },
  cancelBtn: {
    marginRight: "auto",
  },
  stepper: {
    background: "transparent",
  },
  paper: {
    overflow: "hidden",
    marginBottom: theme.spacing(4),
  },
  activeStepIcon: {
    marginTop: -7,
    "&> svg": {
      width: 40,
      height: 40,
    },
  },
  activeStepLabel: {
    "&> span": {
      fontSize: 17,
    },
  },
  header: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  headerSubtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const steps = ["Objektart", "Eckdaten", "Ausstattung", "Lage", "Kontaktdaten"];

const objektarten = {
  1: "Haus (z.B. EFH, DHH zur Eigennutzung)",
  2: "Wohnung (zur Eigennutzung)",
  3: "Grundstück",
  4: "Gewerbeeinheit",
  5: "Renditeobjekt (z.B. MFH, vermietete ETW)",
};

const ausstattung = {
  1: "Barrierefrei",
  2: "Keller",
  3: "Balkon / Terrasse",
  4: "Garten",
  5: "Garage / Tiefgarage",
};

const lageStadtKissingen = {
  1: "Kernstadt",
  2: "Albertshausen",
  3: "Arnshausen",
  4: "Garitz",
  5: "Hausen",
  6: "Kleinbrach",
  7: "Poppenroth",
  8: "Reiterswiesen",
  9: "Winkels",
};

const lageLkrKissingen = {
  1: "Aura a.d.Saale",
  2: "Bad Bocklet",
  3: "Bad Brückenau",
  4: "Burkardroth",
  5: "Elfershausen",
  6: "Euerdorf",
  7: "Fuchsstadt",
  8: "Geroda",
  9: "Hammelburg",
  10: "Maßbach",
  11: "Motten",
  12: "Münnerstadt",
  13: "Nüdlingen",
  14: "Oberleichtersbach",
  15: "Oberthulba",
  16: "Oerlenbach",
  17: "Ramsthal",
  18: "Rannungen",
  19: "Riedenberg",
  20: "Schondra",
  21: "Sulzthal",
  22: "Thundorf i.Ufr.",
  23: "Wartmannsroth",
  24: "Wildflecken",
  25: "Zeitlofs",
  26: "",
  27: "",
};

const lageStadtSchweinfurt = {
  1: "Altstadt / Innenstadt",
  2: "Bergl",
  3: "Deutschhof",
  4: "Eselshöhe / Haardt",
  5: "Gartenstadt / Musikerviertel",
  6: "Hochfeld/Steinberg",
  7: "Nördl. / Nordöstl. Stadtteil",
  8: "Oberndorf",
  9: "SW Süd / Hafen",
};

const lageLkrSchweinfurt = {
  1: "Bergrheinfeld",
  2: "Dingolshausen",
  3: "Dittelbrunn",
  4: "Donnersdorf",
  5: "Euerbach",
  6: "Geldersheim",
  7: "Gerolzhofen",
  8: "Gochsheim",
  9: "Grafenrheinfeld",
  10: "Grettstadt",
  11: "Kolitzheim",
  12: "Michelau i. Steigerwald",
  13: "Niederwerrn",
  14: "Oberschwarzach",
  15: "Poppenhausen",
  16: "Röthlein",
  17: "Schonungen",
  18: "Schwanfeld",
  19: "Schwebheim",
  20: "Sennfeld",
  21: "Stadtlauringen",
  22: "Sulzheim",
  23: "Üchtelhausen",
  24: "Waigolshausen",
  25: "Wasserlosen",
  26: "Werrneck",
  27: "Wipfeld",
};

const valueTextBaujahr = (value) => {
  if (value === 1900) return "<";
  if (value === 2020) return "Neu";
  return value;
};

const valueTextWohnflaeche = (value) => {
  if (value === 20) return "<";
  if (value === 320) return ">";
  return value;
};

const valueTextGrundstueck = (value) => {
  if (value === 0) return "<";
  if (value === 2200) return ">";
  // if (value >= 2000) return `${value / 1000}T`;
  return value;
};

const valueTextZimmer = (value) => {
  if (value === 11) return ">";
  return value;
};

const valueTextKaufpreis = (value) => {
  if (value === 75000) return "<";
  if (value === 750000) return ">";
  // if (value >= 1000000) return `${value / 1000000}M`;
  return `${value / 1000}T`;
};

const SearchAgent = ({ websiteSettings, open, handleClose }) => {
  const classes = useStyles();

  const [success, setSuccess] = useState(false);
  const [values, setValues] = useState({
    salutation: "",
    firstname: "",
    lastname: "",
    street: "",
    zip: "",
    city: "",
    email: "",
    phone: "",
    message: "",
    accept_privacy: false,
    newsletter: false,
  });
  const [checkedObjektart, setCheckedObjektart] = useState([]);
  const [valueBaujahr, setValueBaujahr] = useState([1900, 2020]);
  const [valueWohnflaeche, setValueWohnflaeche] = useState([80, 160]);
  const [valueGrundstueck, setValueGrundstueck] = useState([400, 1000]);
  const [valueZimmer, setValueZimmer] = useState([2, 6]);
  const [valueKaufpreis, setValueKaufpreis] = useState([200000, 500000]);
  const [checkedAusstattung, setCheckedAusstattung] = useState([]);
  const [checkedStadtKissingen, setCheckedStadtKissingen] = useState([]);
  const [checkedLkrKissingen, setCheckedLkrKissingen] = useState([]);
  const [checkedStadtSchweinfurt, setCheckedStadtSchweinfurt] = useState([]);
  const [checkedLkrSchweinfurt, setCheckedLkrSchweinfurt] = useState([]);
  const [eckdatenVisited, setEckdatenVisited] = useState(false);
  const [ausstattungVisited, setAusstattungVisited] = useState(false);
  const [zips, setZips] = useState([]);

  const [activeStep, setActiveStep] = useState(0);

  const { xs, sm } = useScreensize();

  useEffect(() => {
    if (activeStep === 1) {
      setEckdatenVisited(true);
    }
    if (activeStep === 2) {
      setAusstattungVisited(true);
    }
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (i) => {
    setActiveStep(i);
  };

  const handleChangeBaujahr = (event, newValue) => {
    setValueBaujahr(newValue);
  };

  const handleChangeWohnflaeche = (event, newValue) => {
    setValueWohnflaeche(newValue);
  };

  const handleChangeGrundstueck = (event, newValue) => {
    setValueGrundstueck(newValue);
  };

  const handleChangeZimmer = (event, newValue) => {
    setValueZimmer(newValue);
  };

  const handleChangeKaufpreis = (event, newValue) => {
    let v = newValue.map((v) => {
      if (v < 300000) {
        return v;
      }
      return Math.round(v / 50000) * 50000;
    });
    setValueKaufpreis(v);
  };

  const handleToggleObjektart = (value) => () => {
    const currentIndex = checkedObjektart.indexOf(value);
    const newChecked = [...checkedObjektart];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedObjektart(newChecked);
  };

  const handleToggleAusstattung = (value) => () => {
    const currentIndex = checkedAusstattung.indexOf(value);
    const newChecked = [...checkedAusstattung];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedAusstattung(newChecked);
  };

  const handleToggleAllStadtKissingen = () => {
    if (
      checkedStadtKissingen.length === Object.keys(lageStadtKissingen).length
    ) {
      setCheckedStadtKissingen([]);
    } else {
      setCheckedStadtKissingen(Object.keys(lageStadtKissingen));
    }
  };

  const handleToggleStadtKissingen = (value) => () => {
    const currentIndex = checkedStadtKissingen.indexOf(value);
    const newChecked = [...checkedStadtKissingen];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedStadtKissingen(newChecked);
  };

  const handleToggleAllLkrKissingen = () => {
    if (checkedLkrKissingen.length === Object.keys(lageLkrKissingen).length) {
      setCheckedLkrKissingen([]);
    } else {
      setCheckedLkrKissingen(Object.keys(lageLkrKissingen));
    }
  };

  const handleToggleLkrKissingen = (value) => () => {
    const currentIndex = checkedLkrKissingen.indexOf(value);
    const newChecked = [...checkedLkrKissingen];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedLkrKissingen(newChecked);
  };

  const handleToggleAllStadtSchweinfurt = () => {
    if (
      checkedStadtSchweinfurt.length ===
      Object.keys(lageStadtSchweinfurt).length
    ) {
      setCheckedStadtSchweinfurt([]);
    } else {
      setCheckedStadtSchweinfurt(Object.keys(lageStadtSchweinfurt));
    }
  };

  const handleToggleStadtSchweinfurt = (value) => () => {
    const currentIndex = checkedStadtSchweinfurt.indexOf(value);
    const newChecked = [...checkedStadtSchweinfurt];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedStadtSchweinfurt(newChecked);
  };

  const handleToggleAllLkrSchweinfurt = () => {
    if (
      checkedLkrSchweinfurt.length === Object.keys(lageLkrSchweinfurt).length
    ) {
      setCheckedLkrSchweinfurt([]);
    } else {
      setCheckedLkrSchweinfurt(Object.keys(lageLkrSchweinfurt));
    }
  };

  const handleToggleLkrSchweinfurt = (value) => () => {
    const currentIndex = checkedLkrSchweinfurt.indexOf(value);
    const newChecked = [...checkedLkrSchweinfurt];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedLkrSchweinfurt(newChecked);
  };

  const handleChangeSelect = (event) => {
    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeInput = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangePrivacy = () => {
    const newPrivacy = !values.accept_privacy;
    setValues({ ...values, accept_privacy: newPrivacy });
  };

  const handleChangeNewsletter = () => {
    const newNewsletter = !values.newsletter;
    setValues({ ...values, newsletter: newNewsletter });
  };

  const handleSend = async (event) => {
    event.preventDefault();

    const {
      salutation,
      email,
      firstname,
      lastname,
      street,
      zip,
      city,
      phone,
      newsletter,
      message,
    } = values;

    const body = {
      salutation,
      email,
      firstname,
      lastname,
      street,
      zip,
      city,
      phone_number: phone,
      newsletter,
      message,
      objektart: checkedObjektart.map((a) => objektarten[a]).join(", "),
      baujahr: valueBaujahr
        .map((b) => (b === 1900 ? "vor 1900" : b === 2020 ? "Neubau" : b))
        .join(" bis "),
      wohnflaeche: valueWohnflaeche
        .map((b) => (b === 20 ? "unter 40" : b === 320 ? "über 300" : b))
        .join(" bis "),
      grundstueck: valueGrundstueck
        .map((b) => (b === 0 ? "unter 200" : b === 2200 ? "über 2.000" : b))
        .join(" bis "),
      zimmer: valueZimmer.map((b) => (b === 11 ? "über 10" : b)).join(" bis "),
      kaufpreis: valueKaufpreis
        .map((b) =>
          b === 75000 ? "unter 100.000" : b === 750000 ? "über 700.000" : b
        )
        .join(" bis "),
      ausstattung: checkedAusstattung.map((a) => ausstattung[a]).join(", "),
      lageStadtKissingen: checkedStadtKissingen
        .map((a) => lageStadtKissingen[a])
        .join(", "),
      lageLkrKissingen: checkedLkrKissingen
        .map((a) => lageLkrKissingen[a])
        .join(", "),
      lageStadtSchweinfurt: checkedStadtSchweinfurt
        .map((a) => lageStadtSchweinfurt[a])
        .join(", "),
      lageLkrSchweinfurt: checkedLkrSchweinfurt
        .map((a) => lageLkrSchweinfurt[a])
        .join(", "),
      zips: zips.join(", "),
    };

    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(constants.API_URL + "suchagent", {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        setSuccess(true);
      });
  };

  const validContact =
    values.salutation &&
    values.email &&
    values.firstname &&
    values.street &&
    values.zip &&
    values.city &&
    values.lastname &&
    values.phone &&
    values.accept_privacy;

  const isValid = {
    0: Boolean(checkedObjektart.length),
    1: eckdatenVisited,
    2: ausstattungVisited,
    3: Boolean(
      checkedStadtKissingen.length ||
        checkedLkrKissingen.length ||
        checkedStadtSchweinfurt.length ||
        checkedLkrSchweinfurt.length ||
        zips.length
    ),
    4: Boolean(validContact),
  };

  return (
    <>
      <Container maxWidth="lg">
        <Box pt={2} pb={1}>
          <Box
            my={4}
            display="flex"
            flexDirection={{ xs: "column-reverse", md: "row" }}
          >
            <Box width={{ xs: "100%", md: "66%" }} pr={{ xs: 0, md: 1 }}>
              <Video yt="9JaK24G9bNo" />
            </Box>
            <Box width={{ xs: "100%", md: "34%" }} pl={{ xs: 0, md: 4 }}>
              <Typography variant="h2" component="div" className={classes.text}>
                Nutzen Sie unseren Suchagenten!
              </Typography>
              <Typography
                variant="body1"
                component="div"
                className={classes.headerSubtitle}
              >
                Wir informieren Sie umgehend, sobald eine Immobilie, die Ihren
                Wünschen entspricht, verfügbar ist.
              </Typography>
            </Box>
          </Box>

          <Stepper
            nonLinear
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepper}
          >
            {steps.map((label, index) => {
              const labelProps = {};
              labelProps.error = activeStep > index && !isValid[index];
              return (
                <Step key={label}>
                  <StepButton
                    onClick={() => {
                      handleStep(index);
                    }}
                    completed={isValid[index]}
                  >
                    <StepLabel
                      classes={{
                        iconContainer:
                          index === activeStep && classes.activeStepIcon,
                        labelContainer:
                          index === activeStep && classes.activeStepLabel,
                      }}
                      {...labelProps}
                    >
                      {xs || sm ? "" : label}
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {/* {activeStep === 0 && <SearchAgentHeader />} */}
        <Paper className={classes.paper}>
          <Box pt={0} pb={2}>
            {!success && (
              <>
                {activeStep === 0 && (
                  <SearchAgentStep1
                    handleToggleObjektart={handleToggleObjektart}
                    checkedObjektart={checkedObjektart}
                    objektarten={objektarten}
                  />
                )}
                {activeStep === 1 && (
                  <SearchAgentStep2
                    valueBaujahr={valueBaujahr}
                    handleChangeBaujahr={handleChangeBaujahr}
                    valueTextBaujahr={valueTextBaujahr}
                    valueWohnflaeche={valueWohnflaeche}
                    handleChangeWohnflaeche={handleChangeWohnflaeche}
                    valueTextWohnflaeche={valueTextWohnflaeche}
                    valueGrundstueck={valueGrundstueck}
                    handleChangeGrundstueck={handleChangeGrundstueck}
                    valueTextGrundstueck={valueTextGrundstueck}
                    valueZimmer={valueZimmer}
                    handleChangeZimmer={handleChangeZimmer}
                    valueTextZimmer={valueTextZimmer}
                    valueKaufpreis={valueKaufpreis}
                    handleChangeKaufpreis={handleChangeKaufpreis}
                    valueTextKaufpreis={valueTextKaufpreis}
                    isGrundstueck={
                      checkedObjektart.length === 1 &&
                      checkedObjektart[0] === "3"
                    }
                  />
                )}
                {activeStep === 2 && (
                  <SearchAgentStep3
                    handleToggleAusstattung={handleToggleAusstattung}
                    checkedAusstattung={checkedAusstattung}
                    ausstattung={ausstattung}
                  />
                )}
                {activeStep === 3 && (
                  <SearchAgentStep4
                    handleToggleStadtKissingen={handleToggleStadtKissingen}
                    checkedStadtKissingen={checkedStadtKissingen}
                    lageStadtKissingen={lageStadtKissingen}
                    handleToggleAllStadtKissingen={
                      handleToggleAllStadtKissingen
                    }
                    handleToggleLkrKissingen={handleToggleLkrKissingen}
                    checkedLkrKissingen={checkedLkrKissingen}
                    lageLkrKissingen={lageLkrKissingen}
                    handleToggleAllLkrKissingen={handleToggleAllLkrKissingen}
                    handleToggleStadtSchweinfurt={handleToggleStadtSchweinfurt}
                    checkedStadtSchweinfurt={checkedStadtSchweinfurt}
                    lageStadtSchweinfurt={lageStadtSchweinfurt}
                    handleToggleAllStadtSchweinfurt={
                      handleToggleAllStadtSchweinfurt
                    }
                    handleToggleLkrSchweinfurt={handleToggleLkrSchweinfurt}
                    checkedLkrSchweinfurt={checkedLkrSchweinfurt}
                    lageLkrSchweinfurt={lageLkrSchweinfurt}
                    handleToggleAllLkrSchweinfurt={
                      handleToggleAllLkrSchweinfurt
                    }
                    zips={zips}
                    setZips={setZips}
                  />
                )}
                {activeStep === 4 && (
                  <SearchAgentStep5
                    values={values}
                    handleChangeSelect={handleChangeSelect}
                    handleChangeInput={handleChangeInput}
                    handleChangePrivacy={handleChangePrivacy}
                    handleChangeNewsletter={handleChangeNewsletter}
                  />
                )}
              </>
            )}
            {success && <SearchAgentStepSuccess />}
          </Box>

          <Box display="flex" bgcolor="#f9f9f9" mt={4} p={{ xs: 2, sm: 4 }}>
            {!success && activeStep > 0 && (
              <Button
                onClick={handleBack}
                color="primary"
                disableFocusRipple={true}
              >
                Zurück
              </Button>
            )}
            {!success && activeStep < 4 && (
              <Button
                variant="contained"
                onClick={handleNext}
                color="primary"
                style={{ marginLeft: "auto" }}
                disabled={!isValid[activeStep]}
              >
                Weiter
              </Button>
            )}
            {!success && activeStep === 4 && (
              <>
                <Box marginLeft="auto" display="flex" alignItems="center">
                  {!Object.values(isValid).reduce((a, b) => a && b, true) && (
                    <Typography variant="body2" component="span">
                      Ihre Angaben sind noch nicht komplett
                    </Typography>
                  )}
                  <Button
                    size="large"
                    variant="contained"
                    onClick={handleSend}
                    color="secondary"
                    disabled={
                      !Object.values(isValid).reduce((a, b) => a && b, true)
                    }
                    style={{ marginLeft: 16 }}
                  >
                    Senden
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = ({ settings }) => {
  return {
    websiteSettings: settings,
  };
};

export default connect(mapStateToProps)(SearchAgent);
