import React from "react";
import { Dialog, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

const ExposeDialog = ({ children, open, handleClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreen}
    >
      {children}
    </Dialog>
  );
};

export default ExposeDialog;
