import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Typography,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  list: {
    background: "#fff",
    paddingTop: 0,
  },
}));

const SearchAgentStep3 = ({
  ausstattung,
  handleToggleAusstattung,
  checkedAusstattung,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box pt={{ xs: 1, sm: 2 }} pb={{ xs: 1, sm: 2 }} mb={3} bgcolor="#f9f9f9">
        <Typography variant="h2" align="center">
          Ausstattung
        </Typography>
      </Box>
      <Box px={3}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <List className={classes.list} dense>
              {Object.keys(ausstattung).map((artKey) => (
                <ListItem
                  key={"h" + artKey}
                  button
                  onClick={handleToggleAusstattung(artKey)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      checked={checkedAusstattung.indexOf(artKey) !== -1}
                    />
                  </ListItemIcon>
                  <ListItemText>{ausstattung[artKey]}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SearchAgentStep3;
