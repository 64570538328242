import React from "react";

/** MUI imports */
import { Box, Container, makeStyles, Typography } from "@material-ui/core";

/* internal imports */
import imageThorsten from "../../images/employees/5_Thorsten.jpg";
import imageMartin from "../../images/employees/1_Martin.jpg";
import imageHeike from "../../images/employees/3_Heike.jpg";
import CustomProfile from "../global/CustomProfile";

const useStyles = makeStyles((theme) => ({
  title: {
    width: 240,
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("xl")]: {
      padding: theme.spacing(0, 2),
      marginTop: theme.spacing(1),
    },
  },
  sectionOneLeftHeader: {
    paddingRight: theme.spacing(3),
  },
  sectionOneLeftHeaderPartTwo: {
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  sectionOneLeftContent: {
    marginBottom: theme.spacing(5),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingRight: "unset",
    },
  },
  sectionOneRightCard: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4),
    },
  },
  sectionOneRightHeader: {
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  introductionOuterBox: {
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  outerBoxProfileRow: {
    gap: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("md")]: {
      display: "grid",
      gap: "unset",
      marginBottom: "unset",
      "&:last-child": {
        marginBottom: theme.spacing(1),
      },
    },
  },
  container: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  introductionContent: {
    padding: theme.spacing(2),
  },
}));

const Team = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" id="team" className={classes.container}>
      <Box display="flex" className={classes.introductionOuterBox}>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          className={classes.introductionContent}
        >
          <Typography
            variant="h2"
            align="left"
            className={classes.sectionOneLeftHeader}
          >
            Ein Team.
          </Typography>
          <Typography
            variant="h4"
            align="left"
            className={classes.sectionOneLeftHeaderPartTwo}
          >
            Mit Expertise und Erfahrung für jeden Fall gewappnet.
          </Typography>

          <Typography
            variant="body1"
            align="left"
            className={classes.sectionOneLeftContent}
          >
            Wer eine Immobilie erwerben oder veräußern möchte, braucht einen
            starken und verlässlichen Partner an seiner Seite. Das gesamte Team
            von Marquart Immobilien kennt sich nicht nur in der Branche aus und
            behält die aktuelle Marktlage im Blick, sondern weiß auch um
            Neuerungen in Gesetzen und im Finanzsektor. Somit können Sie sich
            jederzeit darauf verlassen, durch uns eine intensive Betreuung und
            eine tiefgehende Beratung rund um Ihre Immobilienfragen zu erhalten.
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          flex={1.5}
          style={{ gap: 10 }}
        >
          <Box
            style={{ backgroundColor: "white" }}
            className={classes.sectionOneRightCard}
          >
            <Typography
              variant="h4"
              align="left"
              className={classes.sectionOneRightHeader}
            >
              Unser Werteverständnis
            </Typography>

            <Typography variant="body1" align="left">
              Uns ist vor allem eines wichtig: Ein vertrauensvolles und
              verlässliches Miteinander. Diese wirkt sich nicht nur intern im
              Team aus, sondern spiegelt sich auch in Kundenbeziehungen wider.
              Wir legen größtmöglichen Wert darauf, dass Sie sich bei uns gut
              aufgehoben fühlen. Daher stehen wir Ihnen als kompetenter
              Ansprechpartner jederzeit zur Seite – ganz egal, um welches
              Immobilienthema oder um welche Frage es sich dreht.
            </Typography>
          </Box>

          <Box
            style={{ backgroundColor: "white" }}
            className={classes.sectionOneRightCard}
          >
            <Typography
              variant="h4"
              align="left"
              className={classes.sectionOneRightHeader}
            >
              Unser Ziel
            </Typography>

            <Typography variant="body1" align="left">
              Sie möchten sich sicher bei allen Angelegenheiten rund um den
              Verkauf oder den Erwerb einer Immobilie wissen? Wir kümmern uns
              darum, dass Ihr Immobilienvorhaben in jedem Bereich zuverlässig
              durchgeführt wird. Sie dürfen sich sorgenfrei zurücklehnen, wir
              erledigen den Rest für Sie. Unser Ziel ist, dass Sie sich darauf
              verlassen dürfen, dass wir für Sie das bestmögliche Ergebnis bei
              Ihrem Immobilienvorhaben realisieren.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* profiles */}
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        mt={3}
        className={classes.outerBoxProfileRow}
      >
        <Box flex={1}>
          <CustomProfile
            id={1}
            name="Martin Marquart"
            image={imageMartin}
            description="Aufgewachsen ist der Diplom-Ingenieur in NRW. Schon während seines technischen Studiums sammelt er Wissen und Erfahrung im Immobiliensektor und baut dieses über die nächsten Jahre intensiv aus. Nach einem erfahrungsreichen Aufenthalt in Michigan/USA wohnt er seit 2012 in Unterfranken und investiert regional in Immobilien. Aus seiner Leidenschaft für Immobilien wagt er den Schritt in die Selbstständigkeit und gründet 2018 in Bad Kissingen ein Maklerbüro. Als Visionär und Unternehmer hat er sowohl das strategische Wachstum als auch das operative Tagesgeschäft im Blick."
            specialFields={[
              { title: "Geschäftsführung" },
              { title: "Strategie & Entwicklung" },
              { title: "Controlling & Prozesse" },
              { title: "Beratung der Eigentümer" },
              { title: "Bewertung & Vor Ort Termine" },
            ]}
            email="martin.marquart@marquart-immobilien.de"
          />
        </Box>
      </Box>

      <Box
        display="flex"
        className={classes.outerBoxProfileRow}
        style={{ marginBottom: "32px" }}
      >
        <Box flex={1}>
          <CustomProfile
            id={5}
            name="Heike Schmitt"
            image={imageHeike}
            description={`Sie haben Fragen zu einer Immobilie, welche wir im Angebot haben? Sie wollen besichtigen? Sie haben sich bei uns beworben? Sie sind im "B to B" tätig und wollen uns Ihre Dienstleistung vorstellen? Frau Schmitt steht Ihnen zur Verfügung und leitet Ihr Anliegen intern weiter.`}
            specialFields={[
              { title: "Assistenz des Inhabers" },
              { title: "Erstkontakt Bestandsobjekte" },
              { title: "Personalwesen" },
              { title: "Marketing" },
              { title: "Kaufmännsch verwaltende Tätigkeiten" },
            ]}
            email="heike.schmitt@marquart-immobilien.de"
          />
        </Box>

        <Box flex={1}>
          <CustomProfile
            id={4}
            name="Thorsten Kogge"
            image={imageThorsten}
            description="Sie wollen Ihre Immobilie verkaufen oder vermieten? Sie sind sich unschlüssig, welcher Schritt sinnvoller ist? Welchen Preis können Sie aktuell erzielen? Wir erstellen im Team eine kostenlose Marktpreiseinschätzung für Sie!
            Sie haben besichtigt und wollen eine Immobilie erwerben. Herr Kogge leitet unseren Verkauf und koordiniert die Abläufe. Hierzu zählen unter anderem die Sicherstellung der Finanzierung und die Erstellung des Kaufvertrags."
            specialFields={[
              { title: "Leiter Verkauf" },
              { title: "Erstkontakt Neuobjekte" },
              { title: "Marktpreiseinschätzung" },
              { title: "Kundenbetreuung" },
              { title: "Kaufabwicklung" },
            ]}
            email="thorsten.kogge@marquart-immobilien.de"
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Team;
