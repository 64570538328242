import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Grid,
  Divider,
  Box,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Button,
} from "@material-ui/core";
import clsx from "clsx";
import useScreensize from "../../utils/useScreensize";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExpandLess } from "@material-ui/icons";
import { useSelector } from "react-redux";
import convert from "htmr";
import Map from "./Map";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
    paddingBottom: 0,
  },
  list: {
    background: "#fff",
    paddingTop: 0,
    paddingBottom: 0,
  },
  hidden: {
    visibility: "hidden",
    pointerEvents: "none",
  },
  listIcon: {
    minWidth: 35,
  },
  stadt: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(0),
      "&:last-child": {
        marginBottom: theme.spacing(2),
      },
    },
  },
  text: {
    fontSize: 14,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },
}));

const SearchAgentStep4 = ({
  handleToggleAllStadtKissingen,
  handleToggleStadtKissingen,
  checkedStadtKissingen,
  lageStadtKissingen,

  handleToggleAllLkrKissingen,
  handleToggleLkrKissingen,
  checkedLkrKissingen,
  lageLkrKissingen,

  handleToggleAllStadtSchweinfurt,
  handleToggleStadtSchweinfurt,
  checkedStadtSchweinfurt,
  lageStadtSchweinfurt,

  handleToggleAllLkrSchweinfurt,
  handleToggleLkrSchweinfurt,
  checkedLkrSchweinfurt,
  lageLkrSchweinfurt,

  zips,
  setZips,
}) => {
  const classes = useStyles();
  const { xs, sm } = useScreensize();
  const [detailsStadtKissingen, setDetailsStadtKissingen] = useState(false);
  const [detailsLkrKissingen, setDetailsLkrKissingen] = useState(false);
  const [detailsStadtSchweinfurt, setDetailsStadtSchweinfurt] = useState(false);
  const [detailsLkrSchweinfurt, setDetailsLkrSchweinfurt] = useState(false);
  const websiteSettings = useSelector((state) => state.settings);
  const [zipError, setZipError] = useState(false);
  const [openMap, setOpenMap] = useState(false);

  useEffect(() => {
    if (!zipError) {
      return;
    }

    setTimeout(() => {
      setZipError(false);
    }, 1000);
  }, [zipError]);

  const suchagentLageDesc = websiteSettings.suchagent_lage_description && (
    <>{convert(websiteSettings.suchagent_lage_description)}</>
  );

  const handleOpenMap = () => {
    setOpenMap(true);
  };

  const handleCloseMap = () => {
    setOpenMap(false);
  };

  const handleApplyMap = (res) => {
    // console.log(res);
    setZips(res);
    setOpenMap(false);
  };

  const handleToggleDetailsStadtKissingen = () => {
    setDetailsStadtKissingen((d) => !d);
  };

  const handleToggleDetailsLkrKissingen = () => {
    setDetailsLkrKissingen((d) => !d);
  };

  const handleToggleDetailsStadtSchweinfurt = () => {
    setDetailsStadtSchweinfurt((d) => !d);
  };

  const handleToggleDetailsLkrSchweinfurt = () => {
    setDetailsLkrSchweinfurt((d) => !d);
  };

  return (
    <>
      <Box pt={{ xs: 1, sm: 2 }} pb={{ xs: 1, sm: 2 }} mb={3} bgcolor="#f9f9f9">
        <Typography variant="h2" align="center">
          Lage
        </Typography>
      </Box>
      <Box px={3}>
        <Typography
          variant="body2"
          align="left"
          color="textPrimary"
          component="div"
          className={classes.text}
          gutterBottom
        >
          {suchagentLageDesc}
        </Typography>

        <Typography variant="h6">
          Für Ihre Suche außerhalb vom Kreis Bad Kissingen und Kreis Schweinfurt
          nutzen Sie bitte unsere Postleitzahlen-Karte.
        </Typography>
        {Boolean(zips && zips.length) && (
          <Box mt={2}>
            <Typography variant="body2">
              <strong>Ihre Auswahl: </strong> {zips.join(", ")}
            </Typography>
          </Box>
        )}
        <Box pt={2} mb={8}>
          <Button variant="contained" color="secondary" onClick={handleOpenMap}>
            {Boolean(zips && zips.length) ? "Auswahl ändern" : "Karte anzeigen"}
          </Button>
        </Box>

        <Box mb={2}>
          <Typography variant="h6">
            Für Ihre Suche im Kreis Bad Kissingen und Kreis Schweinfurt nutzen
            Sie bitte unsere detaillierte Auflistung der Orte / Stadtteile.
          </Typography>
        </Box>
        <Grid container spacing={0} justify="center">
          <Grid item xs={12} md={3}>
            <Grid container>
              {[0, 1, 2].map((i) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={12}
                  key={"lkrkg" + i}
                  className={classes.stadt}
                >
                  <List
                    className={classes.list}
                    dense={true}
                    style={!xs && !sm ? { marginRight: 20 } : undefined}
                  >
                    {(sm || i === 0) && (
                      <>
                        <ListItem
                          button
                          onClick={handleToggleAllStadtKissingen}
                          disabled={i > 0}
                        >
                          <ListItemIcon
                            className={clsx(
                              classes.listIcon,
                              i > 0 && classes.hidden
                            )}
                          >
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              indeterminate={
                                checkedStadtKissingen.length &&
                                checkedStadtKissingen.length !==
                                  Object.keys(lageStadtKissingen).length
                                  ? true
                                  : undefined
                              }
                              checked={
                                checkedStadtKissingen.length ===
                                Object.keys(lageStadtKissingen).length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={clsx(i > 0 && classes.hidden)}
                            style={{ marginRight: -18 }}
                          >
                            <strong>Stadt Bad Kissingen</strong>
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              onClick={handleToggleDetailsStadtKissingen}
                            >
                              {!detailsStadtKissingen ? (
                                <ExpandMoreIcon />
                              ) : (
                                <ExpandLess />
                              )}
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </>
                    )}
                    {detailsStadtKissingen && (
                      <List disablePadding dense={true}>
                        {Object.keys(lageStadtKissingen)
                          .slice(i * 3, i * 3 + 3)
                          .map((artKey) => (
                            <ListItem
                              key={"h" + artKey}
                              className={classes.nested}
                              button
                              onClick={handleToggleStadtKissingen(artKey)}
                            >
                              <ListItemIcon className={classes.listIcon}>
                                <Checkbox
                                  edge="start"
                                  tabIndex={-1}
                                  disableRipple
                                  checked={
                                    checkedStadtKissingen.indexOf(artKey) !== -1
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText>
                                {lageStadtKissingen[artKey]}
                              </ListItemText>
                            </ListItem>
                          ))}
                      </List>
                    )}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {[0, 1, 2].map((i) => (
            <Grid item xs={12} sm={4} md={3} key={"lkrkg" + i}>
              <List className={classes.list} dense={true}>
                {(!xs || i === 0) && (
                  <>
                    <ListItem
                      disabled={i > 0}
                      button
                      onClick={handleToggleAllLkrKissingen}
                    >
                      <ListItemIcon
                        className={clsx(
                          classes.listIcon,
                          i > 0 && classes.hidden
                        )}
                      >
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          indeterminate={
                            checkedLkrKissingen.length &&
                            checkedLkrKissingen.length !==
                              Object.keys(lageLkrKissingen).length
                              ? true
                              : undefined
                          }
                          checked={
                            checkedLkrKissingen.length ===
                            Object.keys(lageLkrKissingen).length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={clsx(i > 0 && classes.hidden)}
                        style={{ marginRight: -18 }}
                      >
                        <strong>Landkreis Bad Kissingen</strong>
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={handleToggleDetailsLkrKissingen}
                        >
                          {i === 0 &&
                            (!detailsLkrKissingen ? (
                              <ExpandMoreIcon />
                            ) : (
                              <ExpandLess />
                            ))}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                )}
                {detailsLkrKissingen && (
                  <List disablePadding dense={true}>
                    {Object.keys(lageLkrKissingen)
                      .slice(i * 9, i * 9 + 9)
                      .map(
                        (artKey) =>
                          (!xs || lageLkrKissingen[artKey] !== "") && (
                            <ListItem
                              key={"h" + artKey}
                              className={clsx(
                                classes.nested,
                                lageLkrKissingen[artKey] === "" &&
                                  classes.hidden
                              )}
                              button
                              onClick={handleToggleLkrKissingen(artKey)}
                            >
                              <ListItemIcon className={classes.listIcon}>
                                <Checkbox
                                  edge="start"
                                  tabIndex={-1}
                                  disableRipple
                                  checked={
                                    checkedLkrKissingen.indexOf(artKey) !== -1
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText>
                                {lageLkrKissingen[artKey]}
                              </ListItemText>
                            </ListItem>
                          )
                      )}
                  </List>
                )}
              </List>
            </Grid>
          ))}
        </Grid>

        <Box py={2}></Box>

        <Grid container spacing={0} justify="center">
          <Grid item xs={12} md={3}>
            <Grid container>
              {[0, 1, 2].map((i) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={12}
                  key={"lkrkg" + i}
                  className={classes.stadt}
                >
                  <List
                    className={classes.list}
                    dense={true}
                    style={!xs && !sm ? { marginRight: 20 } : undefined}
                  >
                    {(sm || i === 0) && (
                      <>
                        <ListItem
                          button
                          onClick={handleToggleAllStadtSchweinfurt}
                        >
                          <ListItemIcon
                            className={clsx(
                              classes.listIcon,
                              i > 0 && classes.hidden
                            )}
                          >
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              indeterminate={
                                checkedStadtSchweinfurt.length &&
                                checkedStadtSchweinfurt.length !==
                                  Object.keys(lageStadtSchweinfurt).length
                                  ? true
                                  : undefined
                              }
                              checked={
                                checkedStadtSchweinfurt.length ===
                                Object.keys(lageStadtSchweinfurt).length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={clsx(i > 0 && classes.hidden)}
                          >
                            <strong>Stadt Schweinfurt</strong>
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              onClick={handleToggleDetailsStadtSchweinfurt}
                            >
                              {!detailsStadtSchweinfurt ? (
                                <ExpandMoreIcon />
                              ) : (
                                <ExpandLess />
                              )}
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </>
                    )}
                    {detailsStadtSchweinfurt && (
                      <List disablePadding dense={true}>
                        {Object.keys(lageStadtSchweinfurt)
                          .slice(i * 3, i * 3 + 3)
                          .map((artKey) => (
                            <ListItem
                              key={"h" + artKey}
                              className={classes.nested}
                              button
                              onClick={handleToggleStadtSchweinfurt(artKey)}
                            >
                              <ListItemIcon className={classes.listIcon}>
                                <Checkbox
                                  edge="start"
                                  tabIndex={-1}
                                  disableRipple
                                  checked={
                                    checkedStadtSchweinfurt.indexOf(artKey) !==
                                    -1
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText>
                                {lageStadtSchweinfurt[artKey]}
                              </ListItemText>
                            </ListItem>
                          ))}
                      </List>
                    )}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {[0, 1, 2].map((i) => (
            <Grid item xs={12} sm={4} md={3} key={"lkrkg" + i}>
              <List className={classes.list} dense={true}>
                {(!xs || i === 0) && (
                  <>
                    <ListItem
                      disabled={i > 0}
                      button
                      onClick={handleToggleAllLkrSchweinfurt}
                    >
                      <ListItemIcon
                        className={clsx(
                          classes.listIcon,
                          i > 0 && classes.hidden
                        )}
                      >
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          disableRipple
                          indeterminate={
                            checkedLkrSchweinfurt.length &&
                            checkedLkrSchweinfurt.length !==
                              Object.keys(lageLkrSchweinfurt).length
                              ? true
                              : undefined
                          }
                          checked={
                            checkedLkrSchweinfurt.length ===
                            Object.keys(lageLkrSchweinfurt).length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={clsx(i > 0 && classes.hidden)}
                        style={{ marginRight: -18 }}
                      >
                        <strong>Landkreis Schweinfurt</strong>
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={handleToggleDetailsLkrSchweinfurt}
                        >
                          {i === 0 &&
                            (!detailsLkrSchweinfurt ? (
                              <ExpandMoreIcon />
                            ) : (
                              <ExpandLess />
                            ))}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                )}
                {detailsLkrSchweinfurt && (
                  <List disablePadding dense={true}>
                    {Object.keys(lageLkrSchweinfurt)
                      .slice(i * 9, i * 9 + 9)
                      .map((artKey) => (
                        <ListItem
                          key={"h" + artKey}
                          className={clsx(
                            classes.nested,
                            lageLkrSchweinfurt[artKey] === "" && classes.hidden
                          )}
                          button
                          onClick={handleToggleLkrSchweinfurt(artKey)}
                        >
                          <ListItemIcon className={classes.listIcon}>
                            <Checkbox
                              edge="start"
                              tabIndex={-1}
                              disableRipple
                              checked={
                                checkedLkrSchweinfurt.indexOf(artKey) !== -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText>
                            {lageLkrSchweinfurt[artKey]}
                          </ListItemText>
                        </ListItem>
                      ))}
                  </List>
                )}
              </List>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Map
        open={openMap}
        handleClose={handleCloseMap}
        handleApply={handleApplyMap}
      />
    </>
  );
};

export default SearchAgentStep4;
