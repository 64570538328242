import { Link, NavLink } from "react-router-dom";
import React from "react";

/** external imports */
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import blueGrey from "@material-ui/core/colors/blueGrey";
import MenuIcon from "@material-ui/icons/Menu";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Toolbar,
  useMediaQuery,
  Box,
  Typography,
} from "@material-ui/core";

/** internal imports */
import CustomMenuWithNestedItems from "../global/CustomMenuWithNestedItems";
import Logo from "../../images/logo-marquart.png";
import ImmobilienbewertungIcon from "../../images/immobilienbewertung/immobilienbewertung-icon.png";
import bgImage from "../../images/teamfoto23.jpg";
import ContactForm from "../forms/ContactForm";
import * as constants from "../../constants";
import Footer from "./Footer";
import "./style/layout.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundSize: "110% auto",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      minHeight: "unset",
    },
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    alignItems: "center",
    display: "flex",
    backgroundColor: "white",
    boxShadow: "unset",
    borderBottom: `2px solid ${theme.palette.primary.dark}`,
  },
  menuButton: {
    color: blueGrey[800],
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
  logo: {
    height: "100%",
    width: "auto",
  },
  logoWrapper: {
    height: "90%",
    marginRight: theme.spacing(0),
    padding: theme.spacing(1.5, 0),
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(1.5),
      marginRight: theme.spacing(4),
      height: "100%",
    },
  },
  appBarMenu: {
    flexGrow: 1,
  },
  tb: {
    height: 1,
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: theme.breakpoints.values.lg,
    },
  },
  btnBeratung: {
    marginRight: "15px",
  },
  menuLink: {
    color: blueGrey[800],
    margin: theme.spacing(0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(0, 0.5),
    },
  },
  active: {
    background: "#f5f1e8",
    color: theme.palette.primary.dark,
    "&:hover": {
      background: "#f5f1e8",
    },
  },
  activeDesktop: {
    "&::before": {
      content: '""',
      background: theme.palette.primary.dark,
      height: "100%",
      position: "absolute",
      left: "0px",
      top: "0px",
      width: "5px",
    },
  },
  logoDrawerWrapper: {
    padding: theme.spacing(2),
    display: "block",
  },
  logoDrawer: {
    width: "100%",
    height: "auto",
  },
  videoBadge: {
    border: "2px solid #fff",
  },
  coronaBanner: {
    marginTop: 56,
    marginBottom: -56,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 44,
    zIndex: 99,
    [theme.breakpoints.up("md")]: {
      marginTop: 64,
      marginBottom: -64,
    },
  },
  coronaLink: {
    fontSize: 16,
    color: "#fff",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
  btnIcon: {
    marginRight: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  navButtons: {
    marginRight: theme.spacing(1),
    marginBottom: "3px",
    textDecoration: "none",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "2px",
      bottom: "-3px",
      left: "50%",
      transform: "translate(-50%,0%)",
      backgroundColor: theme.palette.primary.main,
      visibility: "hidden",
      transition: "all 0.3s ease-in-out",
    },
    "&:hover::before": {
      visibility: "visible",
      width: "100%",
    },
  },
  menuPopper: { marginTop: theme.spacing(1), minWidth: "140px" },
  menu: { padding: 0 },
  menuItem: { borderBottom: "1px solid rgb(239, 239, 239)" },
  menuItemText: { paddingLeft: 0, whiteSpace: "nowrap" },
  navBarBtnIcon: {
    marginRight: theme.spacing(1),
  },
}));

const AdapterLink = React.forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

export default function Layout(props) {
  const { container } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isOpenNavItem, setIsOpenNavItem] = React.useState({
    Unternehmen: true,
    Verkaufen: true,
    Angebote: true,
  });
  const location = useLocation();
  const history = useHistory();

  const navFirstItemRef = React.useRef(null);
  const navSndItemRef = React.useRef(null);
  const navThrdItemRef = React.useRef(null);
  const navFourthItemRef = React.useRef(null);

  const handleClick = ({ navItem }) => {
    setIsOpenNavItem((prevState) => {
      return {
        ...isOpenNavItem,
        [navItem]: prevState && prevState[navItem] ? false : true,
      };
    });
  };

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const menuNestedItems = [
    {
      title: "Angebote",
      ref: navFirstItemRef,
      children: [
        {
          title: "Angebote",
          path: "/immobilien/angebote",
        },
        { title: "Suchagent", path: "/suchagent" },
        { title: "Videoerstellung", path: "/videoerstellung" },
        { title: "YouTube", path: "/youtube" },
      ],
    },
    {
      title: "Verkaufen",
      ref: navSndItemRef,
      children: [
        { title: "Verkaufen", path: "/immobilie-verkaufen" },
        { title: "Immobilienbewertung", path: "/immobilienbewertung" },
        { title: "Referenzobjekte", path: "/immobilien/verkauft" },
        { title: "Entrümpelung und Räumung", path: "/entrümpelung-und-räumung" },
        { title: "Anhängerverleih", path: "/anhaenger" },
      ],
    },
    {
      title: "Unternehmen",
      ref: navThrdItemRef,
      children: [
        { title: "Unternehmen", path: "/unternehmen" },
        { title: "Team ", path: "/team" },
        { title: "Rezensionen", path: "/bewertungen" },
        { title: "Karriere", path: "/perspektive" },
      ],
    },
    {
      title: "Blog",
      ref: navFourthItemRef,
      children: [
        {
          title: "News und Fachwissen",
          path: "/fachwissen",
        },
        { title: "Immobilienlexikon", path: "/immobilienlexikon" },
      ],
    },
  ];

  const footerMenuItems = [
    { title: "Newsletter", path: "/newsletter" },
    { title: "Impressum", path: "/impressum" },
    { title: "Datenschutzerklärung", path: "/datenschutzerklaerung" },
    { title: "Disclaimer", path: "/disclaimer" },
    { title: "AGB", path: "/agb" },
    { title: "Widerrufsrecht", path: "/widerrufsrecht" },
  ];

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Link className={classes.logoDrawerWrapper} to="/">
          <img
            src={Logo}
            className={classes.logoDrawer}
            alt="Immobilien Marquart Bad Kissingen"
          />
        </Link>
      </div>
      <Divider />
      <List>
        {Array.isArray(menuNestedItems) && (
          <CustomMenuWithNestedItems
            data={menuNestedItems}
            isOpenNavItem={isOpenNavItem}
            onClickNavItem={() => setMobileOpen(false)}
            onClickNavHeader={({ navItem }) => handleClick({ navItem })}
            listItemComponent={AdapterLink}
          />
        )}
      </List>

      <Divider />
      <List>
        {footerMenuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            component={AdapterLink}
            to={item.path}
            activeClassName={classes.active}
            onClick={() => {
              setMobileOpen(false);
            }}
          >
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const menu = (
    <div style={{ display: "flex" }}>
      <div className="navItemAngebote">
        <Button endIcon={<ArrowDropDownIcon />} className={classes.navButtons}>
          {menuNestedItems[0]?.title}
        </Button>
        <div className="navItemAngeboteContent">
          {menuNestedItems[0]?.children?.map((navItem, index) => (
            <ListItem
              key={index}
              button
              component={AdapterLink}
              to={navItem?.path}
              activeClassName={classes.activeDesktop}
              className={classes.menuItem}
            >
              <Typography variant="body1">{navItem?.title}</Typography>
            </ListItem>
          ))}
        </div>
      </div>

      <div className="navItemVerkaufen">
        <Button endIcon={<ArrowDropDownIcon />} className={classes.navButtons}>
          {menuNestedItems[1]?.title}
        </Button>
        <div className="navItemVerkaufenContent">
          {menuNestedItems[1]?.children?.map((navItem, index) => (
            <ListItem
              key={index}
              button
              component={AdapterLink}
              to={navItem?.path}
              activeClassName={classes.activeDesktop}
              className={classes.menuItem}
            >
              <Typography variant="body1">{navItem?.title}</Typography>
            </ListItem>
          ))}
        </div>
      </div>

      <div className="navItemUnternehmen">
        <Button endIcon={<ArrowDropDownIcon />} className={classes.navButtons}>
          {menuNestedItems[2]?.title}
        </Button>
        <div className="navItemUnternehmenContent">
          {menuNestedItems[2]?.children?.map((navItem, index) => (
            <ListItem
              key={index}
              button
              component={AdapterLink}
              to={navItem?.path}
              activeClassName={classes.activeDesktop}
              className={classes.menuItem}
            >
              <Typography variant="body1">{navItem?.title}</Typography>
            </ListItem>
          ))}
        </div>
      </div>

      <div className="navItemUnternehmen">
        <Button endIcon={<ArrowDropDownIcon />} className={classes.navButtons}>
          {menuNestedItems[3]?.title}
        </Button>
        <div className="navItemUnternehmenContent">
          {menuNestedItems[3]?.children?.map((navItem, index) => (
            <ListItem
              key={index}
              button
              component={AdapterLink}
              to={navItem?.path}
              activeClassName={classes.activeDesktop}
              className={classes.menuItem}
            >
              <Typography variant="body1">{navItem?.title}</Typography>
            </ListItem>
          ))}
        </div>
      </div>
    </div>
  );

  const additionalOffset = useMediaQuery(constants.THEME.breakpoints.down("sm"))
    ? 56
    : 0;

  const rootStyle =
    bgImage && location.pathname === "/"
      ? {
          backgroundImage: "url(" + bgImage + ")",
          backgroundPosition: "center " + additionalOffset + "px",
        }
      : null;

  const navigateToImmobilienbewertung = () =>
    history.push("/immobilienbewertung");

  return (
    <div className={classes.root} style={rootStyle}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.tb}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Link className={classes.logoWrapper} to="/">
            <img
              src={Logo}
              className={classes.logo}
              alt="Immobilien Marquart Bad Kissingen"
            />
          </Link>
          <div className={classes.appBarMenu}>
            <Hidden smDown>{menu}</Hidden>
          </div>

          <Box mr={1}>
            <Button
              variant="text"
              color="secondary"
              onClick={navigateToImmobilienbewertung}
            >
              <img
                src={ImmobilienbewertungIcon}
                width="24"
                height="24"
                alt="Immobilienbewertung icon"
                className={classes.navBarBtnIcon}
              />
              <Hidden smDown>Kostenlose Immobilienbewertung</Hidden>
            </Button>
          </Box>

          <ContactForm />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="Mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="js">
          <Drawer
            container={container}
            variant="temporary"
            anchor={constants.THEME.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};
