import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useEffect, useRef, useState } from "react";
import Leaflet from "leaflet";
import { Map, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import * as Realm from "realm-web";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 !important",
    position: "relative",
  },
  dialogTitle: {
    [theme.breakpoints.up("xs")]: {
      background: grey[100],
      boxShadow:
        "0px 1px 6px 0px rgba(0,0,0,0.2), 0px 1px 2px 0px rgba(0,0,0,0.14), 0px 2px 2px -1px rgba(0,0,0,0.12)",
    },
  },
  dialogActions: {
    [theme.breakpoints.up("xs")]: {
      background: grey[100],
      boxShadow:
        "0px 1px 6px 2px rgba(0,0,0,0.2), 0px 1px 2px 2px rgba(0,0,0,0.14), 0px 2px 2px 1px rgba(0,0,0,0.12)",
    },
  },
  mapWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: "300px",
    [theme.breakpoints.down("sm")]: {
      right: 0,
    },
  },
  sidebar: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    background: "#fff",
    zIndex: 999,
    width: "300px",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      top: "55%",
      left: 0,
      overflow: "auto",
      boxShadow: "0 0 12px rgb(0 0 0 / 30%)",
    },
  },
  sidebarSelection: {
    flexGrow: 1,
    flexShrink: 0,
    overflow: "auto",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  selectedPointsTable: {
    width: "100%",
    fontSize: "90%",
    color: grey[800],
  },
  btnRemove: {
    fontSize: "80%",
  },
  actionCol: {
    textAlign: "right",
  },
  plzLink: {
    color: "#ee0033",
    cursor: "pointer",
    fontWeight: 600,
  },
  zoomHint: {
    position: "fixed",
    top: 15,
    left: 60,
    padding: theme.spacing(2),
    fontWeight: "bold",
    textAlign: "center",
    zIndex: 999,
    background: "#fff",
    borderRadius: 8,
  },
  zoomHintText: {
    fontSize: 18,
  },
}));

export default function ({ open, handleClose, handleApply }) {
  const LeafIcon = Leaflet.Icon.extend({
    options: {},
  });

  const redIcon = new LeafIcon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const orangeIcon = new LeafIcon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const [user, setUser] = useState(null);
  const [timeoutGetPoints, setTimeoutGetPoints] = useState(null);
  const [points, setPoints] = useState([]);
  const [shapes, setShapes] = useState({});
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [currentZoom, setCurrentZoom] = useState(0);
  const [polygon, setPolygon] = useState([]);
  const classes = useStyles();
  const apply = () => {
    console.log("APPLY");
    handleApply(selectedPoints.map((m) => m.note));
  };

  const [center, setCenter] = useState([50.2315936, 9.9685008]);
  const mapRef = useRef(null);
  const userRef = useRef(null);
  userRef.current = user;

  useEffect(() => {
    // getPoints();
  }, []);

  useEffect(() => {
    if (open) {
      startMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    let pol = [];
    for (let p of selectedPoints) {
      if (shapes[p.plz]) {
        pol = [
          ...pol,
          ...shapes[p.plz].map((a) =>
            a.map((b) => b.map((_, i) => (i === 0 ? b[1] : b[0])))
          ),
        ];
      }
    }
    setPolygon(pol);
  }, [selectedPoints, shapes]);

  const startMap = async () => {
    await loginApp();
    setTimeout(() => {
      handleViewportChange({ zoom: 12 });
    }, 200);
  };

  const loginApp = async () => {
    const app = new Realm.App({ id: "marquart-map-prhoz" });
    const credentials = Realm.Credentials.anonymous();
    try {
      const u = await app.logIn(credentials);
      setUser(u);
      return true;
    } catch (err) {
      console.error("Failed to log in", err);
      return false;
    }
  };

  const getPoints = async (lat1, long1, lat2, long2) => {
    console.log("getPoints", lat1, long1, lat2, long2, user);
    try {
      const res = await userRef.current.functions.getPlzPoints(
        lat1,
        long1,
        lat2,
        long2
      );
      if (res) {
        setPoints(res);
      }
    } catch (err) {
      console.error("Failed to log in", err);
    }
  };

  const getShape = async (plz) => {
    if (shapes[plz]) {
      return;
    }
    try {
      const res = await userRef.current.functions.getPlzShape(plz);
      console.log(res);
      if (res) {
        setShapes((s) => {
          s = { ...s, [plz]: res.coordinates };
          return s;
        });
      }
    } catch (err) {
      console.error("Failed to log in", err);
    }
  };

  const handleViewportChange = (viewport) => {
    if (timeoutGetPoints) {
      clearTimeout(timeoutGetPoints);
    }
    console.log("CHANGE", viewport);
    console.log(mapRef?.current?.leafletElement?.getBounds());
    const bounds = mapRef.current.leafletElement.getBounds();
    setCurrentZoom(viewport.zoom);
    if (
      viewport.zoom >= 12 &&
      bounds &&
      bounds._northEast &&
      bounds._southWest
    ) {
      setTimeoutGetPoints(
        setTimeout(() => {
          getPoints(
            bounds._northEast.lat,
            bounds._northEast.lng,
            bounds._southWest.lat,
            bounds._southWest.lng
          );
        }, 500)
      );
    }
  };

  const handlePointClick = (event, point) => {
    console.log(event);
    console.log(point);
    getShape(point.plz);
    setSelectedPoints((current) => {
      if (current && current.find((f) => f.note === point.note)) {
        return current.filter((f) => f.note !== point.note);
      }
      return [...current, point];
    });
  };

  const handleRemoveSelected = (point) => {
    setSelectedPoints((current) => {
      return current.filter((f) => f.note !== point.note);
    });
  };

  const handlePlzClick = (point) => {
    if (!point || !point.lat || !point.long) {
      return;
    }
    setCenter([point.lat, point.long]);
  };

  console.log(polygon);

  return (
    <>
      <Dialog
        // disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={open}
        onClose={handleClose}
        fullScreen={true}
      >
        <DialogContent classes={{ root: classes.root }}>
          <div className={classes.mapWrapper}>
            {currentZoom < 12 && (
              <div className={classes.zoomHint}>
                <Typography variant="h4" className={classes.zoomHintText}>
                  Bitte näher heranzoomen um Markierungen anzuzeigen
                </Typography>
              </div>
            )}

            <Map
              ref={mapRef}
              center={center}
              zoom={12}
              onViewportChanged={handleViewportChange}
              scrollWheelZoom={false}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              <Polygon color="#ee0033" positions={polygon} />
              {currentZoom >= 12 &&
                points.map((point) => (
                  <Marker
                    icon={
                      selectedPoints.find((f) => f.plz === point.plz)
                        ? redIcon
                        : orangeIcon
                    }
                    key={point._id}
                    position={[point.lat, point.long]}
                    onclick={(e) => {
                      handlePointClick(e, point);
                    }}
                    onMouseOver={(e) => {
                      e.target.openPopup();
                    }}
                    onMouseOut={(e) => {
                      e.target.closePopup();
                    }}
                  >
                    <Popup>{point.note}</Popup>
                  </Marker>
                ))}
            </Map>
          </div>
          <div className={classes.sidebar}>
            <Typography variant="h4">PLZ-Auswahl</Typography>
            <div className={classes.sidebarSelection}>
              <table className={classes.selectedPointsTable}>
                <tbody>
                  {(!selectedPoints || !selectedPoints.length) && (
                    <tr>
                      <td colSpan="3">
                        <Typography variant="body2">
                          Klicken Sie auf einen Marker um einen Ort hinzuzufügen
                        </Typography>
                      </td>
                    </tr>
                  )}
                  {selectedPoints.map((s) => (
                    <tr key={s.plz}>
                      <td>
                        <span
                          className={classes.plzLink}
                          onClick={() => {
                            handlePlzClick(s);
                          }}
                        >
                          {s.plz}
                        </span>
                      </td>
                      <td>{s.note.replace(s.plz + " ", "")}</td>
                      <td className={classes.actionCol}>
                        <Button
                          className={classes.btnRemove}
                          size="small"
                          onClick={() => {
                            handleRemoveSelected(s);
                          }}
                          color="secondary"
                          disableFocusRipple={true}
                        >
                          löschen
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <Button
                fullWidth
                variant="contained"
                onClick={apply}
                color="primary"
              >
                Übernehmen
              </Button>
              <Button
                fullWidth
                onClick={handleClose}
                color="primary"
                disableFocusRipple={true}
              >
                Abbrechen
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
