import React from "react";

/** external imports */
import {
  Avatar,
  Box,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

/** internal imports */
import imageMartin from "../../../src/images/employees/1_Martin_klein.jpg";

const useStyles = makeStyles((theme) => ({
  outerBox: {
    gap: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  innerQuestionBox: {
    display: "flex",
    alignItems: "center",
    padding: "1.5rem 2rem 1.5rem 3rem",
    backgroundColor: "#fff",
    position: "relative",
    zIndex: 99,
    borderRadius: theme.spacing(1),
    "&::after": {
      content: '""',
      zIndex: -1,
      background: "#fff",
      height: "40px",
      position: "absolute",
      left: "-10px",
      top: "20px",
      width: "40px",
      transform: "rotate(45deg)",
    },
    "& h3": {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0rem 1rem 0rem 1rem",
      "&::after": {
        zIndex: -1,
        content: '""',
        background: "#fff",
        height: "40px",
        position: "absolute",
        left: "10px",
        top: "-10px",
        width: "40px",
        transform: "rotate(45deg)",
      },
    },
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginBottom: theme.spacing(1),
    },
  },
  closeBtn: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

const CustomAvatarWithQuestion = ({ question, resetStepper }) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.outerBox}>
      <Box display="flex" justifyContent="space-between">
        <Avatar alt="Thorsten" src={imageMartin} className={classes.avatar} />
        <div className={classes.closeBtn}>
          <IconButton onClick={resetStepper}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </div>
      </Box>
      <Box flex={1} className={classes.innerQuestionBox}>
        <Typography variant="h3">{question}</Typography>
      </Box>
    </Box>
  );
};

export default CustomAvatarWithQuestion;
