import React from "react";

/** external imports */
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const CustomBackdrop = ({ isLoading, handleClose }) => {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      open={isLoading}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CustomBackdrop;
