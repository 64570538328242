import React from "react";

/** external imports */
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

/** internal imports */
import CustomListItemWithText from "./CustomListItemWithText";

const CustomMenuWithNestedItems = ({
  data,
  onClickNavItem,
  onClickNavHeader,
  listItemComponent,
  isOpenNavItem,
}) => {
  return (
    <div>
      {Array.isArray(data) &&
        data?.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              onClick={() => onClickNavHeader({ navItem: item?.title })}
            >
              <ListItemText primary={item?.title} />
              {isOpenNavItem && isOpenNavItem[item?.title] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItem>

            <Collapse
              in={isOpenNavItem && isOpenNavItem[item?.title]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {Array.isArray(item?.children) &&
                  item?.children.map((nestedItem, nestedItemIndex) => (
                    <React.Fragment key={nestedItemIndex}>
                      <CustomListItemWithText
                        navigateTo={nestedItem?.path}
                        uniqueKey={nestedItemIndex}
                        component={listItemComponent}
                        primary={nestedItem?.title}
                        onClickItem={onClickNavItem}
                      />
                    </React.Fragment>
                  ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
    </div>
  );
};

export default CustomMenuWithNestedItems;
