import React from "react";
import {
  Typography,
  Box,
  Paper,
  makeStyles,
  Button,
  Grid,
} from "@material-ui/core";
import SearchAgentImage from "../../images/suchagent.jpg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bild: {
    width: "100%",
    height: "auto",
  },
  verticalCenter: {
    display: "flex",
    alignItems: "center",
  },
}));

const AdSearchAgent = () => {
  const classes = useStyles();

  return (
    <>
      <Box py={{ xs: 2, md: 4 }} px={{ xs: 0, md: 8 }}>
        <Paper>
          <Grid container>
            <Grid item xs={12} sm={3} className={classes.verticalCenter}>
              <img
                src={SearchAgentImage}
                alt="Ihr Suchagent"
                className={classes.bild}
              />
            </Grid>
            <Grid item xs={12} sm={9} className={classes.verticalCenter}>
              <Box
                pt={{ xs: 0, sm: 4 }}
                pb={{ xs: 2, sm: 4 }}
                px={{ xs: 2, md: 6 }}
              >
                <Typography variant="h3" align="left">
                  Nicht die richtige Immobilie dabei?
                </Typography>
                <Box py={2}>
                  <Typography variant="body1" align="left">
                    Nutzen Sie unseren Suchagenten!
                    <br />
                    Wir informieren Sie umgehend, sobald eine Immobilie, die
                    Ihren Wünschen entspricht, verfügbar ist.
                  </Typography>
                </Box>
                <Button
                  disableFocusRipple={true}
                  variant="contained"
                  color="secondary"
                  size="small"
                  component={Link}
                  to="/suchagent"
                >
                  Suchagent starten
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default AdSearchAgent;
