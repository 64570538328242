import React from "react";
import { Box, Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Separator from "../layout/Separator";
import whatsAppTeaserImg from "../../images/whatsapp-teaser.jpeg";

const WhatsAppTeaser = ({ isDarkMode = true, isHome = true }) => {
  const classes = useStyles({ isDarkMode, isHome });

  const openWhatsApp = () => window.open("https://whatsapp.com/channel/0029VaVQSbTGk1FkTJxrN41V");

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h3" align="center" className={classes.title}>
        Tritt unserem WhatsApp-Kanal bei!
      </Typography>
      <Separator dense />
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        className={classes.teaserBox}
      >
        <Box
          className={classes.outerBoxImg}
          onClick={openWhatsApp}
        >
          <img
            src={whatsAppTeaserImg}
            alt="WhatsApp Teaser"
            className={classes.teaserImg}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          className={classes.rightBox}
        >
          <Typography variant="h4" className={classes.teaserText}>
            Bleiben Sie immer auf dem neuesten Stand, indem Sie unserem WhatsApp-Kanal beitreten.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={openWhatsApp}
            size="small"
            className={classes.navigateBtn}
          >
            Kanal beitreten
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: (props) => (props.isHome ? theme.spacing(2) : theme.spacing(7)),
  },
  title: {
    wordBreak: "break-word",
    marginBottom: 0,
    color: (props) =>
      props.isDarkMode ? theme.palette.background.paper : theme.palette.text.primary,
  },
  teaserImg: {
    width: "100%",
    maxWidth: 400,
    height: "auto",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 350,
    },
  },
  teaserBox: {
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  teaserText: {
    color: (props) =>
      props.isDarkMode ? theme.palette.background.paper : theme.palette.text.primary,
    textAlign: "center",
    marginBottom: theme.spacing(1),
    hyphens: "auto",
  },
  outerBoxImg: {
    cursor: "pointer",
    position: "relative",
    "& img": {
      borderRadius: theme.shape.borderRadius,
    },
  },
  navigateBtn: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },
  rightBox: {
    [theme.breakpoints.up("md")]: {
      width: 450,
    },
    textAlign: "center",
  },
}));

export default WhatsAppTeaser;
