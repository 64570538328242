import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  link: {
    cursor: "pointer",
    color: theme.palette.primary.dark,
    fontWeight: "bolder",
    textDecoration: "underline",
  },
}));

export default function CustomAccordion({
  scrollToTitle,
  title,
  description,
  lexiconKeys,
  setIsClickedWord,
}) {
  const classes = useStyles();
  const accordionRef = React.useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (scrollToTitle && scrollToTitle?.toLowerCase() === title.toLowerCase()) {
      scrollToAccordion();
    }

    return () => {
      if (scrollToTitle) {
        setIsClickedWord(null);
      }
    };
    // eslint-disable-next-line
  }, [title, scrollToTitle]);

  const scrollToAccordion = useCallback(() => {
    if (accordionRef.current) {
      accordionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      setTimeout(() => {
        setIsExpanded(true);
      }, 250);
    }
  }, []);

  const handleAccordionChange = (_, expanded) => {
    setIsExpanded(expanded);
  };

  const TextWithLexiconLinks = ({ text, lexiconKeys }) => {
    const words = text.split(/(\s+)/);

    return (
      <div>
        {words.map((word, index) => {
          const isLexiconKey = lexiconKeys.includes(word.toLowerCase());

          if (isLexiconKey) {
            const handleClick = () => {
              setIsClickedWord(word);
            };

            return (
              <span key={index} className={classes.link} onClick={handleClick}>
                {word}
              </span>
            );
          }

          return <span key={index}>{word}</span>;
        })}
      </div>
    );
  };

  return (
    <Accordion
      ref={accordionRef}
      expanded={isExpanded}
      onChange={handleAccordionChange}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box display="flex" flexDirection="column">
          <Typography
            className={clsx(classes.heading, isExpanded && classes.markedText)}
          >
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <TextWithLexiconLinks text={description} lexiconKeys={lexiconKeys} />
      </AccordionDetails>
    </Accordion>
  );
}
