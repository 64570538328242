import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: theme.spacing(4),
    textAlign: ({ textAlign }) => (textAlign ? textAlign : "center"),
    color: ({ textColor }) => textColor,
  },
  separator: {
    width: 90,
    height: 3,
    background: theme.palette.primary.main,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const CustomSubtitle = ({
  text,
  textColor,
  textAlign,
  withSeparator,
  mb,
  mt,
}) => {
  const classes = useStyles({ textColor, textAlign, mb, mt });

  if (withSeparator) {
    return (
      <Box textAlign={textAlign || "center"}>
        <Box display="inline-block" mb={mb || 4} mt={mt || 0}>
          <Typography variant="h4" className={classes.subtitle}>
            {text}
          </Typography>

          <div className={classes.separator} />
        </Box>
      </Box>
    );
  }

  return (
    <Typography variant="h4" className={classes.subtitle}>
      {text}
    </Typography>
  );
};

export default CustomSubtitle;
