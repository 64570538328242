import {
  Box,
  CircularProgress,
  Container,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import green from "@material-ui/core/colors/green";
import CheckIcon from "@material-ui/icons/Check";
import * as constants from "../../constants";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 70,
    height: 70,
    borderRadius: "50%",
    borderColor: green[500],
    borderWidth: 3,
    borderStyle: "solid",
    margin: theme.spacing(2, 6),
  },
  icon: {
    width: 40,
    height: 40,
    color: green[500],
  },
  root: {
    margin: theme.spacing(4, 0, 8, 0),
  },
  paper: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
}));

const NewsletterConfirmation = ({ match }) => {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!match || !match.params || !match.params.token) {
      return;
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(constants.API_URL + "newsletter-confirmation", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ token: match.params.token }),
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        if (data && data.result === "success") {
          setSuccess(true);
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        setError(true);
      });
  }, [match]);

  const successMessage = (
    <>
      <Box display="flex" justifyContent="center">
        <div className={classes.iconWrapper}>
          <CheckIcon className={classes.icon} />
        </div>
      </Box>
      <Typography variant="h3" gutterBottom align="center">
        Bestätigung erfolgreich
      </Typography>
      <Typography variant="body1" align="center">
        Vielen Dank, dass Sie sich für unseren Newsletter angemeldet haben.
      </Typography>
    </>
  );

  const errorMessage = (
    <>
      <Typography variant="h3" gutterBottom align="center">
        Fehler
      </Typography>
      <Typography variant="body1" align="center">
        Bei der Bestätigung Ihrer Anmeldung ist ein Fehler aufgetreten.
      </Typography>
    </>
  );

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Paper className={classes.paper}>
            {success && successMessage}
            {error && errorMessage}
            {!success && !error && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
          </Paper>
        </Container>
      </div>
    </>
  );
};

export default NewsletterConfirmation;
